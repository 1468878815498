import cx from "classnames";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useDeleteUpdateMutation, useGetAllUpdatesQuery } from "../RTK/updates";
import PaginationComponent from "../components/pagination";
import SendUpdate from "../components/send-update";
import EditUpdate from "../components/edit-update";
import ConfirmationDialog from "../components/confirmation-dialog";
import { Toast } from "primereact/toast";
import { useRef } from "react";

const toMap = {
    homs: "HOM",
    nurses: "Nurse",
    users: "User",
    all: "All",
    "system-director": "System Director",
};

export const toTypes = [
    { value: "homs", label: "HOM" },
    { value: "nurses", label: "Nurse" },
    { value: "users", label: "User" },
    { value: "system-director", label: "System Director" },
    { value: "all", label: "All" },
];

const toBody = (rowData) => <p>{toMap[rowData?.to]}</p>;

const Updates = () => {
    const [isSendUpdateShowing, setIsSendUpdateShowing] = useState(false);
    const [isEditUpdateShowing, setIsEditUpdateShowing] = useState(false);
    const [isDeleteUpdateShowing, setDeleteUpdateShowing] = useState(false);
    const [selectedUpdate, setSelectedUpdate] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({
        to: "",
    });

    const toast = useRef();

    const [deleteUpdate, { isLoading }] = useDeleteUpdateMutation();

    const handleChangeFilter = (e, field) => {
        setFilters({ ...filters, [field]: e?.value });
    };

    const onClearFilter = () => {
        setFilters({ to: "" });
        setCurrentPage(1);
    };

    const handleUpdateDelete = async () => {
        try {
            const res = await deleteUpdate(selectedUpdate);
            if (res.data) {
                toast.current?.show({ severity: "success", summary: "Success", detail: "Update Deleted Successfully" });
            }
        } catch (error) {
            toast.current?.show({ severity: "error", summary: "error", detail: "Something went wrong" });
        }
        setDeleteUpdateShowing(false);
    };

    const { data, isFetching } = useGetAllUpdatesQuery({ page: currentPage, type: filters.to });

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                <Button
                    onClick={() => {
                        setSelectedUpdate(rowData?._id);
                        setIsEditUpdateShowing(true);
                    }}
                    label="Edit"
                    className="p-button-rounded p-button-primary p-mr-2"
                    icon="pi pi-user-edit"
                />
                <Button
                    onClick={() => {
                        setSelectedUpdate(rowData?._id);
                        setDeleteUpdateShowing(true);
                    }}
                    icon="pi pi-ban"
                    style={{ marginLeft: ".5rem" }}
                    label="Remove"
                    className={cx("p-button-danger p-button-rounded")}
                />
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <title>Updates</title>
            </Helmet>

            <Toast ref={toast} />

            <div className="card">
                <div className="py-5">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>
                            <div className="font-medium text-3xl text-900">Updates</div>
                        </div>
                        <div className="mt-3 lg:mt-0">
                            <Button onClick={() => setIsSendUpdateShowing(true)} label="Send Update" className="p-button-outlined mr-2" icon="pi pi-plus" />
                        </div>
                    </div>
                    <div className="my-4">
                        <Button onClick={onClearFilter} label="Clear" className="p-button-outlined mr-2" icon="pi pi-filter" />
                        <Dropdown
                            onChange={(e) => handleChangeFilter(e, "to")}
                            className="mr-2"
                            options={toTypes}
                            optionValue="value"
                            optionLabel="label"
                            value={filters.to}
                            placeholder="Type"
                        />
                    </div>
                </div>
                <DataTable
                    lazy
                    dataKey="_id"
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    emptyMessage={"No record found"}
                    first={0}
                    loading={isFetching}
                    value={data?.updates}
                    rows={10}
                    totalRecords={data?.totalCount}
                >
                    <Column field="title" header="Title" />
                    <Column field="body" header="Body" />
                    <Column body={toBody} header="Type" />
                    <Column body={actionBodyTemplate} />
                </DataTable>
                <div className="pagination">
                    <PaginationComponent
                        pageCount={data?.totalPages}
                        totalDocs={data?.totalCount}
                        currentPage={currentPage}
                        onPageChange={(e) => setCurrentPage(e?.selected + 1)}
                    />
                </div>
            </div>
            <SendUpdate show={isSendUpdateShowing} onHide={() => setIsSendUpdateShowing(false)} />
            <EditUpdate selectedUpdate={selectedUpdate} show={isEditUpdateShowing} onHide={() => setIsEditUpdateShowing(false)} />
            <ConfirmationDialog
                onSubmit={handleUpdateDelete}
                isLoading={isLoading}
                msg="Are you sure you want to delete this update?"
                show={isDeleteUpdateShowing}
                onHide={() => setDeleteUpdateShowing(false)}
            />
        </>
    );
};

export default Updates;
