import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useGetCategoryByIdMutation } from "../RTK/categories";
import CheckboxComponent from "./Checkbox";
import Input from "./Input";
import Spinner from "./Spinner";

const organizationTypes = [
    {
        type: "hospital",
        label: "Hospital",
    },
    {
        type: "ems",
        label: "EMS",
    },
    {
        type: "nursingHome",
        label: "Nursing Home",
    },
    {
        type: "bh",
        label: "Behavioral Health",
    },
];

const EditCategory = ({ onHide, submit, show, id, selectedOption }) => {
    const [getCategoryById, { data, isLoading }] = useGetCategoryByIdMutation();

    const formik = useFormik({
        initialValues: {
            name: "",
            type: {},
            trafficLightSystem: null,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required("This field is required"),
            type: Yup.object().required("This field is required"),
        }),
        onSubmit: (values, { setSubmitting }) => {
            submit(values, setSubmitting);
        },
    });

    const getCategoryData = async () => {
        await getCategoryById(id);
    };

    useEffect(() => {
        if (id) {
            getCategoryData();
        }
    }, [id]);

    const mapOrganizationType = (type) => {
        const map = {
            hospital: "Hospital",
            ems: "EMS",
            nursingHome: "Nursing Home",
        };
        const label = map[type];
        if (selectedOption === "bh" && type === "hospital") {
            formik.setFieldValue("type", { label: "Behavioral Health", type: "bh" });
        } else {
            formik.setFieldValue("type", { label, type });
        }
    };

    useEffect(() => {
        if (data !== undefined) {
            formik.setFieldValue("name", data?.category[0]?.name);
            mapOrganizationType(data?.category[0]?.organizationType);
            formik.setFieldValue("trafficLightSystem", data?.category[0]?.isLightSystem);
        }
    }, [data]);

    const footer = () => (
        <div>
            <Button onClick={formik.handleSubmit}> {formik.isSubmitting ? "Please wait.." : "Edit"}</Button>
        </div>
    );

    const handeTypeChange = (e) => {
        formik.setFieldValue("type", e.value);
    };

    const handleToggleTrafficLight = (e) => {
        e?.checked ? formik.setFieldValue("trafficLightSystem", true) : formik.setFieldValue("trafficLightSystem", false);
    };

    return (
        <Dialog footer={footer} draggable={false} style={{ width: "450px" }} header="Edit Category" visible={show} onHide={onHide}>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <Input
                        value={formik.values.name}
                        autoComplete="off"
                        style={{ width: "100%", marginBottom: "1rem" }}
                        label="Name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="name"
                        error={formik.touched.name && formik.errors.name}
                    />
                    <p style={{ fontWeight: 500 }}>Organization Type</p>
                    <Dropdown
                        style={{ width: "100%" }}
                        placeholder="Organization Type"
                        options={organizationTypes}
                        onChange={handeTypeChange}
                        value={formik.values.type}
                        onBlur={formik.handleBlur}
                        name="type"
                    />
                    {/* <Checkbox checked={formik.values.trafficLightSystem} /> */}
                    <CheckboxComponent
                        name="trafficLightSystem"
                        onBlur={formik.handleBlur}
                        checked={formik.values.trafficLightSystem && formik.values.trafficLightSystem}
                        onChange={handleToggleTrafficLight}
                        label="Traffic Light System"
                    />
                </>
            )}
        </Dialog>
    );
};

EditCategory.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export default EditCategory;
