import React from "react";
import { Calendar } from "primereact/calendar";
import PropTypes from "prop-types";

const DatePicker = ({ onBlur, type, style, label, placeholder, value, onChange, error, name, containerStyles, ...rest }) => {
    return (
        <div style={containerStyles}>
            {label && <p style={{ fontWeight: 500 }}>{label}</p>}
            <Calendar {...rest} name={name} onBlur={onBlur} style={style} placeholder={placeholder} value={value} onChange={onChange} />
            {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
    );
};

export default DatePicker;

DatePicker.propTypes = {
    onBlur: PropTypes.func,
    type: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    error: PropTypes.string,
    name: PropTypes.string,
};
