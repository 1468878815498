import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import Cookies from "js-cookie"
import { baseUrl } from "../AppConfig";

export const setttings = createApi({
    reducerPath: "settingsApi",
    baseQuery: fetchBaseQuery({baseUrl: baseUrl,
    prepareHeaders: (headers) => {
        const token = Cookies.get("LOGIN_TOK")
        if(token){
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    }}),
    tagTypes: ["Settings"],
    endpoints: builder => ({
        getData: builder.query({
            query: () => ({
                url: "/api/v1/app/info"
            }),
            providesTags: ["Settings"]
        }),
        postData: builder.mutation({
            query: (body) => ({
                url: "/api/v1/app/info",
                method: "POST",
                body
            }),
            invalidatesTags: ["Settings"]
        })
    })
})

export const {useGetDataQuery, usePostDataMutation} = setttings