/* eslint-disable class-methods-use-this */
import axios from "axios";
import { deleteSessionCookie, getSessionCookie, setSessionCookie } from "./cookie";
import { AUTH_TOKEN } from "./enums";

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});
api.interceptors.request.use(
    async (config) => {
        const token = getSessionCookie(AUTH_TOKEN);
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (err) => Promise.reject(err)
);

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.status === 401 && !error?.response?.request?.responseURL.includes("whoami")) {
            deleteSessionCookie(AUTH_TOKEN);
            window.location.href = "/login";
            return;
        }
        return Promise.reject(error);
    }
);

/**
 * START API MANAGER
 */
export default class ApiManager {
    async Login(email, password) {
        let response;
        try {
            response = await api.post("/api/v1/auth/admin/login", { email, password });
            setSessionCookie(AUTH_TOKEN, response.data?.token);
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, data: error?.response?.data };
        }
    }
    async whoami() {
        let response;
        try {
            response = await api.get("/api/v1/auth/admin/whoami");
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, data: error?.response?.data };
        }
    }
    async getFreelacers(limit = 10, page = 1, sort = "desc", approved = 1, blocked = 0, searchKey = "", search = "") {
        let response;
        try {
            response = await api.get("/api/v1/admin/user/all", { params: { limit, page, sort, approved, blocked, search, searchKey, userType: "freelancer" } });
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, data: error?.response?.data };
        }
    }
    async getHiringManagers(limit = 10, page = 1, sort = "desc", approved = 1, blocked = 0, searchKey = "", search = "") {
        let response;
        try {
            response = await api.get("/api/v1/admin/user/all", { params: { limit, page, sort, approved, blocked, search, searchKey, userType: "hiringManager" } });
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, data: error?.response?.data };
        }
    }
    async getProfileDetails(_id) {
        let response;
        try {
            response = await api.get(`/api/v1/admin/user/${_id}`);
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, data: error?.response?.data };
        }
    }
    async modifyAccount(_id, body = {}) {
        let response;
        try {
            response = await api.patch(`/api/v1/admin/user/${_id}/modify`, { ...body });
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, data: error?.response?.data };
        }
    }
    async getOrders(limit = 10, page = 1, sort = "desc", status, createdago, users, fromdate, todate, userType) {
        let response;
        try {
            response = await api.get("/api/v1/admin/order/all", { params: { limit, page, sort, status, createdago, users, fromdate, todate, userType } });
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, data: error?.response?.data };
        }
    }
    async getOrderDetails(oid) {
        let response;
        try {
            response = await api.get(`/api/v1/admin/order/${oid}`);
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, data: error?.response?.data };
        }
    }
    async uploadBanner(image, description, name, size){
        const fd = new FormData()
        let response
        let response2
        const sizeString = `${JSON.stringify(size?.width)}x${JSON.stringify(size?.height)}`
        try{    
            fd.append("file", image)
            response = await api.post("/api/v1/upload/admin", fd, {
                headers: {
                    folderName: 'public'
                }
            })
            const banner = response?.data?.Location
            response2 = await api.post("/api/v1/banner", {banner, description, name, size: sizeString})
            console.log(response2)
            return {success: true, payload: response2}
        }catch(err){
            return {success: false, error: err}
        }
        // console.log(sizeString)
        // console.log(banner)
        // let response
        // try{
        //     response = await api.post("/api/v1/banner", {banner})
        // }catch(err){
        //     return {success: false, error}
        // }
    }
    async getAllBanners(limit = 10, page = 1){
        let res
        try{
            res = await api.get("/api/v1/banner", {params: {limit, page}})
            return {success: true, payload: res}
        }catch(err){
            return {success: false, payload: err}
        }
    }
}
