import React from "react";
import Pagination from "react-paginate";

const PaginationComponent = ({ pageCount, currentPage, totalDocs, onPageChange }) => {
    return (
        <>
            <Pagination
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                pageCount={pageCount}
                totalDocs={totalDocs}
                currentPage={currentPage}
                onPageChange={onPageChange}
                forcePage={currentPage - 1}
            />
        </>
    );
};

export default PaginationComponent;
