import { Button } from "primereact/button";
import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useGetPollAnalyticsQuery } from "../RTK/poll";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

const questionTypeMap = {
    multi_selection: "MCQS",
    single_selection: "Checkbox",
};

const BarCard = ({ question }) => {
    const chartData = question?.selections?.map((selection, index) => {
        return { option: `Option ${index + 1}`, count: selection?.count };
    });
    return (
        <div className="card" style={{ width: "100%" }}>
            <h4 className="mb-4">{questionTypeMap[question?.type]}</h4>
            <ResponsiveContainer debounce={300} sponsiveContainer width="100%" height={250}>
                <BarChart width={150} height={40} data={chartData}>
                    <XAxis dataKey="option" />
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" />
                    <YAxis dataKey="count" />
                </BarChart>
            </ResponsiveContainer>
            <h5>{question?.title}</h5>
            {question?.options?.map((option, index) => (
                <p key={index}>
                    Option {index + 1}: {option}
                </p>
            ))}
        </div>
    );
};

const ViewPollAnalytics = () => {
    const { id } = useParams();

    const { data, isLoading } = useGetPollAnalyticsQuery(id);

    const selectionTypeQuestions = useMemo(() => {
        return data?.questions?.filter((q) => q?.type !== "open_ended");
    }, [data]);

    return (
        <div className="card">
            {isLoading ? (
                <div style={{ display: "grid", placeItems: "center", padding: "1rem 0" }}>
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className="flex align-items-center justify-content-between">
                        <h1>{data?.name}</h1>
                        <Link to={`/dashboard/polls/edit/${id}`}>
                            <Button icon="pi pi-pencil" className="p-button p-button-outlined" label="Edit Poll" />
                        </Link>
                    </div>
                    <p className="text-gray" style={{ color: "rgba(105, 111, 140, 1)", marginTop: "1rem" }}>
                        {data?.description}
                    </p>
                    <Swiper
                        pagination={{
                            el: ".my-custom-pagination-div",
                            clickable: true,
                            renderBullet: (_, className) => {
                                return '<span style="margin-right: 0.5rem;" class="' + className + '">' + "</span>";
                            },
                        }}
                        navigation={true}
                        modules={[Navigation, Pagination]}
                        spaceBetween={20}
                        slidesPerView={2}
                    >
                        {selectionTypeQuestions?.map((question) => (
                            <SwiperSlide key={question?._id}>
                                <BarCard question={question} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div style={{ textAlign: "center" }}>
                        <div className="my-custom-pagination-div" />
                    </div>
                    <h3>Poll Questions</h3>
                    {data?.questions?.map((item) => (
                        <Link style={{ textDecoration: "none", color: "black" }} to={`/dashboard/polls/view-question/${id}/${item?._id}`}>
                            <div
                                key={item?._id}
                                style={{
                                    paddingTop: "1rem",
                                    paddingRight: "1rem",
                                    borderBottom: "1px solid lightgray",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                }}
                            >
                                <p style={{ fontSize: "1.2rem" }}>{item?.title}</p>
                                <span
                                    style={{
                                        border: "2px solid rgba(0, 123, 255, 1)",
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        borderRadius: "50%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <i style={{ color: "rgba(0, 123, 255, 1)", fontSize: "0.8rem" }} className="pi pi-arrow-right" />
                                </span>
                            </div>
                        </Link>
                    ))}
                </>
            )}
        </div>
    );
};

export default ViewPollAnalytics;
