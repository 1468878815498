import React from "react";
import { headerBody } from "./UsersDialog";
import emptyImage from "../../assets/image 1.png";

const AnswerView = ({ question }) => {
    return (
        <>
            {question?.responses?.length === 0 ? (
                <div style={{ flexDirection: "column" }} className="flex align-items-center justify-content-center p-4">
                    <img src={emptyImage} alt="Empty" />
                    <p className="text-xl mt-2">No Responses Yet.</p>
                </div>
            ) : (
                <div className="flex" style={{ flexDirection: "column", gap: "1.5rem" }}>
                    {question?.responses?.map((answer) => {
                        const name = headerBody(answer?.pollee);
                        return (
                            <div key={answer?.pollee?._id} className="flex align-items-center" style={{ gap: "1rem" }}>
                                <div
                                    className="flex align-items-center "
                                    style={{
                                        width: "10rem",
                                        gap: "0.5rem",
                                        padding: "0.5rem 1rem",
                                        borderRadius: "1rem",
                                        color: "rgba(0, 123, 255, 1)",
                                        background: "rgba(225, 240, 255, 1)",
                                    }}
                                >
                                    <div style={{ flexShrink: 0, width: "0.5rem", height: "0.5rem", borderRadius: "50%", background: "rgba(0, 123, 255, 1)" }} />
                                    {name}
                                </div>
                                <div
                                    className="card mb-0"
                                    style={{
                                        position: "relative",
                                        boxShadow: "none",
                                        filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0px 3px 6px)",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    <div className="arrow-left"></div>
                                    <p style={{ color: "rgba(105, 111, 140, 1)" }}>{answer?.answer}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default AnswerView;
