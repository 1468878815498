import { Dialog } from "primereact/dialog";
import React from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const getType = (rowData) => {
    const type = Object.hasOwn(rowData, "organization") ? "organization" : Object.hasOwn(rowData, "hom") ? "hom" : "user";
    return type;
};

const mapLinks = (type, id) => {
    const linkMap = {
        hospital: `/dashboard/hospitals/${id}`,
        ems: `/dashboard/ems/${id}`,
        nursingHome: `/dashboard/nursing-homes/${id}`,
    };
    return linkMap[type];
};

export const headerBody = (rowData) => {
    const type = getType(rowData);
    if (type === "organization") {
        const orgType = rowData?.organizationType;
        const id = rowData?.organization?._id;
        const name = rowData?.organization?.name;
        const link = mapLinks(orgType, id);
        return <Link to={link}>{name}</Link>;
    } else if (type === "hom") {
        const orgType = rowData?.hom[0]?.organizationType;
        const name = rowData?.hom[0]?.organization?.name;
        const id = rowData?.hom[0]?.organization?._id;
        const link = mapLinks(orgType, id);
        return <Link to={link}>{name}</Link>;
    } else {
        return <p>{rowData?.fullName}</p>;
    }
};

const UsersDialog = ({ visible, onClose, users }) => {
    return (
        <Dialog dataKey="_id" visible={visible} onHide={onClose} style={{ width: "50%" }} header="Users">
            <DataTable emptyMessage="No users found" value={users}>
                <Column header="Name" body={headerBody} />
                <Column header="email" field="email" />
            </DataTable>
        </Dialog>
    );
};

export default UsersDialog;
