import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";

const scale = 14;

let development = "https://dev.backend.nationaltransferregistry.com";
let production = "https://backend.nationaltransferregistry.com";
let local = "https://126e-103-163-238-186.in.ngrok.io";

export const param = queryString.parse(window.location.search);

export let baseUrl = production;

export const AppConfig = (props) => {
    const [active, setActive] = useState(false);
    const config = useRef(null);
    let outsideClickListener = useRef(null);

    const isLoggedin = useSelector((state) => state.auth.isLogin);

    const unbindOutsideClickListener = useCallback(() => {
        if (outsideClickListener.current) {
            document.removeEventListener("click", outsideClickListener.current);
            outsideClickListener.current = null;
        }
    }, []);

    const hideConfigurator = useCallback(
        (event) => {
            setActive(false);
            unbindOutsideClickListener();
            event.preventDefault();
        },
        [unbindOutsideClickListener]
    );

    const bindOutsideClickListener = useCallback(() => {
        if (!outsideClickListener.current) {
            outsideClickListener.current = (event) => {
                if (active && isOutsideClicked(event)) {
                    hideConfigurator(event);
                }
            };
            document.addEventListener("click", outsideClickListener.current);
        }
    }, [active, hideConfigurator]);

    useEffect(() => {
        if (active) {
            bindOutsideClickListener();
        } else {
            unbindOutsideClickListener();
        }
    }, [active, bindOutsideClickListener, unbindOutsideClickListener]);

    const isOutsideClicked = (event) => {
        return !(config.current.isSameNode(event.target) || config.current.contains(event.target));
    };

    useEffect(() => {
        document.documentElement.style.fontSize = scale + "px";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scale]);

    const configClassName = classNames("layout-config", {
        "layout-config-active": active,
    });

    return <div ref={config} className={configClassName} id={"layout-config"}></div>;
};
