import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { param } from "../AppConfig";
import { useGetAdsQuery, useGetPieChartQuery, useGetPollAnalyticsQuery, useGetRegisteredUsersQuery, useGetUsersActivityQuery } from "../RTK/analytics";
import Spinner from "../components/Spinner";
import AnalyticsCard from "../components/analytics/AnalyticsCard";
import DatePicker from "../components/date-picker";
import { Helmet } from "react-helmet";

const past2 = [
    {
        label: "All time",
        value: 1000,
    },
    {
        label: "Today",
        value: 1,
    },
    {
        label: "Last week",
        value: 7,
    },
    {
        label: "This Month",
        value: 30,
    },
    {
        label: "This Quarter Year",
        value: 120,
    },
    {
        label: "This Year",
        value: 365,
    },
];

const months = [
    {
        label: "All",
        value: null,
    },
    {
        label: "January",
        value: 1,
    },
    {
        label: "Feburary",
        value: 2,
    },
    {
        label: "March",
        value: 3,
    },
    {
        label: "April",
        value: 4,
    },
    {
        label: "May",
        value: 5,
    },
    {
        label: "June",
        value: 6,
    },
    {
        label: "July",
        value: 7,
    },
    {
        label: "August",
        value: 8,
    },
    {
        label: "September",
        value: 9,
    },
    {
        label: "October",
        value: 10,
    },
    {
        label: "November",
        value: 11,
    },
    {
        label: "December",
        value: 12,
    },
];

const RADIAN = Math.PI / 180;

const lastWeekDate = new Date();
lastWeekDate.setDate(lastWeekDate.getDate() - 7);

const formatDate = (date) => {
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" }).replace(",", "");
};

const Analytics = () => {
    const [years, setYears] = useState([]);
    const [pollDates, setPollDates] = useState({
        from: lastWeekDate,
        to: new Date(),
    });

    function generateYears() {
        const max = new Date().getFullYear();
        const min = max - 9;
        const years = [];
        for (let i = min; i <= max; i++) {
            years.push(i);
        }
        const inOrder = years.sort((a, b) => b - a);
        setYears(inOrder);
    }

    useEffect(() => {
        generateYears();
    }, []);

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const percentage = (percent * 100).toFixed(0);

        return percentage > 0 ? (
            <text style={{ fontSize: "0.9rem" }} x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
                {`${percentage}%`}
            </text>
        ) : null;
    };

    const [userPast, setUserPast] = useState({ month: null, year: new Date().getFullYear() });
    const [registeredUserPast, setRegisteredUsersPast] = useState({ month: null, year: new Date().getFullYear() });
    const [adsPast, setAdsPast] = useState(1000);

    const [orgPast, setOrgPast] = useState(1000);

    const { data: pieChartData, isFetching: isPieChartFetching } = useGetPieChartQuery({ past: orgPast });
    const { data: registeredUserData, isFetching: isRegisteredUsersFetching } = useGetRegisteredUsersQuery({
        ...registeredUserPast,
        type: registeredUserPast.month === null ? "yearly" : "monthly",
    });

    const itemTypeMap = (item) => {
        const map = {
            hospital: "Hospitals",
            ems: "EMS",
            nursingHome: "Nursing Homes",
        };
        return map[item];
    };

    //ads
    const { data: ads, isFetching: isAdsFetching } = useGetAdsQuery({ past: adsPast });
    //active users
    const { data: userActivity, isFetching: isActiveUsersFetching } = useGetUsersActivityQuery({ ...userPast, type: userPast.month === null ? "yearly" : "monthly" });

    const { data: polls, isFetching: isPollsLoading } = useGetPollAnalyticsQuery({ startDate: formatDate(pollDates.from), endDate: formatDate(pollDates.to) });

    const positions = {
        "home-screen": "Home",
        "hospitals-list": "Hospital",
        "ems-list": "Ems",
        "nursing-homes-list": "Nursing",
        "claim-organization-screen": "Claim Org",
    };

    const adsLabel = () => {
        if (ads?.data) {
            let arr = [...ads?.data];
            const updatedArr = arr.map((item) => {
                return { ...item, adPosition: positions[item?.adPosition] };
            });
            return updatedArr;
        }
    };

    const pollsTotalCount = polls?.data?.ResponsesCount + polls?.data?.polleeCount;
    const shownPercentage = (polls?.data?.polleeCount / pollsTotalCount) * 100;
    const answeredPercentage = (polls?.data?.ResponsesCount / pollsTotalCount) * 100;

    const handlePollDatesChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (pollDates.to !== "" && pollDates.from !== "") {
            if ((name === "to" && value < pollDates.from) || (name === "from" && value > pollDates.to)) {
                alert("Invalid Date Range.");
                return;
            }
        }
        setPollDates((prev) => {
            return { ...prev, [name]: value };
        });
    };

    return (
        <>
            <Helmet>
                <title>Analytics</title>
            </Helmet>
            <div className="card">
                <div className="py-5">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>
                            <div className="font-medium text-3xl text-900">Analytics</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="flex mb-4" style={{ alignItems: "center", justifyContent: "space-between" }}>
                    <p className="font-medium text-2xl text-900">Polls</p>
                    <div className="flex align-items-center" style={{ gap: "1rem" }}>
                        <DatePicker maxDate={new Date()} value={pollDates.from} name="from" onChange={handlePollDatesChange} showIcon placeholder="Start Date" />
                        <DatePicker maxDate={new Date()} value={pollDates.to} name="to" onChange={handlePollDatesChange} showIcon placeholder="End Date" />
                    </div>
                </div>
                {isPollsLoading ? (
                    <div className="text-center">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <div className="flex mb-2 align-items-center pb-2 " style={{ borderBottom: "1px solid lightgray" }}>
                            <p style={{ width: "20%", margin: 0 }} className="text-xl text-900">
                                Polls Sent ({polls?.data?.polleeCount || 0})
                            </p>
                            <div style={{ width: "80%" }}>
                                <div style={{ height: "1rem", width: `${shownPercentage}%`, background: "rgb(136, 132, 216)" }} />
                            </div>
                        </div>
                        <div className="flex align-items-center ">
                            <p style={{ width: "20%", margin: 0 }} className="text-xl text-900">
                                Polls Answered ({polls?.data?.ResponsesCount || 0})
                            </p>
                            <div style={{ width: "80%" }}>
                                <div style={{ height: "1rem", width: `${answeredPercentage}%`, background: "rgb(130, 202, 157)" }} />
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="flex">
                <AnalyticsCard.Root style={{ width: "50%" }} className="mr-4">
                    <AnalyticsCard.HeaderContent>
                        <AnalyticsCard.Heading>Active Users</AnalyticsCard.Heading>
                        <AnalyticsCard.DropDownContainer>
                            <Dropdown
                                className="mr-2"
                                options={years}
                                value={userPast.year}
                                onChange={(e) => setUserPast({ ...userPast, year: e.value })}
                                placeholder="Year"
                            />
                            <Dropdown
                                className="mr-2"
                                options={months}
                                value={userPast.month}
                                optionLabel="label"
                                optionValue="value"
                                onChange={(e) => setUserPast({ ...userPast, month: e.value })}
                                placeholder="Month"
                            />
                        </AnalyticsCard.DropDownContainer>
                    </AnalyticsCard.HeaderContent>
                    <AnalyticsCard.Content isLoading={isActiveUsersFetching}>
                        <ResponsiveContainer debounce={300} width="100%" height={200}>
                            <AreaChart data={userActivity?.data}>
                                <XAxis dataKey={userPast.month === null ? "month" : "daysOfMonth"} />
                                <YAxis dataKey="count" />
                                <Area dataKey="count" />
                                <CartesianGrid opacity={0.1} />
                                <Tooltip />
                            </AreaChart>
                        </ResponsiveContainer>
                    </AnalyticsCard.Content>
                </AnalyticsCard.Root>
                <AnalyticsCard.Root style={{ width: "49%" }}>
                    <AnalyticsCard.HeaderContent>
                        <AnalyticsCard.Heading>Ads</AnalyticsCard.Heading>
                        <AnalyticsCard.DropDownContainer>
                            <Dropdown className="mr-2" options={past2} value={adsPast} onChange={(e) => setAdsPast(e.value)} placeholder="All Time" />
                        </AnalyticsCard.DropDownContainer>
                    </AnalyticsCard.HeaderContent>
                    <AnalyticsCard.Content isLoading={isAdsFetching}>
                        <>
                            <ResponsiveContainer debounce={300} width="100%" height={200}>
                                <BarChart className="ads-chart" data={adsLabel() ? adsLabel() : []}>
                                    <XAxis dataKey="adPosition" />
                                    <Tooltip />
                                    <Bar fill="#8884d8" dataKey="viewsCount" />
                                    <Bar fill="#82ca9d" dataKey="clicksCount" />
                                    <YAxis dataKey="viewsCount" />
                                </BarChart>
                            </ResponsiveContainer>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingLeft: "1rem",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    marginTop: "1rem",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <span style={{ width: "1rem", height: "1rem", backgroundColor: "rgb(136, 132, 216)", marginRight: "1rem" }} />
                                    <p>Views Count</p>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <span style={{ width: "1rem", height: "1rem", backgroundColor: "rgb(130, 202, 157)", marginRight: "1rem" }} />
                                    <p>Clicked Count</p>
                                </div>
                            </div>
                        </>
                    </AnalyticsCard.Content>
                </AnalyticsCard.Root>
            </div>
            <div className="flex">
                <AnalyticsCard.Root style={{ width: "50%" }} className="mr-4">
                    <AnalyticsCard.HeaderContent>
                        <AnalyticsCard.Heading>Registered Users</AnalyticsCard.Heading>
                        <AnalyticsCard.DropDownContainer>
                            <Dropdown
                                className="mr-2"
                                options={years}
                                value={registeredUserPast.year}
                                onChange={(e) => setRegisteredUsersPast({ ...userPast, year: e.value })}
                                placeholder="Year"
                            />
                            <Dropdown
                                className="mr-2"
                                options={months}
                                value={registeredUserPast.month}
                                optionLabel="label"
                                optionValue="value"
                                onChange={(e) => setRegisteredUsersPast({ ...userPast, month: e.value })}
                                placeholder="Month"
                            />
                        </AnalyticsCard.DropDownContainer>
                    </AnalyticsCard.HeaderContent>
                    <AnalyticsCard.Content isLoading={isRegisteredUsersFetching}>
                        <ResponsiveContainer debounce={300} width="100%" height={200}>
                            <AreaChart data={registeredUserData?.data}>
                                <XAxis dataKey={registeredUserPast.month === null ? "month" : "daysOfMonth"} />
                                <YAxis dataKey="count" />
                                <Area dataKey="count" />
                                <CartesianGrid opacity={0.1} />
                                <Tooltip />
                            </AreaChart>
                        </ResponsiveContainer>
                    </AnalyticsCard.Content>
                </AnalyticsCard.Root>
                <AnalyticsCard.Root style={{ width: "49%" }}>
                    <AnalyticsCard.HeaderContent>
                        <AnalyticsCard.Heading>Organization Viewed</AnalyticsCard.Heading>
                        <AnalyticsCard.DropDownContainer>
                            <Dropdown className="mr-2" options={past2} value={orgPast} onChange={(e) => setOrgPast(e.value)} placeholder="All Time" />
                        </AnalyticsCard.DropDownContainer>
                    </AnalyticsCard.HeaderContent>
                    <AnalyticsCard.Content isLoading={isPieChartFetching}>
                        <>
                            <ResponsiveContainer debounce={300} width="100%" height={230}>
                                <PieChart>
                                    <Pie labelLine={false} label={renderCustomizedLabel} data={pieChartData?.data} dataKey="value">
                                        {pieChartData?.data?.map((item) => (
                                            <Cell fill={item.type === "hospital" ? "#157347" : item.type === "ems" ? "#007bff" : "red"} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginRight: "3rem" }}>
                                {pieChartData?.data?.map((item) => (
                                    <div className="flex " style={{ alignItems: "center" }}>
                                        <span
                                            style={{
                                                width: "1rem",
                                                height: "1rem",
                                                marginRight: "1rem",
                                                background: item.type === "hospital" ? "#157347" : item.type === "ems" ? "#007bff" : "red",
                                            }}
                                        />

                                        <p style={{ width: "2rem" }}>{itemTypeMap(item?.type)}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    </AnalyticsCard.Content>
                </AnalyticsCard.Root>
            </div>
        </>
    );
};

export default Analytics;
