import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { baseUrl } from "../AppConfig";

export const hospital = createApi({
    reducerPath: "hospitalApi",
    tagTypes: ["Hospital"],
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            const token = Cookies.get("LOGIN_TOK");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
                headers.set("Access-Control-Allow-Credentials", false);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAllHospitals: builder.mutation({
            query: (args) => {
                const { search, searchKey, sort, page, sortKey } = args;
                console.log(baseUrl);
                let sortt = 0;
                sort === "asc" ? (sortt = 1) : (sortt = -1);
                return {
                    url: "/api/v1/admin/hospital",
                    params: { search, searchKey, sort: sortt, page, sortKey: "createdAt" },
                };
            },
            invalidatesTags: ["Hospital"],
        }),
        getHospitals: builder.query({
            query: (params) => {
                return {
                    url: "/api/v1/admin/hospital",
                    params: { ...params },
                };
            },
        }),
        createHospital: builder.mutation({
            query: (body) => {
                console.log("from rtk", body);
                return {
                    url: "/api/v1/admin/hospital",
                    method: "POST",
                    body: body,
                };
            },
            invalidatesTags: ["Hospital"],
        }),
        getHospitalById: builder.query({
            query: (id) => ({
                url: `/api/v1/admin/hospital/${id}`,
            }),
            providesTags: ["Hospital"],
        }),
        removeHospital: builder.mutation({
            query: (id) => ({
                url: `/api/v1/admin/hospital/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Hospital"],
        }),
        editHospital: builder.mutation({
            query: (body) => {
                const { id, ...rest } = body;
                return {
                    url: `/api/v1/admin/hospital/${id}`,
                    method: "PATCH",
                    body: rest,
                };
            },
            invalidatesTags: ["Hospital"],
        }),
    }),
});

export const {
    useGetAllHospitalsMutation,
    useCreateHospitalMutation,
    useGetHospitalByIdQuery,
    useRemoveHospitalMutation,
    useEditHospitalMutation,
    useGetHospitalsQuery,
} = hospital;
