import { Dialog } from 'primereact/dialog'
import React from 'react'
import PropTypes from "prop-types"
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { useFormik } from 'formik'
import * as Yup from "yup"
import Input from './Input'
import CheckboxComponent from './Checkbox'
import Select from './Select'
import { param } from '../AppConfig'

const organizationTypes = [
    {
        type: "hospital",
        label: "Hospital"
    },
    {
        type: "ems",
        label: "EMS"
    },
    {
        type: "nursingHome",
        label: "Nursing Home"
    },
    {
        type: "bh",
        label: "Behavioral Health"
    }
] 
const AddCategory = ({onHide, submit, show}) => {

    const formik = useFormik({
        initialValues: {
            name: "",
            type: null,
            trafficLightSystem: true
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required("This field is required"),
            type: Yup.object().required("This field is required").nullable()
        }),
        onSubmit: (values, {setSubmitting}) => {
            submit(values, setSubmitting)        
        }   
    })

    const footer = () => (
        <div >
            <Button onClick={formik.handleSubmit} > {formik.isSubmitting ? "Please wait.."  : "Add"}</Button>
        </div>
    )

    const handeTypeChange = e => {
        formik.setFieldValue("type", e.value)
    }

    const handleToggleTrafficLight = e => {
        e?.checked ? formik.setFieldValue("trafficLightSystem", true) : formik.setFieldValue("trafficLightSystem", false)
    }

  return (
    <Dialog footer={footer} draggable={false} style={{width: "450px"}}  header="Add Category" visible={show} onHide={onHide} >
        
        <Input autoComplete="off" style={{width: "100%", marginBottom: "1rem"}} label="Name" onBlur={formik.handleBlur} onChange={formik.handleChange} name="name" error={formik.errors.name} />
        <p style={{fontWeight: 500}} >Organization Type</p>
        <Select
            error={formik.touched.type && formik.errors.type}
            style={{width: "100%"}}
            placeholder='Organization Type'
            options={organizationTypes}
            onChange={handeTypeChange}
            value={formik.values.type}
            onBlur={formik.handleBlur}
            name="type"
        />
        <CheckboxComponent name="trafficLightSystem" onBlur={formik.handleBlur} checked={formik.values.trafficLightSystem} onChange={handleToggleTrafficLight} label="Traffic Light System"  />
    </Dialog>
  )
}

AddCategory.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool,
    onSubmit: PropTypes.func
}

export default AddCategory