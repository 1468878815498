import React from "react";
import Spinner from "../Spinner";

const AnalyticsCard = {
    Root: ({ className, style = { width: "49%" }, children }) => (
        <div className={`card ${className}`} style={{ ...style, minHeight: "15rem" }}>
            {children}
        </div>
    ),
    HeaderContent: ({ children }) => (
        <div className="flex mb-8" style={{ alignItems: "center", justifyContent: "space-between" }}>
            {children}
        </div>
    ),
    Heading: ({ children }) => <div className="font-medium text-2xl text-900">{children}</div>,
    DropDownContainer: ({ children }) => <div>{children}</div>,
    Content: ({ isLoading, children }) =>
        isLoading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50%" }}>
                <Spinner />
            </div>
        ) : (
            children
        ),
};
export default AnalyticsCard;
