import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { baseUrl } from "../AppConfig"

export const ads = createApi({
    reducerPath: "adsApi",
    tagTypes: ["Ads"],
    baseQuery: fetchBaseQuery({baseUrl: baseUrl, 
    prepareHeaders: (headers, {getState}) => {
        const token = Cookies.get("LOGIN_TOK")
        if(token){
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    }
}),
    endpoints: builder => ({
        getAllAds: builder.query({
            query: (params) => ({
                url: "/api/v1/ads",
                params: {...params}
            }),
            providesTags: ["Ads"]
        }),
        addAd: builder.mutation({
            query: body => ({
                url: "/api/v1/ads",
                method: "POST",
                body
            }),
            invalidatesTags: ["Ads"]
        }),
        editAd: builder.mutation({
            query: body => ({
                url: "/api/v1/ads",
                method: "PUT",
                body
            }),
            invalidatesTags: ["Ads"]
        }),
        deleteAd: builder.mutation({
            query: id => ({
                url: `/api/v1/ads?ad=${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Ads"]
        })
    })  
})

export const {useGetAllAdsQuery, useAddAdMutation, useEditAdMutation, useDeleteAdMutation} = ads