import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import PropTypes from "prop-types"

const ConfirmationDialog = ({ msg = "Are you sure you want to delete?", onSubmit, show, onHide, isLoading}) => {
    const dialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={onHide} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" loading={isLoading}  onClick={onSubmit} />
        </>
    );

    return (
        <Dialog visible={show} style={{ width: "450px" }} header="Confirm" modal footer={dialogFooter} onHide={onHide}>
            <div className="flex align-items-center justify-content-start">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
            <span>{msg}</span>
            </div>
        </Dialog>
    );
};

export default ConfirmationDialog;

ConfirmationDialog.propTypes = {
    msg: PropTypes.string,
    onSubmit: PropTypes.func,
    show: PropTypes.bool,
    onHide: PropTypes.func
}