import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export default function LargeImage({visible, setVisible, image}) {
    return (
        // <div>
        <Dialog onMaskClick={() => setVisible(false)} onClick={() => setVisible(false)} showHeader={false} contentStyle={{padding: 0}}  closeOnEscape  visible={visible} style={{ width: '50vw', padding: 0}} onHide={() => setVisible(false)}>
            <img style={{width: "100%", height: '200px', objectFit: 'cover'}} src={image} crossOrigin="anonymous" />
        </Dialog>
        // </div>
    )
}