import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { baseUrl } from "../AppConfig";

export const users = createApi({
    reducerPath: "usersApi",
    tagTypes: ["Users", "UserDetails"],
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = Cookies.get("LOGIN_TOK");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAllUsers: builder.query({
            query: (params) => ({
                url: "/api/v1/admin/user/all",
                params: { ...params },
            }),
            providesTags: ["Users"],
        }),
        toggleTestUser: builder.mutation({
            query: (body) => {
                const { id, ...rest } = body;
                return {
                    url: `/api/v1/admin/user/${id}/modify`,
                    body: rest,
                    method: "PUT",
                };
            },
            invalidatesTags: ["Users"],
        }),
        modifyUser: builder.mutation({
            query: (body) => {
                const { id, ...rest } = body;
                return {
                    url: `/api/v1/admin/user/${id}/modify`,
                    body: rest,
                    method: "PUT",
                };
            },
            invalidatesTags: ["Users", "UserDetails"],
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/api/v1/admin/user/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Users"],
        }),
        getUserById: builder.query({
            query: (id) => {
                console.log("being called with id", id);
                return {
                    url: `/api/v1/admin/user/${id}`,
                };
            },
            providesTags: ["UserDetails"],
        }),
        sendNotification: builder.mutation({
            query: ({ id, body }) => ({
                url: `/api/v1/admin/notifications/${id}?userType=user`,
                body,
                method: "POST",
            }),
        }),
    }),
});

export const { useSendNotificationMutation, useGetAllUsersQuery, useToggleTestUserMutation, useModifyUserMutation, useDeleteUserMutation, useGetUserByIdQuery } = users;
