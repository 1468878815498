import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
    theme: "",
    themeFile: "",
    activeMenuLabel: ""
};

const uiSlice = createSlice({
    name: "ui",
    initialState: INIT_STATE,
    reducers: {
        setTheme: (state, action) => {
            return {
                ...state, 
                theme: action.payload
            }
        },
        setThemeFile: (state, action) => {
            return {
                ...state, 
                themeFile: action.payload
            }
        },
        setActiveMenuLabel: (state,action) => {
            return {
                ...state,
                activeMenuLabel: action.payload
            }
        }
    }
})

export const {setTheme, setThemeFile, setActiveMenuLabel} = uiSlice.actions

export default uiSlice.reducer