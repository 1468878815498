import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useMemo, useRef } from "react";
import ReactSelect from "react-select";
import * as Yup from "yup";
import { useGetAllOrganizationsQuery } from "../RTK/organization";
import { useEditMutation } from "../RTK/system-directors";

const EditSystemDirector = ({ onHide, isVisible, systemDirector }) => {
    const toast = useRef();

    const [edit, { isLoading }] = useEditMutation();

    const formik = useFormik({
        initialValues: {
            organizations: [],
        },
        validationSchema: Yup.object().shape({
            organizations: Yup.array().min(1).required("This field is required"),
        }),
        onSubmit: async (values) => {
            const { organizations } = values;

            const org = organizations?.map((item) => {
                const o = { organizationType: item?.organizationType, organization: item?._id };
                return o;
            });

            const body = {
                systemDirector: systemDirector?.systemDirector?._id,
                adminSystemDirector: systemDirector?._id,
                organizationType: org[0]?.organizationType,
                organization: org[0]?.organization,
                organizations: org,
            };

            const res = await edit(body);
            if (res?.data?.success) {
                toast.current.show({ severity: "success", summary: "Success", detail: "System Director Edited Successfully", life: 3000 });
                onHide();
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: res?.data?.msg ?? "Something went wrong", life: 3000 });
            }
        },
    });

    const { data } = useGetAllOrganizationsQuery({ limit: 1000, sort: "desc" });

    const options = useMemo(() => {
        let ids = formik.values.organizations.map((item) => item?._id);
        const filteredOrgs = data?.data?.filter((item) => !ids.includes(item?.organization?._id));
        return filteredOrgs;
    }, [data, formik.values.organizations]);

    const handleSelectOrganization = (e) => {
        const orgs = e.map((item) => (item.organization ? { organizationType: item.organizationType, ...item.organization } : item));
        console.log(orgs, "orgs");
        formik.setFieldValue("organizations", orgs);
    };

    const handleDefaultOrganization = (e) => {
        formik.setFieldValue("defaultOrganization", e.value);
    };

    useEffect(() => {
        if (isVisible) {
            formik.setFieldValue("organizations", systemDirector?.organizations);
            // } else {
            //     formik.setFieldValue(
            //         "organizations",
            //         systemDirector?.organizations?.map((item) => item?.organization)
            //     );
            // }
        }
    }, [systemDirector, isVisible]);

    const footer = () => (
        <div>
            <Button disabled={isLoading} onClick={formik.handleSubmit}>
                {" "}
                {formik.isSubmitting ? "Please wait.." : "Save"}
            </Button>
        </div>
    );

    const selectedOrganizations = systemDirector?.organizations?.map((item) => {
        const i = { ...item, id: item?.organization?._id, name: item?.organization?.name };
        return i;
    });

    const handleSelectDelete = (item) => {
        console.log(item, "item");
        const id = item?._id;
        const temp = formik.values.organizations?.filter((item) => item?._id !== id);
        formik.setFieldValue("organizations", temp);
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                footer={footer}
                contentStyle={{ paddingTop: "3rem", paddingBottom: "3rem" }}
                style={{ width: "650px" }}
                draggable={false}
                onHide={() => {
                    formik.resetForm();
                    onHide();
                }}
                visible={isVisible}
                header="Edit System Director"
            >
                {data && (
                    <>
                        <p style={{ fontWeight: 500 }}>Operational Managers (Select Multiple)</p>
                        <ReactSelect
                            isMulti={true}
                            onBlur={formik.handleBlur}
                            name="organizations"
                            controlShouldRenderValue={false}
                            styles={{
                                container: (baseStyles) => ({
                                    ...baseStyles,
                                    border: "1px solid #007bff",
                                    borderRadius: "2rem",
                                }),
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderRadius: "2rem",
                                    border: "1px solid #007bff",
                                    padding: ".3rem",
                                }),
                            }}
                            closeMenuOnSelect={false}
                            options={options}
                            getOptionLabel={(data) => data?.organization?.name}
                            getOptionValue={(data) => data?.organization?._id}
                            value={formik.values.organizations}
                            onChange={handleSelectOrganization}
                        />
                    </>
                )}
                {formik.touched.organizations && formik.errors.organizations && <p style={{ color: "red" }}>{formik.errors.organizations}</p>}
                <div>
                    {formik.values.organizations?.length > 0 && (
                        <>
                            <div
                                style={{
                                    marginTop: "1rem",
                                    marginBottom: "1rem",
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3,1fr)",
                                    columnGap: "1rem",
                                    rowGap: "1rem",
                                }}
                            >
                                {formik?.values?.organizations?.map((item) => (
                                    <div
                                        key={item?.id}
                                        style={{
                                            padding: ".5rem",
                                            borderRadius: "1rem",
                                            background: "#007bff",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                        }}
                                    >
                                        <p style={{ color: "white", fontSize: ".8rem", marginBottom: "0", marginRight: ".5rem" }}>{item?.name}</p>
                                        <i
                                            onClick={() => handleSelectDelete(item)}
                                            style={{ fontSize: ".8rem", color: "white", cursor: "pointer" }}
                                            className="pi pi-times"
                                        ></i>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <p style={{ marginTop: "1rem" }}>
                    <strong>Note:</strong> If Target HOM is not in the list. Please assign an HOM to the organization first.
                </p>
            </Dialog>
        </>
    );
};

export default EditSystemDirector;
