import React from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { useState } from "react";
import { useGetHospitalsQuery } from "../../RTK/hospital";
import { useRef } from "react";
import PaginationComponent from "../pagination";
import { useGetEmsQuery } from "../../RTK/ems";
import { useGetNursingHomeQuery } from "../../RTK/nursing-home";
import { useGetAllOrganizationsQuery } from "../../RTK/organization";
import { param } from "../../AppConfig";

const HospitalTable = ({ checked, setChecked }) => {
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    const searchRef = useRef();

    const { data, isFetching: loading } = useGetHospitalsQuery({ limit: 8, search, page });

    const handleSeachOnClick = () => {
        setSearch(searchRef.current.value);
    };

    const handleChange = (e) => {
        searchRef.current.value = e.target.value;
    };

    const handlePageChange = (p) => {
        setPage(p);
    };

    const onClearFilter = () => {
        setPage(1);
        setSearch("");
        searchRef.current.value = "";
    };

    const handleCheckChange = (isChecked, id) => {
        let arr = [...checked];
        if (isChecked) {
            arr.push(id);
        } else {
            const indx = arr.findIndex((itemId) => itemId === id);
            arr.splice(indx, 1);
        }
        setChecked(arr);
    };

    const checkBody = (rowData) => {
        return <Checkbox checked={checked.includes(rowData?._id)} onChange={(e) => handleCheckChange(e.checked, rowData?._id)} />;
    };

    return (
        <div>
            <div className="flex items-center mb-4">
                <Button type="button" label="Clear" className="p-button-outlined mr-2" icon="pi pi-filter" onClick={onClearFilter} />
                <InputText ref={searchRef} className="mr-2" onSubmit={handleSeachOnClick} onChange={(e) => handleChange(e)} placeholder="Search..." />
                <Button onClick={handleSeachOnClick} icon="pi pi-search" label="Search" className="p-button-outlined mr-2" />
            </div>
            <DataTable
                lazy
                dataKey="_id"
                filterDisplay="row"
                responsiveLayout="scroll"
                emptyMessage={"No record found"}
                first={0}
                loading={loading}
                value={data?.docs}
                rows={8}
                totalRecords={data?.totalRecords}
            >
                <Column body={checkBody} />
                <Column field="name" header="Name" />
                <Column field="address" header="Address" />
                <Column field="city" header="City" />
                <Column field="state" header="State" />
                <Column field="phoneNumber" header="Phone" />
            </DataTable>
            <div className="flex items-center justify-center mt-2">
                <PaginationComponent
                    currentPage={page}
                    onPageChange={(e) => handlePageChange(e?.selected + 1)}
                    totalDocs={data?.totalRecords}
                    pageCount={data?.totalPages}
                />
            </div>
        </div>
    );
};

const EMSTable = ({ checked, setChecked }) => {
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    const searchRef = useRef();

    const { data, isFetching: loading } = useGetEmsQuery({ limit: 8, search, page });

    const handleSeachOnClick = () => {
        setSearch(searchRef.current.value);
    };

    const handleChange = (e) => {
        searchRef.current.value = e.target.value;
    };

    const handlePageChange = (p) => {
        setPage(p);
    };

    const onClearFilter = () => {
        setPage(1);
        setSearch("");
        searchRef.current.value = "";
    };

    const handleCheckChange = (isChecked, id) => {
        let arr = [...checked];
        if (isChecked) {
            arr.push(id);
        } else {
            const indx = arr.findIndex((itemId) => itemId === id);
            arr.splice(indx, 1);
        }
        setChecked(arr);
    };

    const checkBody = (rowData) => {
        return <Checkbox checked={checked.includes(rowData?._id)} onChange={(e) => handleCheckChange(e.checked, rowData?._id)} />;
    };

    const criticalCareMap = (rowData) => {
        const map = {
            dont_know: {
                text: "Don't know",
            },
            yes: {
                text: "Yes",
            },
            NO: {
                text: "No",
            },
            no: {
                text: "No",
            },
        };

        const text = map[rowData?.criticalCare]?.text || "";

        return <p>{text}</p>;
    };

    return (
        <div>
            <div className="flex items-center mb-4">
                <Button type="button" label="Clear" className="p-button-outlined mr-2" icon="pi pi-filter" onClick={onClearFilter} />
                <InputText ref={searchRef} className="mr-2" onSubmit={handleSeachOnClick} onChange={(e) => handleChange(e)} placeholder="Search..." />
                <Button onClick={handleSeachOnClick} icon="pi pi-search" label="Search" className="p-button-outlined mr-2" />
            </div>
            <DataTable
                lazy
                dataKey="_id"
                filterDisplay="row"
                responsiveLayout="scroll"
                emptyMessage={"No record found"}
                first={0}
                loading={loading}
                value={data?.docs}
                rows={8}
                totalRecords={data?.totalRecords}
            >
                <Column body={checkBody} />
                <Column field="name" header="Name" />
                <Column field="mailingAddressState" header="Mailing Address State" />
                <Column body={criticalCareMap} header="Critical Care" />
                <Column field="telephone" header="Phone" />
                <Column field="city" header="City" />
                <Column field="practiceAddressState" header="Practice Address State" />
            </DataTable>
            <div className="flex items-center justify-center mt-2">
                <PaginationComponent
                    currentPage={page}
                    onPageChange={(e) => handlePageChange(e?.selected + 1)}
                    totalDocs={data?.totalRecords}
                    pageCount={data?.totalPages}
                />
            </div>
        </div>
    );
};

const NursingHomeTable = ({ checked, setChecked }) => {
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    const searchRef = useRef();

    const { data, isFetching: loading } = useGetNursingHomeQuery({ limit: 8, search, page });

    const handleSeachOnClick = () => {
        setSearch(searchRef.current.value);
    };

    const handleChange = (e) => {
        searchRef.current.value = e.target.value;
    };

    const handlePageChange = (p) => {
        setPage(p);
    };

    const onClearFilter = () => {
        setPage(1);
        setSearch("");
        searchRef.current.value = "";
    };

    const handleCheckChange = (isChecked, id) => {
        let arr = [...checked];
        if (isChecked) {
            arr.push(id);
        } else {
            const indx = arr.findIndex((itemId) => itemId === id);
            arr.splice(indx, 1);
        }
        setChecked(arr);
    };

    const checkBody = (rowData) => {
        return <Checkbox checked={checked.includes(rowData?._id)} onChange={(e) => handleCheckChange(e.checked, rowData?._id)} />;
    };

    return (
        <div>
            <div className="flex items-center mb-4">
                <Button type="button" label="Clear" className="p-button-outlined mr-2" icon="pi pi-filter" onClick={onClearFilter} />
                <InputText ref={searchRef} className="mr-2" onSubmit={handleSeachOnClick} onChange={(e) => handleChange(e)} placeholder="Search..." />
                <Button onClick={handleSeachOnClick} icon="pi pi-search" label="Search" className="p-button-outlined mr-2" />
            </div>
            <DataTable
                lazy
                dataKey="_id"
                filterDisplay="row"
                responsiveLayout="scroll"
                emptyMessage={"No record found"}
                first={0}
                loading={loading}
                value={data?.docs}
                rows={8}
                totalRecords={data?.totalRecords}
            >
                <Column body={checkBody} />
                <Column field="name" header="Name" />
                <Column field="address" header="Address" />
                <Column field="certifiedBeds" header="Certified Beds" />
                <Column field="phoneNumber" header="Phone" />
                <Column field="city" header="City" />
                <Column field="state" header="State" />
            </DataTable>
            <div className="flex items-center justify-center mt-2">
                <PaginationComponent
                    currentPage={page}
                    onPageChange={(e) => handlePageChange(e?.selected + 1)}
                    totalDocs={data?.totalRecords}
                    pageCount={data?.totalPages}
                />
            </div>
        </div>
    );
};

const NursesTable = ({ checked, setChecked }) => {
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);

    const searchRef = useRef();

    const { data, isFetching: loading } = useGetAllOrganizationsQuery({ limit: 8, search, page });

    const handleSeachOnClick = () => {
        setSearch(searchRef.current.value);
    };

    const handleChange = (e) => {
        searchRef.current.value = e.target.value;
    };

    const handlePageChange = (p) => {
        setPage(p);
    };

    const onClearFilter = () => {
        setPage(1);
        setSearch("");
        searchRef.current.value = "";
    };

    const handleCheckChange = (isChecked, id) => {
        let arr = [...checked];
        if (isChecked) {
            arr.push(id);
        } else {
            const indx = arr.findIndex((itemId) => itemId === id);
            arr.splice(indx, 1);
        }
        setChecked(arr);
    };

    const checkBody = (rowData) => {
        return <Checkbox checked={checked.includes(rowData?._id)} onChange={(e) => handleCheckChange(e.checked, rowData?._id)} />;
    };

    const organizationTypeMap = (rowData) => {
        const map = {
            hospital: "Hospital",
            ems: "EMS",
            nursingHome: "Nursing Home",
        };

        const label = map[rowData?.organizationType];
        return label;
    };

    const organizationNameBody = (rowData) => {
        return <p>{rowData?.organization?.name}</p>;
    };

    return (
        <div>
            <div className="flex items-center mb-4">
                <Button type="button" label="Clear" className="p-button-outlined mr-2" icon="pi pi-filter" onClick={onClearFilter} />
                <InputText ref={searchRef} className="mr-2" onSubmit={handleSeachOnClick} onChange={(e) => handleChange(e)} placeholder="Search..." />
                <Button onClick={handleSeachOnClick} icon="pi pi-search" label="Search" className="p-button-outlined mr-2" />
            </div>
            <DataTable
                lazy
                dataKey="_id"
                filterDisplay="row"
                responsiveLayout="scroll"
                emptyMessage={"No record found"}
                first={0}
                loading={loading}
                value={data?.data}
                rows={8}
                totalRecords={data?.totalCount}
            >
                <Column body={checkBody} />
                <Column field="name" header="Name" body={organizationNameBody} />
                <Column field="email" header="Email" />
                <Column field="organizationType" body={organizationTypeMap} header="Organization Type" />
            </DataTable>
            <div className="flex items-center justify-center mt-2">
                <PaginationComponent
                    currentPage={page}
                    onPageChange={(e) => handlePageChange(e?.selected + 1)}
                    totalDocs={data?.totalRecords}
                    pageCount={data?.totalPages}
                />
            </div>
        </div>
    );
};

const SpecificOrganization = ({ isVisible, onHide, type, orgType, handleSubmit, handleSpecificOrganizationSelected }) => {
    const [checked, setChecked] = useState([]);

    const onSubmit = () => {
        handleSpecificOrganizationSelected(checked);
        setChecked([]);
        onHide();
    };

    const footer = () => {
        return <Button onClick={onSubmit} label="Confirm" />;
    };

    return (
        <Dialog footer={footer} visible={isVisible} style={{ width: "80%" }} header="Select Organization" modal onHide={onHide}>
            {type === "user" && orgType === "hospital" && <HospitalTable checked={checked} setChecked={setChecked} />}
            {type === "user" && orgType === "ems" && <EMSTable checked={checked} setChecked={setChecked} />}
            {type === "user" && orgType === "nursingHome" && <NursingHomeTable checked={checked} setChecked={setChecked} />}
            {type === "nurse" && <NursesTable checked={checked} setChecked={setChecked} />}
        </Dialog>
    );
};

export default SpecificOrganization;
