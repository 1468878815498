import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useMemo, useState } from "react";
import ReactSelect from "react-select";
import * as Yup from "yup";
import { useGetAllOrganizationsQuery } from "../../RTK/organization";

const Footer = ({ onHide, onSubmit, isSubmitting }) => {
    return (
        <div className="flex align-items-center justify-content-center">
            <Button onClick={onHide} label="Close" className="p-button p-button-outlined p-button-secondary" style={{ width: "49%" }} />
            <Button type="button" onClick={onSubmit} label={isSubmitting ? "Please wait..." : "Add"} disabled={isSubmitting} style={{ width: "49%" }} />
        </div>
    );
};

const AddOrganizationDialog = ({ show, onHide, onAdd, addedOrganizations }) => {
    const [minHeight, setminHeight] = useState("350px");

    const formik = useFormik({
        initialValues: {
            selectedOrganizations: [],
        },
        validationSchema: Yup.object().shape({
            selectedOrganizations: Yup.array()
                .of(
                    Yup.object().shape({
                        id: Yup.string(),
                        type: Yup.string(),
                        name: Yup.string(),
                    })
                )
                .min(1, "Please select at least 1 organization")
                .required("This field is required"),
        }),
        onSubmit: (values) => {
            onAdd(values.selectedOrganizations, formik.resetForm);
        },
    });

    const { data } = useGetAllOrganizationsQuery({ limit: 1000, sort: "desc" });

    const organizations = useMemo(() => {
        return data?.data
            ?.filter((item) => !addedOrganizations?.some((org) => org?.organization?._id === item?.organization?._id))
            .map((item) => {
                const org = { id: item?.organization?._id, type: item?.organizationType, name: item?.organization?.name };
                return org;
            });
    }, [data, addedOrganizations]);

    const handleSelectOrganization = (value) => {
        formik.setFieldValue("selectedOrganizations", value);
    };

    const handleSelectDelete = (item) => {
        const id = item?.id;
        const temp = formik.values.selectedOrganizations.filter((item) => item?.id !== id);
        formik.setFieldValue("selectedOrganizations", temp);
    };

    return (
        <Dialog
            contentStyle={{ padding: "1.5rem 1rem", display: "flex", flexDirection: "column" }}
            footer={<Footer isSubmitting={formik.isSubmitting} onSubmit={formik.handleSubmit} onHide={onHide} />}
            draggable={false}
            style={{ width: "500px", minHeight }}
            visible={show}
            onHide={onHide}
        >
            <div className="flex mb-4" style={{ alignItems: "center", flexDirection: "column" }}>
                <i className="pi pi-building text-5xl" />
                <p className="font-bold mt-4 text-xl">Add Organizations</p>
            </div>
            <p style={{ fontWeight: 500 }}>Select Organizations</p>
            <ReactSelect
                onChange={handleSelectOrganization}
                options={organizations}
                placeholder="Select multiple organizations"
                getOptionLabel={(data) => data?.name}
                getOptionValue={(data) => data?.id}
                value={formik.values.selectedOrganizations}
                onBlur={formik.handleBlur}
                isMulti={true}
                closeMenuOnSelect={false}
                controlShouldRenderValue={false}
                onMenuOpen={() => setminHeight("450px")}
                onMenuClose={() => setminHeight("350px")}
            />
            {formik.values.selectedOrganizations.length > 0 && (
                <>
                    <div style={{ marginBottom: "1rem", display: "grid", gridTemplateColumns: "repeat(3,1fr)", columnGap: "1rem", rowGap: "1rem", marginTop: "1rem" }}>
                        {formik?.values?.selectedOrganizations?.map((item) => (
                            <div
                                key={item?.id}
                                style={{
                                    padding: ".5rem",
                                    borderRadius: "1rem",
                                    background: "#007bff",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                }}
                            >
                                <p style={{ color: "white", fontSize: ".8rem", marginBottom: "0", marginRight: ".5rem" }}>{item?.name}</p>
                                <i onClick={() => handleSelectDelete(item)} style={{ fontSize: ".8rem", color: "white", cursor: "pointer" }} className="pi pi-times"></i>
                            </div>
                        ))}
                    </div>
                </>
            )}
            {formik.touched.selectedOrganizations && formik.errors.selectedOrganizations && (
                <p style={{ color: "red", marginTop: "1rem" }}>{formik.errors.selectedOrganizations}</p>
            )}
        </Dialog>
    );
};

export default AddOrganizationDialog;
