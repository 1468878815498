import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from 'react'
import FileBase64 from 'react-file-base64'
import * as Yup from "yup"
import { baseUrl } from '../AppConfig'
import Input from './Input'
import Select from './Select'

const organizationTypes = [
    {
        type: "hospital",
        label: "Hospital"
    },
    {
        type: "ems",
        label: "EMS"
    },
    {
        type: "nursingHome",
        label: "Nursing Home"
    }
]

const EditAds = ({onHide, handleSubmit, show, ad}) => {

    const [shouldSubmit, setShouldSubmit] = useState()

    const toast = useRef(null)

    const formik = useFormik({
        initialValues: {
            position: "",
            subject: "",
            email: "info@nationaltransferregistry.com",
            image: null
        },
        validationSchema: Yup.object().shape({
            position: Yup.string().required("This field is required"),
            subject: Yup.string().required("This field is required"),
            email: Yup.string().required("This field is required"),
            image: Yup.mixed().required("This field is required")
            // .test("size", "Image must be 800x100", value => {
            //     const i = new Image()
            //     i.src = value
            // })
        }),
        onSubmit: (values, {setSubmitting}) => {
            const {image} = values
            const imageBase64 = image?.split(",")[1]
            const body = {...values, image: imageBase64}
            handleSubmit(body, setSubmitting)
        }   
    })
    const footer = () => (
        <div >
            <Button onClick={formik.handleSubmit} disabled={formik.errors.image ? true : false}> {formik.isSubmitting ? "Please wait.."  : "Save"}</Button>
        </div>
    )

    const positionOptions = [
        {
            label: "Home Screen",
            value: "home-screen"
        },
        {
            label: "Hospitals List",
            value: "hospitals-list"
        },
        {
            label: "Nursing Homes List",
            value: "nursing-homes-list"
        },
        {
            label: "Ems List",
            value: "ems-list"
        },
        {
            label: "Claim Organization Screen",
            value: "claim-organization-screen"
        }
    ]

    useEffect(() => {
        formik.setFieldValue("position", ad?.position)
        formik.setFieldValue("subject", ad?.subject)
        formik.setFieldValue("email", ad?.email)
        formik.setFieldValue("image", baseUrl + `/api/v1/ads/image/${ad?._id}?t=${new Date().getTime()}`)
    }, [ad])


  return (
    <>
    <Toast ref={toast} />
    <Dialog footer={footer} draggable={false} style={{width: "650px"}}  header="Edit Ad" visible={show} onHide={onHide} >
        <Select  
        onChange={e => formik.setFieldValue("position", e?.value)}
        value={formik.values.position}
        onBlur={formik.handleBlur}
        name="position"
        error={formik.touched.position && formik.errors.position}
        style={{width: "100%", marginBottom: "1rem"}}
        label="Position" placeholder="Select a position" options={positionOptions} optionLabel="label" optionValue="value" />
         <p style={{fontWeight: 500}} >Image (Size should be 800x100) </p>
        <FileBase64 multiple={false} 
            onDone={({base64}) => {
                const type = base64.slice(5, 10)
                const i = new Image()
                i.src = base64
                i.onload = () => {
                    const height = i.height
                    const width  = i.width
                    if(height != 100 && width != 800){
                        formik.setFieldError("image", "Image size should be 800x100")
                    }
                }
                i.onerror = () => console.error("image failed to load")
                if(type === "image"){
                    const base64str = base64.split(",")[1]
                    const decoded = atob(base64str);
                    const bytes = decoded.length
                    const kb = bytes / 1000
                    const mb = kb / 1000
                    if(mb.toFixed(2) < 1){
                        formik.setFieldValue("image", base64)
                    }else{
                        alert("File size must be less than 1mb")
                    }
                }else{
                    alert("Invalid Image Type. Please upload a png/jpeg image")
                }
            }}
         />
        {formik.errors.image && (
        <p style={{color: "red"}} >{formik.errors.image}</p>
        )}
        <div>
        {formik.values.image && (
            <img
                width='300px'
                src={formik.values.image}
                crossOrigin="anonymous"
            />
        )}

        </div>
         <p style={{fontWeight: "bold", fontSize: "1.2rem"}} >Email Content</p>
        <Input
            name="email"
            label="Email"    
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
            style={{width: "100%", marginBottom: "1rem"}}
        />  
        <Input
            name="subject"
            label="Subject"    
            value={formik.values.subject}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.subject && formik.errors.subject}
            style={{width: "100%", marginBottom: ".5rem"}}
        />  
    </Dialog>
    </>
  )
}

EditAds.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool,
    onSubmit: PropTypes.func,
}

export default EditAds