import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
    user: {
        token: "",
        userMeta: ""
    },
    isLogin: false,
    isLoggingin: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState: INIT_STATE,
    reducers: {
        loginn: (state, action) => {
            return {
                ...state,
                user: {...state.user, userMeta: action.payload.userMeta, token: action.payload.token},
                isLogin: true
            }
        },
        logout: (state, action) => {
            return {
                ...state, 
                user: {},
                isLogin: false
            }
        },
        isLoggingIn: (state, action) => {
            return {
                ...state, 
                isLoggingin: action.payload
            }
        }
    }
})

export const {loginn, logout, isLoggingIn} = authSlice.actions

export default authSlice.reducer