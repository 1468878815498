import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import PropTypes from "prop-types"
import { Button } from 'primereact/button'
import { useFormik } from 'formik'
import * as Yup from "yup"
import Input from './Input'

const ChangePasswordOrganizationDialog = ({onHide, submit, show}) => {

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object().shape({
            newPassword: Yup.string().required("This field is requried"),
            confirmPassword: Yup.string().required("This field is requried").oneOf([Yup.ref("newPassword")], "Your Passwords Must Match")
        }),
        onSubmit: async (values, {setSubmitting}) => {
            await submit(values.newPassword, values.confirmPassword)
            setSubmitting(false)        
        }   
    })

    const footer = () => (
        <div >
            <Button onClick={formik.handleSubmit} > {formik.isSubmitting ? "Please wait.."  : "Update"}</Button>
        </div>
    )

  return (
    <Dialog footer={footer} draggable={false} style={{width: "450px"}}  header="Change Password" visible={show} onHide={onHide} >
        {/* inputs with hidden so that browser doesn't autofll saved passwords */}
        <input autoComplete='off' hidden type="password" name="fakepassword" />
        <input autoComplete='off' hidden type="password" name="fakeconfirmpassword" />
        <Input autoComplete="off" type="password" style={{width: "100%"}} label="New Password" onBlur={formik.handleBlur} onChange={formik.handleChange} name="newPassword" error={formik.errors.newPassword} />
        <Input autoComplete="off" type="password" style={{width: "100%"}} label="Confirm Password" onBlur={formik.handleBlur} onChange={formik.handleChange} name="confirmPassword" error={formik.errors.confirmPassword} />
    </Dialog>
  )
}

ChangePasswordOrganizationDialog.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool,
    onSubmit: PropTypes.func
}

export default ChangePasswordOrganizationDialog