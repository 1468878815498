import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useGetCustomAppsQuery } from "../RTK/custom-apps";

const actionBodyTemplate = (rowData) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={`/dashboard/custom-apps/${rowData?._id}?name=${rowData?.name}`}>
                <Button label="View Details" className="p-button-rounded p-button-primary" />
            </Link>
        </div>
    );
};

const CustomApps = () => {
    const { data, isLoading } = useGetCustomAppsQuery();

    return (
        <>
            <Helmet>
                <title>Custom Apps</title>
            </Helmet>
            <div className="card">
                <div className="py-5">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div className="font-medium text-3xl text-900">Apps</div>
                    </div>
                </div>
                <DataTable
                    lazy
                    dataKey="_id"
                    loading={isLoading}
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    emptyMessage={"No record found"}
                    first={0}
                    value={data?.customApps}
                    rows={10}
                    totalRecords={2}
                >
                    <Column field="name" header="Name" headerStyle={{ backgroundColor: "#007BFF", color: "white", border: "none" }} />
                    <Column
                        header="Actions"
                        headerStyle={{ backgroundColor: "#007BFF", color: "white", display: "flex", justifyContent: "center", border: "none" }}
                        body={actionBodyTemplate}
                    />
                </DataTable>
            </div>
        </>
    );
};

export default CustomApps;
