import React, { useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import Input from "./Input";
import Select from "./Select";
import { Button } from "primereact/button";

const questionTypes = [
    { label: "Short Answer", value: "open_ended" },
    { label: "Checkboxes", value: "single_selection" },
    { label: "Multiple Choice", value: "multi_selection" },
];

const Question = ({ handleChange, index, item, handleOptionAdd, removeQuestion, deleteOption }) => {
    const [isAddingOption, setIsAddingOption] = useState(false);
    const [text, setText] = useState("");

    const handleOption = () => {
        let arr = [...item.options];
        arr.push(text);
        handleOptionAdd(index, arr);
        setText("");
        setIsAddingOption(false);
    };

    const handleRequiredToggle = () => {
        handleChange("isRequired", index, !item.isRequired);
    };

    return (
        <div className="card py-4" style={{ backgroundColor: "#EDF1F5" }}>
            <div className="flex align-items-center justify-content-end">
                <div className="card px-4 py-2 flex align-items-center">
                    <div className="flex align-items-center">
                        <p className="mb-0 mr-3">Required</p>
                        <InputSwitch checked={item.isRequired} onChange={handleRequiredToggle} />
                    </div>
                    <hr className="mx-2" style={{ background: "#007BFF", border: "2px solid #007BFF", height: "1px", width: "1.3rem", transform: "rotate(90deg)" }} />
                    <div style={{ cursor: "pointer" }} onClick={() => removeQuestion(index)} className="flex align-items-center">
                        <i className="pi pi-trash" />
                        <p className="mb-0 ml-3">Remove</p>
                    </div>
                </div>
            </div>
            <div className="flex align-items-center mt-2 mb-4">
                <div style={{ width: "70%" }}>
                    <Input
                        value={item?.title}
                        onChange={(e) => handleChange("title", index, e.target.value)}
                        placeholder="Question *"
                        style={{ width: "100%", padding: "1.5rem" }}
                    />
                </div>
                <div style={{ width: "30%" }}>
                    <Select
                        value={item.type}
                        onChange={(e) => handleChange("type", index, e.value)}
                        options={questionTypes}
                        placeholder="Question Type *"
                        className="ml-2"
                        style={{ width: "100%", padding: "1rem" }}
                    />
                </div>
            </div>
            {item.options.map((item, i) => (
                <div key={i} className="flex align-items-center mb-2">
                    <input type="checkbox" id={item} />
                    <label style={{ fontSize: "1.2rem", marginLeft: "0.3rem" }} htmlFor={item}>
                        {item}
                    </label>
                    <i
                        onClickCapture={() => deleteOption(index, i)}
                        className="pi pi-times-circle ml-4"
                        style={{ color: "red", marginBottom: "-0.5rem", cursor: "pointer" }}
                    />
                </div>
            ))}
            {(item.type === "multi_selection" || item.type === "single_selection") &&
                (isAddingOption ? (
                    <div className="flex mt-4 align-items-center" style={{ width: "100%" }}>
                        <div style={{ width: "70%" }} className="mr-4">
                            <Input value={text} onChange={(e) => setText(e.target.value)} placeholder="Add Option Name" style={{ width: "100%", padding: "1.5rem" }} />
                        </div>
                        <Button disabled={text === ""} onClick={handleOption} label="Save" className="px-4 " style={{ height: "3rem" }} />
                    </div>
                ) : (
                    <p className="text-primary mt-4" onClick={() => setIsAddingOption(true)} style={{ textDecoration: "underline", cursor: "pointer" }}>
                        Add New Option
                    </p>
                ))}
        </div>
    );
};

export default Question;
