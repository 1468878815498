import cx from "classnames";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRemoveOrganizationMutation } from "../RTK/organization";
import { useDeleteSystemDirectorMutation, useGetAllSystemDirectorsQuery } from "../RTK/system-directors";
import eyeSlash from "../assets/svg/Icon awesome-eye-slash.svg";
import eyeIcon from "../assets/svg/Icon awesome-eye.svg";
import userIcon from "../assets/svg/Icon awesome-user-ninja.svg";
import bulletIcon from "../assets/svg/Icon ionic-md-radio-button-on.svg";
import ConfirmationDialog from "../components/confirmation-dialog";
import EditSystemDirector from "../components/edit-sys-director";
import PaginationComponent from "../components/pagination";
import SystemDirectorDialog from "../components/system-director";

const organizations = [
    {
        name: "Organization 1",
        value: "organization1",
    },
];

// const data = {
//     docs: [
//         {
//             name: "System director 101",
//             organizations: "1"
//         }
//     ],
//     totalPages: 10,
//     totalDocs: 101
// }

const searchKeyOptions = [
    {
        value: "email",
        name: "Email",
    },
];

const SystemDirectors = () => {
    const [isAddDirectorVisible, setDirectorVisible] = useState(false);
    const [isEditDirectorVisible, setEditDirectorVisible] = useState(false);
    const [isAdminDetailsVisible, setAdminDetailsVisible] = useState(false);
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [org, setOrg] = useState();
    const [details, setDetails] = useState({});
    const [search, setSearch] = useState("");
    const [searchKey, setSearchKey] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const toast = useRef();

    let { data, isFetching } = useGetAllSystemDirectorsQuery({ search, searchKey, page: search !== "" && currentPage > 1 ? 1 : currentPage, sort: "desc" });

    const showAddDirector = () => {
        setDirectorVisible(true);
    };

    const onDirectorHide = () => {
        setDirectorVisible(false);
    };

    console.log(data, "data");

    const onShowEditDirector = (data) => {
        setEditDirectorVisible(true);
        setOrg(data);
    };

    const onHideEditDirector = () => {
        setEditDirectorVisible(false);
    };

    const onHideAdminDetails = () => {
        setAdminDetailsVisible(false);
    };

    const orgBody = (rowData) => {
        return rowData?.organization?.name;
    };

    const sysDirBody = (rowData) => {
        return <p>{rowData?.systemDirector?.email}</p>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <a href={`mailto:?to=&body=Email:%20${rowData?.systemDirector?.email}%0APassword:%20${rowData?.passwordCopy}`}>
                    <Button label="Share Credentials" className="p-button p-button-rounded p-button-outlined mr-2" />
                </a>
                <Button label="Edit" onClick={() => onShowEditDirector(rowData)} className="p-button-rounded p-button-primary p-mr-2" icon="pi pi-user-edit" />
                <Button
                    onClick={() => {
                        setDetails({
                            email: rowData?.email,
                            password: rowData?.passwordCopy,
                            organization: rowData?.organization,
                            organizations: rowData?.organizations,
                        });
                        setAdminDetailsVisible(true);
                    }}
                    className="p-button p-button-secondary"
                    style={{ marginLeft: "1rem" }}
                >
                    View
                </Button>
                <Button
                    icon="pi pi-ban"
                    style={{ marginLeft: ".5rem" }}
                    label="Remove"
                    onClick={() => {
                        setDetails({
                            email: rowData?.email,
                            password: rowData?.passwordCopy,
                            organization: rowData?.organization,
                            organizations: rowData?.organizations,
                            systemDirector: rowData?._id,
                        });
                        setConfirmDialogVisible(true);
                    }}
                    className={cx("p-button-danger")}
                />
            </>
        );
    };

    const onClear = () => {
        setSearch("");
        setSearchKey("");
    };

    const [removeOrganization] = useRemoveOrganizationMutation();
    const [deleteSystemDirector, { isLoading }] = useDeleteSystemDirectorMutation();

    const handleDeleteSystemDirector = async () => {
        // console.log(details?.systemDirector)
        const res = await deleteSystemDirector({ adminSystemDirector: details?.systemDirector });
        console.log(res);
        if (res?.data) {
            toast.current.show({ severity: "success", summary: "Success", detail: res?.data?.msg, life: 3000 });
            setDetails({});
            setConfirmDialogVisible(false);
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: res?.data?.msg ?? "Something went wrong", life: 3000 });
            setConfirmDialogVisible(false);
            setDetails({});
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <Helmet>
                <title>System Directors</title>
            </Helmet>
            <div className="card">
                <div className="py-5">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>
                            <div className="font-medium text-3xl text-900">System Directors</div>
                        </div>
                        <div className="mt-3 lg:mt-0">
                            <Button onClick={showAddDirector} label="Add" className="p-button-outlined mr-2" icon="pi pi-user-plus" />
                        </div>
                    </div>
                </div>
                <form className="my-4 flex items-center ">
                    <Button onClick={onClear} type="button" label="Clear" className="p-button-outlined mr-2" icon="pi pi-filter" />
                    {/* <Dropdown
                    value={searchKey}
                    className="mr-2"
                    onChange={e => setSearchKey(e.value)}
                    options={searchKeyOptions}
                    optionLabel="name"
                    optionValue='value' 
                    placeholder="Search Key"
                /> */}
                    <InputText value={search} onChange={(e) => setSearch(e.target.value)} className="mr-2" placeholder="Search..." />
                    {/* <Button type="submit" icon="pi pi-search" label="Search" className="p-button-outlined mr-2" /> */}
                </form>
                <DataTable
                    lazy
                    dataKey="_id"
                    loading={isFetching}
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    emptyMessage={"No record found"}
                    first={0}
                    value={data?.data}
                    rows={10}
                    totalRecords={data?.totalCount}
                >
                    <Column field="email" header="Email" body={sysDirBody} />
                    {/* <Column header="Default Organization" body={orgBody} /> */}
                    <Column body={actionBodyTemplate} />
                </DataTable>
                <div className="pagination">
                    <PaginationComponent
                        onPageChange={(e) => setCurrentPage(e?.selected + 1)}
                        pageCount={data?.totalPages}
                        totalDocs={data?.totalCount}
                        currentPage={currentPage}
                    />
                </div>
            </div>
            <SystemDirectorDialog isVisible={isAddDirectorVisible} onHide={onDirectorHide} />
            <EditSystemDirector systemDirector={org} isVisible={isEditDirectorVisible} onHide={onHideEditDirector} />
            <AdminDetailsDialog details={details} isVisible={isAdminDetailsVisible} onHide={onHideAdminDetails} />
            <ConfirmationDialog isLoading={isLoading} onSubmit={handleDeleteSystemDirector} show={isConfirmDialogVisible} onHide={() => setConfirmDialogVisible(false)} />
        </>
    );
};

export default SystemDirectors;

const AdminDetailsDialog = ({ isVisible, onHide, details }) => {
    const capitalize = (str) => {
        const words = str?.split(" ");
        for (let i = 0; i < words?.length; i++) {
            words[i] = words[i]?.charAt(0)?.toUpperCase() + words[i]?.slice(1);
        }
        return words?.join(" ");
    };

    const [hide, setHide] = useState(true);

    return (
        <Dialog style={{ width: "550px" }} draggable={false} header={() => <p style={{ fontWeight: "bold" }}>System Director</p>} visible={isVisible} onHide={onHide}>
            <div style={{ paddingBottom: "1rem" }}>
                <p style={{ fontSize: "1.2rem", fontWeight: 500 }}>Operational Managers</p>
                {details?.organizations?.map((value) => {
                    const item = value;
                    return (
                        <div key={item?._id} style={{ display: "flex", alignItems: "center", marginTop: ".5rem" }}>
                            <img src={bulletIcon} width="15rem" />
                            <p style={{ marginLeft: ".5rem", marginBottom: 0 }}>{capitalize(item?.name?.toLowerCase())}</p>
                            <Link
                                to={
                                    item?.organizationType === "hospital"
                                        ? `/dashboard/hospitals/${item?._id}`
                                        : item?.organizationType === "ems"
                                        ? `/dashboard/ems/${item?._id}`
                                        : `/dashboard/nursing-homes/${item?._id}`
                                }
                            >
                                <p style={{ marginLeft: "2rem", color: "#007bff", fontWeight: 500 }}>View</p>
                            </Link>
                        </div>
                    );
                })}
                {/* <p style={{fontSize: "1.2rem", fontWeight: 500, marginTop: "1rem"}} >Current Operational Manager</p> */}
                {/* <div style={{padding: ".8rem 0", width: "16rem", background: "#007bff", color: "white", borderRadius: "1rem", textAlign: "center"}} >{capitalize(details?.organization?.name?.toLowerCase())}</div> */}
                <div style={{ width: "100%", marginTop: "1rem", height: "2px", backgroundColor: "#007bff" }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "1rem" }}>
                    <div style={{ alignItems: "center", display: "flex" }}>
                        <img src={userIcon} width="18rem" style={{ marginRight: ".5rem" }} />
                        <p style={{ fontSize: "1.2rem", fontWeight: 500 }}>Admin Credentials</p>
                    </div>
                    <img style={{ cursor: "pointer", width: "30px" }} src={hide ? eyeSlash : eyeIcon} onClick={() => setHide(!hide)} />
                </div>
                {!hide && (
                    <div style={{ marginTop: "1rem" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <strong>Email: </strong>
                            <p style={{ marginLeft: "1rem" }}>{details?.email}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", marginTop: ".5rem" }}>
                            <strong>Password: </strong>
                            <p style={{ marginLeft: "1rem" }}>{details?.password}</p>
                        </div>
                    </div>
                )}
            </div>

            {/* <p style={{color: "gray"}} >Details: </p>
        <h6>HOMs</h6>
        {details?.organizations?.map((item,index) => <p style={{marginTop: "-.5rem"}} key={item?._id} >{index + 1}. {item?.organization?.name}</p>)}
        <h6 style={{marginTop: "-.5rem"}} >Default HOM</h6>
        <p style={{marginTop: "-.5rem"}} >{details?.organization?.name}</p>
        <div style={{backgroundColor: "#0069d9", width: "5rem", height: "1px"}} />
        <h6 style={{color: "gray"}} >Admin Credentials</h6>
        <h6 style={{marginTop:"-.5rem"}} >Email:</h6>
        <p  style={{marginTop:"-.5rem"}} >{details?.email}</p>
        <h6  style={{marginTop:"-.5rem"}} >Password:</h6>
        <p  style={{marginTop:"-.5rem"}}>{details?.password}</p> */}
        </Dialog>
    );
};
