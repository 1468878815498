import React, { useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useGetUserByIdQuery, useModifyUserMutation, useSendNotificationMutation } from "../RTK/users";
import ProgressSpinnerComponent from "../components/progress-spinner";
import { Button } from "primereact/button";
import Input from "../components/Input";
import TextArea from "../components/TextArea";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

const organizationMap = {
    hospital: "Hospital",
    ems: "EMS",
    nursingHome: "Nursing Home",
};

const booleanMap = {
    true: "Yes",
    false: "No",
};

const linkMap = {
    hospital: "hospitals",
    ems: "ems",
    nursingHome: "nursing-home",
};

const UserDetails = () => {
    const [isSendingNotification, setSendingNotification] = useState(false);

    const [modifyUser, { isLoading: isModifyUserLoading }] = useModifyUserMutation();

    const { id } = useParams();

    const { data, isLoading } = useGetUserByIdQuery(id);

    const handleTestUserChange = async () => {
        await modifyUser({ id, isTestUser: !data?.isTestUser });
    };

    const handleBlockChange = async () => {
        await modifyUser({ id, isBlocked: !data?.isBlocked });
    };

    const handleApproveChange = async () => {
        await modifyUser({ id, isApproved: !data?.isApproved });
    };

    return isLoading ? (
        <div style={{ width: "100%", height: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <ProgressSpinnerComponent />
        </div>
    ) : (
        <div>
            <div className="flex align-items-center justify-content-between">
                <h1 className="my-4">User Details</h1>
                <Button onClick={() => setSendingNotification(!isSendingNotification)} className="mt-4">
                    Send Notification
                </Button>
            </div>
            <div className="card">
                <div className="user-details-grid">
                    <div style={{ flexDirection: "column", gap: "0.5rem" }} className="flex">
                        <strong>Email: </strong>
                        <p>{data?.email}</p>
                    </div>
                    <div style={{ flexDirection: "column", gap: "0.5rem" }} className="flex">
                        <strong>User Name: </strong>
                        <p>{data?.username}</p>
                    </div>
                    <div style={{ flexDirection: "column", gap: "0.5rem" }} className="flex">
                        <strong>Full Name: </strong>
                        <p>{data?.fullName}</p>
                    </div>
                    <Link to={`/dashboard/${linkMap[data?.organizationType]}/${data?.organization?._id}`}>
                        <div style={{ flexDirection: "column", gap: "0.5rem" }} className="flex">
                            <strong style={{ color: "black" }}>Organization Name: </strong>
                            <p>{data?.organization?.name}</p>
                        </div>
                    </Link>
                    <div style={{ flexDirection: "column", gap: "0.5rem" }} className="flex">
                        <strong>Organization Type: </strong>
                        <p>{organizationMap[data?.organizationType]}</p>
                    </div>
                    <div style={{ flexDirection: "column", gap: "0.5rem" }} className="flex">
                        <strong>User Type: </strong>
                        <p>{data?.category}</p>
                    </div>
                    <div style={{ flexDirection: "column", gap: "0.5rem" }} className="flex">
                        <strong>Testing User: </strong>
                        <div className="flex mt-2 align-items-center">
                            <p className="mr-2 mb-0">{booleanMap[data?.isTestUser]}</p>
                            <InputSwitch disabled={isModifyUserLoading} onChange={handleTestUserChange} checked={data?.isTestUser} />
                        </div>
                    </div>
                    <div style={{ flexDirection: "column", gap: "0.5rem" }} className="flex">
                        <strong>Blocked: </strong>
                        <div className="flex mt-2 align-items-center">
                            <p className="mr-2 mb-0">{booleanMap[data?.isBlocked]}</p>
                            <InputSwitch disabled={isModifyUserLoading} onChange={handleBlockChange} checked={data?.isBlocked} />
                        </div>
                    </div>
                    <div style={{ flexDirection: "column", gap: "0.5rem", marginTop: "-1rem" }} className="flex">
                        <strong>Approved: </strong>
                        <div className="flex mt-2 align-items-center">
                            <p className="mr-2 mb-0">{booleanMap[data?.isApproved]}</p>
                            <InputSwitch disabled={isModifyUserLoading} onChange={handleApproveChange} checked={data?.isApproved} />
                        </div>
                    </div>
                </div>
                <SendNotificationDialog id={id} isVisible={isSendingNotification} onHide={() => setSendingNotification(false)} />
            </div>
        </div>
    );
};

export default UserDetails;

const SendNotificationDialog = ({ isVisible, onHide, id }) => {
    const [form, setForm] = useState({ title: "", body: "" });

    const toastRef = useRef();

    const [sendNotification, { isLoading }] = useSendNotificationMutation();

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        if (!form.body || !form.title) {
            alert("Please enter all fields!");
            return;
        }
        const res = await sendNotification({ id, body: { title: form.title, body: form.body } });
        if (res.data) {
            toastRef.current.show({ summary: "Success", detail: "Notification sent successfully", severity: "success" });
            onHide();
        }
    };

    return (
        <>
            <Toast ref={toastRef} />
            <Dialog
                footer={
                    <Button disabled={isLoading} onClick={handleSubmit}>
                        Send Notification
                    </Button>
                }
                visible={isVisible}
                onHide={onHide}
                header="Send Notification"
            >
                <Input onChange={handleChange} name="title" style={{ width: "25rem" }} placeholder="Title" />
                <TextArea onChange={handleChange} name="body" style={{ width: "25rem" }} className="mt-2" placeholder="Body" />
            </Dialog>
        </>
    );
};
