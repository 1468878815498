import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { baseUrl } from "../AppConfig";

export const customApps = createApi({
    reducerPath: "customAppsApi",
    tagTypes: ["CustomApps"],
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = Cookies.get("LOGIN_TOK");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCustomApps: builder.query({
            query: () => ({
                url: "/api/v1/org/custom/app",
            }),
        }),
        getCustomAppOrganizations: builder.query({
            query: ({ id, ...rest }) => ({
                url: `/api/v1/org/apps/list`,
                params: { id, ...rest },
            }),
            providesTags: ["CustomApps"],
        }),
        addOrganization: builder.mutation({
            query: (body) => ({
                url: "/api/v1/org/custom/app/organization",
                body,
                method: "POST",
            }),
            invalidatesTags: ["CustomApps"],
        }),
        deleteOrganization: builder.mutation({
            query: (id) => ({
                url: `/api/v1/org/custom/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["CustomApps"],
        }),
    }),
});

export const { useGetCustomAppsQuery, useGetCustomAppOrganizationsQuery, useAddOrganizationMutation, useDeleteOrganizationMutation } = customApps;
