import { createSlice } from "@reduxjs/toolkit"

const INIT_STATE = {
    env: "",
    baseUrl: ""
}

const envSlice = createSlice({
    name: "env",
    initialState: INIT_STATE,
    reducers: {
        changeEnv:  (state,action) => {
            return {
                ...state, 
                env: action.payload.env,
                baseUrl: action.payload.baseUrl
            }
        }
    }
})

export const {changeEnv} = envSlice.actions

export default envSlice.reducer