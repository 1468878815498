import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ads } from "./RTK/ads";
import { analytics } from "./RTK/analytics";
import { auth } from "./RTK/auth";
import { categories } from "./RTK/categories";
import { customApps } from "./RTK/custom-apps";
import { ems } from "./RTK/ems";
import { hospital } from "./RTK/hospital";
import { notifications } from "./RTK/notifications";
import { nursingHome } from "./RTK/nursing-home";
import { organization } from "./RTK/organization";
import { polls } from "./RTK/poll";
import { setttings } from "./RTK/settings";
import { systemDirectors } from "./RTK/system-directors";
import { updates } from "./RTK/updates";
import { users } from "./RTK/users";
import ScrollToTop from "./ScrollToTop";
import authSlice from "./redux/auth-slice";
import envSlice from "./redux/env-slice";
import uiSlice from "./redux/ui-slice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        ui: uiSlice,
        env: envSlice,
        [auth.reducerPath]: auth.reducer,
        [hospital.reducerPath]: hospital.reducer,
        [setttings.reducerPath]: setttings.reducer,
        [nursingHome.reducerPath]: nursingHome.reducer,
        [ems.reducerPath]: ems.reducer,
        [organization.reducerPath]: organization.reducer,
        [categories.reducerPath]: categories.reducer,
        [systemDirectors.reducerPath]: systemDirectors.reducer,
        [ads.reducerPath]: ads.reducer,
        [analytics.reducerPath]: analytics.reducer,
        [users.reducerPath]: users.reducer,
        [notifications.reducerPath]: notifications.reducer,
        [polls.reducerPath]: polls.reducer,
        [customApps.reducerPath]: customApps.reducer,
        [updates.reducerPath]: updates.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            setttings.middleware,
            auth.middleware,
            hospital.middleware,
            ems.middleware,
            organization.middleware,
            categories.middleware,
            systemDirectors.middleware,
            ads.middleware,
            analytics.middleware,
            nursingHome.middleware,
            users.middleware,
            notifications.middleware,
            polls.middleware,
            customApps.middleware,
            updates.middleware
        ),
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
