import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { baseUrl } from "../AppConfig";

export const updates = createApi({
    reducerPath: "updatesApi",
    tagTypes: ["Updates"],
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = Cookies.get("LOGIN_TOK");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAllUpdates: builder.query({
            query: (params) => ({
                url: "/api/v1/admin/updates",
                params: { ...params },
            }),
            providesTags: ["Updates"],
        }),
        sendUpdate: builder.mutation({
            query: (body) => ({
                url: "/api/v1/admin/updates",
                body,
                method: "POST",
            }),
            invalidatesTags: ["Updates"],
        }),
        getUpdateById: builder.query({
            query: (id) => ({
                url: `/api/v1/admin/update/${id}`,
            }),
            providesTags: ["Updates"],
        }),
        editUpdate: builder.mutation({
            query: ({ id, ...rest }) => ({
                url: `/api/v1/admin/update/${id}`,
                body: rest,
                method: "PUT",
            }),
            invalidatesTags: ["Updates"],
        }),
        deleteUpdate: builder.mutation({
            query: (id) => ({
                url: `/api/v1/admin/update/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Updates"],
        }),
    }),
});

export const { useGetAllUpdatesQuery, useSendUpdateMutation, useGetUpdateByIdQuery, useEditUpdateMutation, useDeleteUpdateMutation } = updates;
