import cx from "classnames";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useEditEmsMutation } from "../RTK/ems";
import { useEditHospitalMutation } from "../RTK/hospital";
import { useEditNursingHomeMutation } from "../RTK/nursing-home";
import { useChangePasswordMutation, useGetAllOrganizationsQuery, useRemoveOrganizationMutation } from "../RTK/organization";
import ChangePasswordOrganizationDialog from "../components/change-organization-password";
import ConfirmationDialog from "../components/confirmation-dialog";
import OrganizationCategory from "../components/organization-category";
import PaginationComponent from "../components/pagination";

const organizationTypes = [
    {
        type: "hospital",
        label: "Hospital",
    },
    {
        type: "ems",
        label: "EMS",
    },
    {
        type: "nursingHome",
        label: "Nursing Home",
    },
];

const RegisteredOrganizations = () => {
    const [filter, setFilter] = useState({
        type: "",
        label: "",
    });

    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [changePasswordDialog, setChangePasswordDialog] = useState(false);
    const [showOrganizationCategory, setShowOrganizationCategory] = useState(false);
    const [id, setId] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [selectedSubOrganization, setSelectedSubOrganization] = useState({ id: "", type: "" });

    const searchRef = useRef("");

    const toast = useRef();

    const { data, refetch, isFetching } = useGetAllOrganizationsQuery({ page: currentPage, organizationType: filter.type, search, searchKey: "name" });
    const [changePassword] = useChangePasswordMutation();
    const [editHospital, { isLoading: isEditHospitalLoading }] = useEditHospitalMutation();
    const [editEms, { isLoading: isEditEmsLoading }] = useEditEmsMutation();
    const [editNursingHome, { isLoading: isEditNursingHomeLoading }] = useEditNursingHomeMutation();
    const [removeOrganization] = useRemoveOrganizationMutation();

    const handleOrganizationType = (e) => {
        setFilter(e?.value);
    };

    const handleChangeOrganizationPassword = async (newPassword) => {
        const body = {
            newPassword,
            orgId: id,
        };
        const res = await changePassword(body);
        if (res?.data) {
            setChangePasswordDialog(false);
            toast.current.show({ severity: "success", summary: "Successful", detail: "Password changed successfully", life: 3000 });
        }
    };

    const onClearFilter = async () => {
        searchRef.current.value = "";
        setSearch("");
        setFilter({ type: "", label: "" });
    };

    const organizationTypeMap = (rowData) => {
        const map = {
            hospital: "Hospital",
            ems: "EMS",
            nursingHome: "Nursing Home",
        };

        const label = map[rowData?.organizationType];
        return label;
    };

    const hideConfirmDialog = () => {
        setConfirmDialogVisible(false);
    };

    const handleDeleteOrganization = async () => {
        const res = await removeOrganization({ id });
        if (res?.data) {
            toast.current.show({ severity: "success", summary: "Success", detail: res?.data?.msg, life: 3000 });
            setId("");
            setConfirmDialogVisible(false);
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: res?.error?.data?.error ?? "Something went wrong", life: 3000 });
            setId("");
            setConfirmDialogVisible(false);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setSearch(searchRef.current?.value);
    };

    const userProfileTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link
                    to={
                        rowData?.organizationType === "hospital"
                            ? `/dashboard/hospitals/${rowData?.organization?._id}?from=registered-organizations`
                            : rowData?.organizationType === "ems"
                            ? `/dashboard/ems/${rowData?.organization?._id}?from=registered-organizations`
                            : `/dashboard/nursing-homes/${rowData?.organization?._id}?from=registered-organizations`
                    }
                >
                    <span className="image-text">{rowData?.organization?.name ?? "N/A"}</span>
                </Link>
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                {rowData?.passwordCopy && (
                    <a href={`mailto:?to=&body=Email:%20${rowData?.email}%0APassword:%20${rowData?.passwordCopy}`}>
                        <Button style={{ flexShrink: 0 }} label="Share Credentials" className="p-button p-button-rounded p-button-outlined mr-2" />
                    </a>
                )}

                <Button
                    onClick={() => {
                        setSelectedSubOrganization({ id: rowData?.organization?._id, type: rowData?.organizationType, name: rowData?.organization?.name });
                        setShowOrganizationCategory(true);
                    }}
                    icon="pi pi-user-edit"
                    style={{ marginRight: ".5rem", flexShrink: 0 }}
                    className={cx("p-button-rounded ")}
                    label="Categories"
                />

                <Button
                    onClick={() => {
                        setId(rowData?._id);
                        setChangePasswordDialog(true);
                    }}
                    className={cx("p-button-rounded")}
                    style={{ marginRight: ".5rem", background: "#34D1BF", borderColor: "#34D1BF", width: "max-content", flexShrink: 0 }}
                    icon="pi pi-key"
                    label="Change Password"
                />

                <Button
                    icon="pi pi-ban"
                    label="Remove"
                    style={{ flexShrink: 0 }}
                    className={cx("p-button-rounded p-button-danger")}
                    onClick={() => {
                        setId(rowData?._id);
                        setConfirmDialogVisible(true);
                    }}
                />
            </div>
        );
    };

    const [disabledId, setDisabledId] = useState("");

    const Statusbody = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    onClick={() => {
                        setDisabledId(rowData?.organization?._id);
                        rowData?.organizationType === "hospital"
                            ? editHospital({ id: rowData?.organization?._id, isActive: rowData?.organization?.isActive ? false : true }).then(() => refetch())
                            : rowData?.organizationType === "ems"
                            ? editEms({ id: rowData?.organization?._id, isActive: rowData?.organization?.isActive ? false : true }).then(() => refetch())
                            : editNursingHome({ id: rowData?.organization?._id, isActive: rowData?.organization?.isActive ? false : true }).then(() => refetch());
                    }}
                    disabled={
                        rowData?.organizationType === "hospital" && rowData?.organization?._id === disabledId
                            ? isEditHospitalLoading
                            : rowData?.organizationType === "ems" && rowData?.organization?._id === disabledId
                            ? isEditEmsLoading
                            : rowData?.organizationType === "nursingHome" && rowData?.organization?._id === disabledId
                            ? isEditNursingHomeLoading
                            : false
                    }
                    style={{ marginRight: ".5rem" }}
                    label={rowData?.organization?.isActive ? "Active" : "Inactive"}
                    className={cx("p-button-rounded", { "p-button-secondary": !rowData?.organization?.isActive, "p-button-success": rowData?.organization?.isActive })}
                />
            </React.Fragment>
        );
    };

    return (
        <>
            <Helmet>
                <title>Organizations</title>
            </Helmet>
            <Toast ref={toast} />
            <div className="card">
                <div className="py-5">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>
                            <div className="font-medium text-3xl text-900">Registered Organizations</div>
                        </div>
                    </div>
                    <form onSubmit={handleSearch} className="my-4">
                        <Button type="button" label="Clear" onClick={onClearFilter} className="p-button-outlined mr-2" icon="pi pi-filter" />
                        <Dropdown
                            className="mr-2"
                            options={organizationTypes}
                            onChange={handleOrganizationType}
                            optionLabel="label"
                            value={filter}
                            placeholder="Organization Type"
                        />
                        <InputText className="mr-2" ref={searchRef} placeholder="Search..." />
                        <Button type="submit" icon="pi pi-search" label="Search" className="p-button-outlined mr-2" />
                    </form>
                </div>
                <DataTable
                    lazy
                    dataKey="_id"
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    emptyMessage={"No record found"}
                    first={0}
                    value={data?.data}
                    loading={isFetching}
                    rows={10}
                    totalRecords={data?.totalCount}
                >
                    <Column field="name" header="Name" body={userProfileTemplate} />
                    <Column field="email" header="Email" />
                    <Column field="organizationType" body={organizationTypeMap} header="Organization Type" />
                    <Column
                        field="state"
                        body={(rowData) => (
                            <p>{rowData?.organizationType === "ems" ? rowData?.organization?.mailingAddressState : rowData?.organization?.state ?? "N/A"}</p>
                        )}
                        header="State"
                    />
                    <Column field="status" body={Statusbody} header="Status" />
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "8rem" }}></Column>
                </DataTable>
                <div className="pagination">
                    <PaginationComponent
                        totalDocs={data?.totalCount}
                        pageCount={data?.totalPages}
                        currentPage={currentPage}
                        onPageChange={(e) => setCurrentPage(e?.selected + 1)}
                    />
                </div>
            </div>

            <ChangePasswordOrganizationDialog submit={handleChangeOrganizationPassword} show={changePasswordDialog} onHide={() => setChangePasswordDialog(false)} />
            <OrganizationCategory subOrganization={selectedSubOrganization} isVisible={showOrganizationCategory} onHide={() => setShowOrganizationCategory(false)} />
            <ConfirmationDialog
                onSubmit={handleDeleteOrganization}
                msg="Are you sure you want to remove this organization?"
                onHide={hideConfirmDialog}
                show={isConfirmDialogVisible}
            />
        </>
    );
};

export default RegisteredOrganizations;
