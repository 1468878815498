import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetPollByIdQuery, useUpdatePollMutation } from "../RTK/poll";
import Input from "../components/Input";
import Select from "../components/Select";
import TextArea from "../components/TextArea";
import DatePicker from "../components/date-picker";
import Question from "../components/question";
import Spinner from "../components/Spinner";

const polleeTypeOptions = [
    { label: "HOM", value: "organization-manager" },
    { label: "System Director", value: "system-director" },
    { label: "Nurse", value: "nurse" },
    { label: "User", value: "user" },
    { label: "All", value: "all" },
];

const multipleTimeOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

const initialState = {
    name: "",
    description: "",
    polleeType: "",
    dates: [],
    isRepeated: false,
    monthlyOccurrences: [],
    date: "",
    isAllowMultipleTimeResponse: false,
};

const objectsEqual = (o1, o2) =>
    typeof o1 === "object" && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
        : o1 === o2;

const arraysEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

const EditPoll = () => {
    const [form, setForm] = useState(initialState);
    const [tempForm, setTempForm] = useState(initialState);
    const [questions, setQuestions] = useState([]);
    const [tempQuestions, setTempQuestions] = useState([]);

    const changesMade = !(objectsEqual(form, tempForm) && arraysEqual(questions, tempQuestions));

    const toast = useRef();

    const { id } = useParams();

    const { data, isLoading } = useGetPollByIdQuery(id);
    const [updatePoll, { isLoading: updatingPoll }] = useUpdatePollMutation();

    const handleFormChange = (field, value) => {
        const obj = { ...form };
        obj[field] = value;
        setForm(obj);
    };

    const addNewQuestion = () => {
        let arr = [...questions];
        arr.push({
            title: "",
            type: "",
            isRequired: false,
            options: [],
        });
        setQuestions(arr);
    };

    const handleChange = (field, index, value) => {
        console.log(field, index, value);
        let arr = [...questions];
        let obj = { ...arr[index] };
        obj[field] = value;
        arr[index] = obj;
        setQuestions(arr);
    };

    const handleOptionAdd = (index, options) => {
        let arr = [...questions];
        let obj = { ...arr[index] };
        obj["options"] = options;
        arr[index] = obj;
        setQuestions(arr);
    };

    const removeQuestion = (index) => {
        const arr = [...questions];
        arr.splice(index, 1);
        setQuestions(arr);
    };

    const deleteOption = (question, option) => {
        const arr = [...questions];
        let obj = { ...arr[question] };
        let options = [...obj.options];
        options.splice(option, 1);
        obj["options"] = options;
        arr[question] = obj;
        setQuestions(arr);
    };

    const handleSubmit = async () => {
        if (!form.name.trim() || !form.description.trim() || !form.polleeType || questions.length === 0) {
            alert("Please enter all the required fields.");
            return;
        }

        const isValid = questions.every((item) => {
            if (!item.title.trim() || !item.type) return false;
            if (item.type !== "open_ended" && item.options.length === 0) return false;
            return true;
        });

        if (!isValid) {
            alert("Please enter all the required fields");
            return;
        }

        let body = { ...form, questions: [...questions] };
        const monthlyOccurrences = form.dates.map((date) => new Date(date).getDate());
        body["monthlyOccurrences"] = monthlyOccurrences;
        const date = !form.isRepeated && form.dates.length === 1 ? body.dates[0] : "";
        body["date"] = date;
        body["id"] = id;
        delete body.dates;

        try {
            await updatePoll(body);
            toast.current?.show({ severity: "success", summary: "Successful", detail: "Poll updated successfully", life: 1000 });
        } catch (error) {
            console.log(error, "error");
        }
    };

    useEffect(() => {
        if (data) {
            let dates = data?.monthlyOccurrences?.map((date) => new Date(new Date().getFullYear(), new Date().getMonth(), date));
            setForm((prev) => {
                return {
                    ...prev,
                    name: data?.name,
                    description: data?.description,
                    polleeType: data?.polleeType,
                    isRepeated: data?.isRepeated,
                    dates,
                    isAllowMultipleTimeResponse: data?.isAllowMultipleTimeResponse,
                };
            });
            setTempForm((prev) => {
                return {
                    ...prev,
                    name: data?.name,
                    description: data?.description,
                    polleeType: data?.polleeType,
                    isRepeated: data?.isRepeated,
                    dates,
                    isAllowMultipleTimeResponse: data?.isAllowMultipleTimeResponse,
                };
            });
            setQuestions(data?.questions);
            setTempQuestions(data?.questions);
        }
    }, [data]);

    return (
        <>
            <Toast ref={toast} />
            <div className="card py-6">
                {isLoading ? (
                    <div style={{ display: "grid", placeItems: "center" }}>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <h2 className="font-bold mb-6">Edit Poll</h2>
                        <Input
                            value={form.name}
                            onChange={(e) => handleFormChange("name", e.target.value)}
                            label="Poll Name *"
                            className="mb-4"
                            placeholder="Enter Poll Name"
                            style={{ width: "100%", padding: "1rem", marginBottom: "2rem" }}
                        />
                        <TextArea
                            value={form.description}
                            onChange={(e) => handleFormChange("description", e.target.value)}
                            className="mb-4"
                            label="Poll Description *"
                            rows={4}
                            placeholder="Enter poll description"
                            style={{ width: "100%", padding: "1rem" }}
                        />
                        <Select
                            onChange={(e) => handleFormChange("polleeType", e.value)}
                            options={polleeTypeOptions}
                            value={form.polleeType}
                            label="Pollee Type *"
                            placeholder="Select Pollee Type"
                            style={{ width: "100%", padding: "0.5rem" }}
                        />
                        <h4 className="font-bold mb-4">Frequency</h4>
                        <div className="flex align-items-start" style={{ width: "100%" }}>
                            <div style={{ width: "50%" }}>
                                <DatePicker
                                    value={form.dates}
                                    onChange={(e) => handleFormChange("dates", e.value)}
                                    selectionMode="multiple"
                                    label="Date"
                                    placeholder="Select Date"
                                    style={{ width: "100%" }}
                                    inputStyle={{ padding: "1rem" }}
                                />
                            </div>
                            <div className="pl-4">
                                <p style={{ fontWeight: 500 }}>Repeat on</p>
                                <div className="flex align-items-center">
                                    <Button
                                        onClick={() => handleFormChange("isRepeated", false)}
                                        label="Once"
                                        className="p-button-rounded p-3 px-6"
                                        style={{
                                            background: form.isRepeated ? "transparent" : "black",
                                            color: form.isRepeated ? "black" : "white",
                                            borderColor: "black",
                                        }}
                                    />
                                    <Button
                                        onClick={() => handleFormChange("isRepeated", true)}
                                        label="Monthly"
                                        className="p-button-rounded p-button-outlined p-3 px-6 ml-2"
                                        style={{
                                            backgroundColor: form.isRepeated ? "black" : "transparent",
                                            borderColor: "black",
                                            color: form.isRepeated ? "white" : "black",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-4 align-items-center justify-content-between">
                            <h4 className="font-bold mb-4">Questions *</h4>
                        </div>
                        {questions?.map((item, i) => (
                            <Question
                                deleteOption={deleteOption}
                                removeQuestion={removeQuestion}
                                item={item}
                                handleOptionAdd={handleOptionAdd}
                                handleChange={handleChange}
                                index={i}
                                key={i}
                            />
                        ))}
                        <div className="flex align-items-center justify-content-end">
                            <p onClick={addNewQuestion} className="text-primary" style={{ textDecoration: "underline", cursor: "pointer" }}>
                                Add New Question
                            </p>
                        </div>
                        <div className="card py-4 mt-2" style={{ backgroundColor: "#EDF1F5" }}>
                            <div className="flex align-items-center justify-content-between">
                                <p style={{ margin: 0, padding: 0 }}>Can user's respond to poll questions multiple times ? *</p>
                                <Select
                                    placeholder="Select"
                                    onChange={(e) => handleFormChange("isAllowMultipleTimeResponse", e.value)}
                                    options={multipleTimeOptions}
                                    style={{ width: "10rem" }}
                                    value={form.isAllowMultipleTimeResponse}
                                />
                            </div>
                        </div>
                        <div className="text-center mt-2">
                            <Button loading={updatingPoll} disabled={!changesMade || updatingPoll} onClick={handleSubmit} label="Update" className="px-6 py-2" />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default EditPoll;
