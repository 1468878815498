import {Checkbox} from "primereact/checkbox";
import PropTypes from "prop-types"
import React from "react"

const CheckboxComponent = ({label, error, checked, onChange, name}) => {
  return (
    <div>
        <div style={{display: "flex", alignItems: "center", marginTop: "2rem"}} >
            <Checkbox style={{marginRight: "1rem"}} checked={checked} onChange={onChange} name={name} />
            {label && (
                <p >{label}</p>
                )}
        </div>
        {error && (
            <p style={{color: "red"}} >{error}</p>
        )}
    </div>
  )
}

export default CheckboxComponent


CheckboxComponent.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string
}