import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useMemo, useRef } from "react";
import ReactSelect from "react-select";
import * as Yup from "yup";
import {
    useAddOrganizationCategoryMutation,
    useDeleteOrganizationCategoryMutation,
    useGetOrganizationAllCategoriesQuery,
    useGetSelectedOrganizationCategoriesQuery,
} from "../RTK/organization";
import Spinner from "./Spinner";

const OrganizationCategory = ({ onHide, isVisible, subOrganization }) => {
    const toast = useRef();

    const { data: categoriesData, isFetching: isCategoriesLoading } = useGetOrganizationAllCategoriesQuery({ organizationType: subOrganization?.type });
    const { data, isFetching: isSelectedCategoriesLoading } = useGetSelectedOrganizationCategoriesQuery({ subOrganization: subOrganization?.id });
    const [addOrganizationCategory, { isLoading }] = useAddOrganizationCategoryMutation();
    const [deleteOrganizationCategory, { isLoading: isDeletingCategory }] = useDeleteOrganizationCategoryMutation();

    const formik = useFormik({
        initialValues: {
            selectedCategories: [],
        },
        validationSchema: Yup.object().shape({
            selectedCategories: Yup.array().min(1, "Please Select Atleast 1 category").required("This field is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            const body = values.selectedCategories.map((item) => {
                return { category: item?.id, subOrganization: subOrganization?.id, subOrganizationType: subOrganization?.type, availability: "no-status" };
            });
            try {
                const res = await addOrganizationCategory(body);
                if (res.data) {
                    resetForm();
                    toast.current?.show({ severity: "success", summary: "Success", detail: "Categories Added Successfully" });
                    onHide();
                }
            } catch (error) {}
        },
    });

    const categories = useMemo(() => {
        const ids = formik.values.selectedCategories.map((item) => item.id);
        const formattedCategories = categoriesData?.categories?.map((category) => {
            return { id: category?._id, name: category?.name };
        });
        const filteredCategories = formattedCategories?.filter((item) => !ids.includes(item?.id));
        return filteredCategories;
    }, [categoriesData, formik.values.selectedCategories]);

    const handleSelectOrganization = (e) => {
        formik.setFieldValue("selectedCategories", e);
    };

    const footer = () => (
        <div>
            <Button disabled={isLoading || isDeletingCategory} type="button" onClick={formik.handleSubmit}>
                {" "}
                {formik.isSubmitting ? "Please wait.." : "Save"}
            </Button>
        </div>
    );

    const handleSelectDelete = async (item) => {
        try {
            const id = item?.id;
            if (data?.categories?.some((obj) => obj?.category?._id === id)) {
                const res = await deleteOrganizationCategory({ subOrganization: subOrganization?.id, category: id });
                if (res.data) {
                    toast.current.show({ severity: "success", summary: "Success", detail: "Category Removed Successfully", life: 3000 });
                }
            }
            const temp = formik.values.selectedCategories.filter((item) => item?.id !== id);
            formik.setFieldValue("selectedCategories", temp);
        } catch (error) {}
    };

    useEffect(() => {
        if (data) {
            const categories = data?.categories?.map((item) => {
                return { id: item?.category?._id, name: item?.category?.name };
            });
            formik.setFieldValue("selectedCategories", categories);
        }
    }, [data]);

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                footer={footer}
                contentStyle={{ paddingTop: "5rem", paddingBottom: "5rem", display: "flex", justifyContent: "center", flexDirection: "column" }}
                style={{ width: "650px", display: "flex", justifyContent: "center" }}
                draggable={false}
                onHide={onHide}
                visible={isVisible}
                header="Edit Categories"
            >
                {isCategoriesLoading || isSelectedCategoriesLoading ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <div style={{ marginBottom: "1rem" }}>
                            <p style={{ fontWeight: 500 }}>Categories</p>
                            <ReactSelect
                                isMulti={true}
                                options={categories}
                                styles={{
                                    container: (baseStyles) => ({
                                        ...baseStyles,
                                        border: "1px solid #007bff",
                                        borderRadius: "2rem",
                                    }),
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderRadius: "2rem",
                                        border: "1px solid #007bff",
                                        padding: ".3rem",
                                    }),
                                    multiValue: (baseStyles) => ({
                                        ...baseStyles,
                                        background: "#007bff",
                                        color: "white",
                                        borderRadius: "1rem",
                                    }),
                                    multiValueLabel: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "white",
                                    }),
                                }}
                                name="selectedCategories"
                                closeMenuOnSelect={false}
                                controlShouldRenderValue={false}
                                getOptionLabel={(data) => data?.name}
                                getOptionValue={(data) => data?.id}
                                value={formik.values.selectedCategories}
                                onBlur={formik.handleBlur}
                                onChange={handleSelectOrganization}
                            />
                            {formik.touched.selectedCategories && formik.errors.selectedCategories && <p style={{ color: "red" }}>{formik.errors.selectedCategories}</p>}
                        </div>
                        {formik.values.selectedCategories.length > 0 && (
                            <>
                                <div style={{ marginBottom: "1rem", display: "grid", gridTemplateColumns: "repeat(3,1fr)", columnGap: "1rem", rowGap: "1rem" }}>
                                    {formik?.values?.selectedCategories?.map((item) => (
                                        <div
                                            key={item?.id}
                                            style={{
                                                padding: ".5rem",
                                                borderRadius: "1rem",
                                                background: "#007bff",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                            }}
                                        >
                                            <p style={{ color: "white", fontSize: ".8rem", marginBottom: "0", marginRight: ".5rem" }}>{item?.name}</p>
                                            <i
                                                onClick={() => handleSelectDelete(item)}
                                                style={{ fontSize: ".8rem", color: "white", cursor: "pointer" }}
                                                className="pi pi-times"
                                            ></i>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <p style={{ marginTop: "1rem" }}>
                            <strong>Note:</strong> From here, you can select or remove categories from {subOrganization?.name}
                        </p>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default OrganizationCategory;
