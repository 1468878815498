import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDeletePollMutation, useGetAllPollsQuery } from "../RTK/poll";
import ConfirmationDialog from "../components/confirmation-dialog";
import PaginationComponent from "../components/pagination";

const polleeTypeMap = {
    all: "All",
    "organization-manager": "Organization Manager",
    "system-director": "System Director",
    hom: "HOM",
    nurse: "Nurse",
    user: "User",
    all: "All",
};

const polleeTypeBody = (rowData) => {
    return <p>{polleeTypeMap[rowData?.polleeType]}</p>;
};

const noOfQuestionsBody = (rowData) => {
    return <p>{rowData?.questions?.length}</p>;
};

const Polls = () => {
    const [currentPage, setCurrentPage] = useState(1);

    const { data, isFetching } = useGetAllPollsQuery({ page: currentPage });

    const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [selectedPoll, setSelectedPoll] = useState("");

    const [deletePoll, { isLoading }] = useDeletePollMutation();

    const handleDelete = async () => {
        const res = await deletePoll(selectedPoll);
        if (res?.data) {
            setDeleteConfirmationVisible(false);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                <Link to={`/dashboard/polls/view/${rowData?._id}`}>
                    <Button className="p-button p-button-rounded mr-4" label="View Analytics" />
                </Link>
                <Link to={`/dashboard/polls/edit/${rowData?._id}`}>
                    <Button label="Edit" icon="pi pi-pencil" className="p-button-outlined p-button-rounded px-4" />
                </Link>
                <Button
                    onClick={() => {
                        setSelectedPoll(rowData?._id);
                        setDeleteConfirmationVisible(true);
                    }}
                    label="Delete"
                    icon="pi pi-ban"
                    className="p-button-rounded p-button-danger ml-4"
                />
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <title>Polls</title>
            </Helmet>
            <div className="card">
                <div className="py-5">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>
                            <div className="font-medium text-3xl text-900">Polls</div>
                        </div>
                        <div className="mt-3 lg:mt-0">
                            <Link to="/dashboard/polls/create">
                                <Button label="Create Poll" className="p-button-outlined mr-2" icon="pi pi-plus" />
                            </Link>
                        </div>
                    </div>
                </div>
                <DataTable
                    loading={isFetching}
                    lazy
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    emptyMessage={"No record found"}
                    first={0}
                    value={data?.data}
                    rows={10}
                    totalRecords={data?.totalCount}
                >
                    <Column field="name" headerStyle={{ backgroundColor: "#007BFF", color: "white" }} header="Poll Title" />
                    <Column body={polleeTypeBody} headerStyle={{ backgroundColor: "#007BFF", color: "white" }} header="Pollee Type" />
                    <Column body={noOfQuestionsBody} headerStyle={{ backgroundColor: "#007BFF", color: "white" }} header="Number of Questions" />
                    <Column headerStyle={{ backgroundColor: "#007BFF", color: "white" }} header="Actions" body={actionBodyTemplate} />
                </DataTable>
                <div className="pagination">
                    <PaginationComponent
                        onPageChange={(e) => setCurrentPage(e?.selected + 1)}
                        currentPage={currentPage}
                        pageCount={data?.totalPages}
                        totalDocs={data?.totalCount}
                    />
                </div>
            </div>
            <ConfirmationDialog
                isLoading={isLoading}
                onSubmit={handleDelete}
                msg="Are you sure you want to delete this poll?"
                show={isDeleteConfirmationVisible}
                onHide={() => setDeleteConfirmationVisible(false)}
            />
        </>
    );
};

export default Polls;
