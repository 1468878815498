import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { baseUrl } from "../AppConfig"

export const systemDirectors = createApi({
    reducerPath: "systemDirectorsApi",
    tagTypes: ["System"],
    baseQuery: fetchBaseQuery({baseUrl: baseUrl, 
    prepareHeaders: (headers, {getState}) => {
        const token = Cookies.get("LOGIN_TOK")
        if(token){
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    }
}),
    endpoints: builder => ({
        getAllSystemDirectors: builder.query({
            query: (args) => ({
                url: "/api/v1/admin/organizations",
                params: {homType: "admin", ...args}
            }),
            providesTags: ["System"]
        }),
        register: builder.mutation({
            query: (body) => ({
                url: "/api/v1/org/auth/register?homType=system-director",
                method: "POST",
                body,
            }),
            invalidatesTags: ["System"]
        }),
        edit: builder.mutation({
            query: body => {
                const {systemDirector, adminSystemDirector, ...rest} = body
                return {
                    url: `/api/v1/org/update`,
                    method: "PUT",
                    params: {systemDirector, adminSystemDirector},
                    body: rest
                }
            },
            invalidatesTags: ["System"]
        }),
        deleteSystemDirector: builder.mutation({
            query: params => {
                console.log(params, "params")
                return {
                    url: "/api/v1/admin/system-directors",
                    params: {...params},
                    method: "DELETE"
                }
            },
            invalidatesTags: ["System"]
        })
    })
})

export const {useGetAllSystemDirectorsQuery, useRegisterMutation, useEditMutation, useDeleteSystemDirectorMutation} = systemDirectors