import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from "primereact/multiselect"

const Select = ({multi, onBlur, style, label, placeholder, options, value, onChange, error, name, ...rest}) => {
  return (
    <div>
        {label && (
            <p style={{fontWeight: 500}} >{label}</p>
        )}
        {multi ? (
          <MultiSelect  {...rest}  name={name} value={value} options={options} onChange={onChange} placeholder={placeholder} style={style} onBlur={onBlur} />
        ) : (
          <Dropdown {...rest} name={name} value={value} options={options} onChange={onChange} placeholder={placeholder} style={style} onBlur={onBlur} />
        )}
        {error && (
            <p style={{color: "red"}} >{error}</p>
        )}
    </div>
  )
}

export default Select