import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import AddOrganizationDialog from "../components/customApps/add-organization-dialog";
import { useAddOrganizationMutation, useDeleteOrganizationMutation, useGetCustomAppOrganizationsQuery } from "../RTK/custom-apps";
import { useParams, useLocation } from "react-router-dom";
import PaginationComponent from "../components/pagination";
import ConfirmationDialog from "../components/confirmation-dialog";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { Link } from "react-router-dom";

const organizationTypes = [
    {
        type: "hospital",
        label: "Hospital",
    },
    {
        type: "ems",
        label: "EMS",
    },
    {
        type: "nursingHome",
        label: "Nursing Home",
    },
];

const orgs = [
    {
        name: "Some name",
    },
    {
        name: "Some name 2",
    },
];

const CustomAppDetails = () => {
    const { id } = useParams();
    const [showAddOrganizationModal, setShowAddOrganizationModal] = useState(false);
    const [organizationType, setOrganizationType] = useState({ name: "", type: "" });
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedIdForDelete, setSelectedIdForDelete] = useState("");
    const [keyword, setKeyword] = useState("");
    const toastRef = useRef();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const organizationName = searchParams.get("name");

    const { data: organizations, isFetching: isLoading } = useGetCustomAppOrganizationsQuery({
        id,
        page: currentPage,
        limit: 10,
        organizationType: organizationType.type,
        keyWord: keyword,
    });
    const [addOrganization, { isLoading: isAddingOrganization }] = useAddOrganizationMutation();
    const [deleteOrganization, { isLoading: isDeletingOrganization }] = useDeleteOrganizationMutation();

    const handleOnAdd = async (organizations, resetForm) => {
        try {
            const body = organizations?.map((item) => {
                return { organizationType: item.type, organization: item.id, customApp: id };
            });
            const res = await addOrganization(body);
            console.log(res, "res");
            if (res?.data?.message?.toLowerCase() === "success") {
                console.log("dad");
                resetForm();
                setShowAddOrganizationModal(false);
                toastRef.current.show({ severity: "success", summary: "Success", detail: "Organization Added Successfully", life: 3000 });
            }
        } catch (error) {
            toastRef.current.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
        }
    };

    const actionBodyTemplate = (rowData) => {
        return <Button onClick={() => setSelectedIdForDelete(rowData?._id)} label="Remove" icon="pi pi-ban" className="p-button-rounded p-button-danger" />;
    };

    const handleDeleteOrganization = async () => {
        try {
            const res = await deleteOrganization(selectedIdForDelete);
            if (res.data) {
                setSelectedIdForDelete("");
                toastRef.current.show({ severity: "success", summary: "Success", detail: "Organization Deleted Successfully", life: 3000 });
            }
        } catch (error) {
            toastRef.current.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
        }
    };

    const nameBody = (rowData) => {
        return <p>{rowData?.name}</p>;
    };

    return (
        <>
            <Toast ref={toastRef} />
            <div className="card py-5">
                <div className="py-5">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div className="flex align-items-center">
                            <Link to="/dashboard/custom-apps">
                                <Button icon="pi pi-arrow-left" />
                            </Link>
                            <div className="font-medium text-3xl ml-4 text-900">{organizationName}</div>
                        </div>

                        <div className="mt-3 lg:mt-0">
                            <Button onClick={() => setShowAddOrganizationModal(true)} label="Add Organization" className="p-button-outlined mr-2" icon="pi pi-plus" />
                        </div>
                    </div>
                </div>
                <form>
                    <div className="my-4 flex items-center ">
                        <Button
                            onClick={() => {
                                setOrganizationType({ name: "", type: "" });
                                setCurrentPage(1);
                                setKeyword("");
                            }}
                            label="Clear"
                            type="button"
                            className="p-button-outlined mr-2"
                            icon="pi pi-filter"
                        />
                        <Dropdown
                            onChange={(e) => setOrganizationType(e.value)}
                            className="mr-2"
                            options={organizationTypes}
                            optionLabel="label"
                            placeholder="Organization Type"
                            value={organizationType}
                        />
                        <InputText value={keyword} onChange={(e) => setKeyword(e.target.value)} className="mr-2" placeholder="Search..." />
                    </div>
                </form>
                <DataTable
                    lazy
                    dataKey="_id"
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    emptyMessage={"No record found"}
                    first={0}
                    value={organizations?.organizationList[0]?.data}
                    rows={10}
                    loading={isLoading}
                    totalRecords={2}
                >
                    <Column field="name" header="Name" headerStyle={{ backgroundColor: "#007BFF", color: "white" }} body={nameBody} />
                    <Column header="Actions" headerStyle={{ backgroundColor: "#007BFF", color: "white" }} body={actionBodyTemplate} />
                </DataTable>
                <div className="pagination">
                    <PaginationComponent
                        onPageChange={(e) => setCurrentPage(e?.selected + 1)}
                        currentPage={currentPage}
                        pageCount={organizations?.organizationList[0]?.totalPages}
                        totalDocs={organizations?.organizationList[0]?.totalCount}
                    />
                </div>
            </div>
            <AddOrganizationDialog
                addedOrganizations={organizations?.organizationList}
                onAdd={handleOnAdd}
                show={showAddOrganizationModal}
                onHide={() => setShowAddOrganizationModal(false)}
            />
            <ConfirmationDialog
                onSubmit={handleDeleteOrganization}
                show={selectedIdForDelete !== ""}
                onHide={() => setSelectedIdForDelete("")}
                isLoading={isDeletingOrganization}
                msg="Are you sure you want to delete this organization?"
            />
        </>
    );
};

export default CustomAppDetails;
