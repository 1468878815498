import ApiManager from "../../util/api-manager";
import { deleteSessionCookie } from "../../util/cookie";
import { AUTH_TOKEN } from "../../util/enums";
import { LOGIN, LOGOUT, IS_LOGGIN_IN } from "./actionTypes";

export const loginFun = (email, password, successCb, errorCb) => {
    return async (dispatch) => {
        if (!email || !password) {
            errorCb();
            return null;
        } else {
            dispatch({ type: IS_LOGGIN_IN, payload: true });
            const res = await new ApiManager().Login(email, password);
            dispatch({ type: IS_LOGGIN_IN, payload: false });

            if (res.success) {
                let userRes = await new ApiManager().whoami();
                dispatch({ type: LOGIN, payload: userRes.data });
                successCb();
            } else errorCb();
        }
    };
};

export const whoami = (errorCb = () => {}, successCb = () => {}) => {
    return async (dispatch) => {
        const res = await new ApiManager().whoami();
        if (res.success) {
            dispatch({ type: LOGIN, payload: res.data });
            successCb();
        } else {
            errorCb();
        }
    };
};

/**
 * Logout User
 * @returns
 */
export const setLogout = (successCb = () => {}) => {
    deleteSessionCookie(AUTH_TOKEN);
    successCb()
    return {
        type: LOGOUT,
    };
};
