import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { ToggleButton } from "primereact/togglebutton";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import {
    useCreateCategoryMutation,
    useDeleteCategoryMutation,
    useEditCategoryMutation,
    useGetCategoryByOrganizationQuery,
    useGetExpiryTimeQuery,
    useUpdateExpiryTimeMutation,
} from "../RTK/categories";
import AddCategory from "../components/add-category";
import ConfirmationDialog from "../components/confirmation-dialog";
import EditCategory from "../components/edit-category";
import PaginationComponent from "../components/pagination";
import { useMemo } from "react";

const organizationTypes = [
    {
        type: "hospital",
        label: "Hospital",
    },
    {
        type: "ems",
        label: "EMS",
    },
    {
        type: "nursingHome",
        label: "Nursing Home",
    },
    {
        type: "bh",
        label: "Behavioral Health",
    },
];

const statusExpirationOptions = [
    {
        type: "1",
        label: "1 Day",
    },
    {
        type: "2",
        label: "2 Days",
    },
    {
        type: "3",
        label: "3 Days",
    },
    {
        type: "4",
        label: "4 Days",
    },
    {
        type: "5",
        label: "5 Days",
    },
    {
        type: "6",
        label: "6 Days",
    },
    {
        type: "7",
        label: "7 Days",
    },
];

const Categories = () => {
    const [filter, setFilter] = useState({
        type: "hospital",
        label: "Hospital",
    });

    const [isAddCategoryVisible, setAddCategoryVisible] = useState(false);
    const [isEditCategoryVisible, setEditCategoryVisible] = useState(false);
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState("");

    const toast = useRef();

    const { data, isFetching, isError } = useGetCategoryByOrganizationQuery({
        organizationType: filter?.type === "bh" ? "hospital" : filter?.type,
        page: currentPage,
        isBH: filter.type === "bh" ? true : null,
        limit: 100,
    });
    const { data: expiryTime } = useGetExpiryTimeQuery();
    const [createCategory] = useCreateCategoryMutation();
    const [editCategory] = useEditCategoryMutation();
    const [deleteCategory] = useDeleteCategoryMutation();
    const [updateExpiryTime, { isLoading: isUpdatingStatus }] = useUpdateExpiryTimeMutation();

    const statusExpiryTime = useMemo(() => {
        return statusExpirationOptions.find((item) => item.type == expiryTime?.days);
    }, [expiryTime]);

    const createdAtBody = (rowData) => {
        const date = new Date(rowData.createdAt).toDateString();

        return <p>{date}</p>;
    };

    const updatedAtBody = (rowData) => {
        const date = new Date(rowData.updatedAt).toDateString();

        return <p>{date}</p>;
    };

    const handleOrganizationType = (e) => {
        setFilter(e?.value);
    };

    const handleStatusExpiryChange = async (e) => {
        const res = await updateExpiryTime(e?.value?.type);
        try {
            toast.current?.show({ severity: "success", summary: "Success", detail: res?.data?.message });
        } catch (error) {
            toast.current?.show({ severity: "error", summary: "error", detail: "Something went wrong" });
        }
    };

    const onClearFilter = async () => {
        setFilter({ type: "hospital", label: "Hospital" });
    };

    const hideAddCategory = () => {
        setAddCategoryVisible(false);
    };

    const showAddCategory = () => {
        setAddCategoryVisible(true);
    };

    const showConfirmDialog = (id) => {
        setId(id);
        setConfirmDialogVisible(true);
    };

    const hideConfirmDialog = () => {
        setConfirmDialogVisible(false);
    };

    const hideEditCategory = () => {
        setEditCategoryVisible(false);
    };

    const showEditCategory = (id) => {
        setEditCategoryVisible(true);
        setId(id);
    };

    const handleAddCategorySubmit = async (values, setSubmitting) => {
        const body = {
            categoryName: values.name?.trim(),
            orgType: values.type?.type === "bh" ? "hospital" : values?.type?.type,
            isLightSystem: values.trafficLightSystem,
        };
        const res = await createCategory({ ...body, params: { isBH: values?.type?.type === "bh" ? true : null } });
        if (res?.data) {
            toast.current.show({ severity: "success", summary: "Success", detail: res?.data?.msg, life: 3000 });
            setSubmitting(false);
            setAddCategoryVisible(false);
        } else {
            setSubmitting(false);
            toast.current.show({ severity: "error", summary: "Error", detail: res?.error?.data?.error ?? "Something went wrong", life: 3000 });
        }
    };

    const handleEditCategorySubmit = async (values, setSubmitting) => {
        const body = {
            categoryName: values.name?.trim(),
            orgType: values?.type?.type === "bh" ? "hospital" : values.type?.type,
            isLightSystem: values.trafficLightSystem,
            id,
            params: { isBH: values?.type?.type === "bh" ? true : null },
        };
        const res = await editCategory(body);
        if (res?.data) {
            toast.current.show({ severity: "success", summary: "Success", detail: "Category Edited Successfully", life: 3000 });
            setSubmitting(false);
            setId("");
            setEditCategoryVisible(false);
        }
    };

    const handleDeleteCategorySubmit = async () => {
        const res = await deleteCategory(id);
        if (res?.data) {
            toast.current.show({ severity: "success", summary: "Success", detail: res?.data?.msg, life: 3000 });
            setId("");
            setConfirmDialogVisible(false);
        }
    };

    const userProfileTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="image-text">{`${rowData.name}`}</span>
            </React.Fragment>
        );
    };

    const menuRef = useRef();

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button onClick={() => showEditCategory(rowData?._id)} className="p-button-rounded p-button-primary p-mr-2" icon="pi pi-user-edit" />
                <Button
                    onClick={() => showConfirmDialog(rowData?._id)}
                    className="p-button-rounded p-button-danger"
                    icon="pi pi-ban"
                    style={{ marginLeft: ".5rem" }}
                    label="Delete"
                />
            </React.Fragment>
        );
    };

    const lightSystemBody = (rowData) => {
        return <p>{JSON.stringify(rowData?.isLightSystem)}</p>;
    };

    const defaultBody = (rowData) => {
        return (
            <ToggleButton
                style={{ backgroundColor: rowData?.isDefaultSelected ? "#28a745" : "grey" }}
                checked={rowData?.isDefaultSelected}
                onChange={async (e) => {
                    const res = await editCategory({
                        id: rowData?._id,
                        isDefaultSelected: e?.value,
                    });
                    console.log(res);
                }}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Categories</title>
            </Helmet>
            <Toast ref={toast} />
            <div className="card">
                <div className="py-5">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>
                            <div className="font-medium text-3xl text-900">Categories</div>
                        </div>
                        <div className="mt-3 lg:mt-0">
                            <Button label="Add" onClick={showAddCategory} className="p-button-outlined mr-2" icon="pi pi-plus" />
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <div className="my-4">
                            <Button label="Clear" onClick={onClearFilter} className="p-button-outlined mr-2" icon="pi pi-filter" />
                            <Dropdown
                                className="mr-2"
                                options={organizationTypes}
                                onChange={handleOrganizationType}
                                optionLabel="label"
                                value={filter}
                                placeholder="Organization Type"
                            />
                        </div>
                        <div className="my-4 flex align-items-center">
                            <div className="flex align-items-center">
                                <i className="pi pi-clock" />
                                <p style={{ margin: 0 }} className="mr-2 ml-2 font-semibold">
                                    Set Categories Expiry Time:
                                </p>
                            </div>
                            <Dropdown
                                className="mr-2"
                                options={statusExpirationOptions}
                                onChange={handleStatusExpiryChange}
                                optionLabel="label"
                                value={statusExpiryTime}
                                disabled={isUpdatingStatus}
                                placeholder="Status Expiry Time"
                            />
                        </div>
                    </div>
                </div>
                <DataTable
                    lazy
                    // paginator
                    dataKey="_id"
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    emptyMessage={"No record found"}
                    first={0}
                    loading={isFetching}
                    value={isError ? [] : data?.categories}
                    rows={10}
                    totalRecords={data?.categories?.totalCount}
                >
                    <Column field="name" header="Name" body={userProfileTemplate} />
                    {/* <Column body={organizationTypeMap} field="organizationType" header="Organization Type"  /> */}
                    <Column body={lightSystemBody} field="isLightSystem" header="Light System" />
                    <Column body={defaultBody} header="Is Selected By Default" />
                    <Column field="createdAt" body={createdAtBody} header="Created At" />
                    <Column field="updatedAt" body={updatedAtBody} header="Updated At" />
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "8rem" }}></Column>
                </DataTable>
                <div className="pagination">
                    <PaginationComponent
                        pageCount={data?.totalPages}
                        totalDocs={data?.totalCount}
                        currentPage={currentPage}
                        onPageChange={(e) => setCurrentPage(e?.selected + 1)}
                    />
                </div>
            </div>
            <AddCategory submit={handleAddCategorySubmit} onHide={hideAddCategory} show={isAddCategoryVisible} />
            <EditCategory selectedOption={filter.type} submit={handleEditCategorySubmit} id={id} onHide={hideEditCategory} show={isEditCategoryVisible} />
            <ConfirmationDialog
                onSubmit={handleDeleteCategorySubmit}
                msg="Are you sure you want to delete this category?"
                onHide={hideConfirmDialog}
                show={isConfirmDialogVisible}
            />
        </>
    );
};

export default Categories;
