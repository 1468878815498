import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useEditUpdateMutation, useGetUpdateByIdQuery } from "../RTK/updates";
import { toTypes } from "../pages/updates";
import Input from "./Input";
import Spinner from "./Spinner";
import ConfirmationDialog from "./confirmation-dialog";

const EditUpdate = ({ onHide, show, selectedUpdate }) => {
    const [isEditConfirmationVisible, setIsEditConfirmationVisible] = useState(false);
    const { data, isFetching: isUpdateLoading } = useGetUpdateByIdQuery(selectedUpdate);

    const [editUpdate, { isLoading }] = useEditUpdateMutation();

    const toastRef = useRef();

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            type: "",
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required("This field is requried"),
            description: Yup.string().required("This field is requried"),
            type: Yup.string().required("This field is requried"),
        }),
        onSubmit: async () => {
            setIsEditConfirmationVisible(true);
        },
    });

    const handleSubmit = async () => {
        const body = {
            title: formik.values.title,
            body: formik.values.description,
            to: formik.values.type,
            id: selectedUpdate,
        };
        try {
            const res = await editUpdate(body);
            if (res.data) {
                toastRef.current?.show({ severity: "success", summary: "Success", detail: "Update Sent Successfully" });
                setIsEditConfirmationVisible(false);
                onHide();
            }
        } catch (error) {
            toastRef.current?.show({ severity: "error", summary: "error", detail: "Something went wrong" });
        }
    };

    useEffect(() => {
        if (data) {
            formik.setFieldValue("title", data?.update?.title);
            formik.setFieldValue("description", data?.update?.body);
            formik.setFieldValue("type", data?.update?.to);
        }
    }, [data]);

    const footer = () => (
        <div>
            <Button disabled={isLoading} onClick={formik.handleSubmit}>
                {" "}
                {formik.isSubmitting ? "Please wait.." : "Update"}
            </Button>
        </div>
    );

    return (
        <>
            <Toast ref={toastRef} />
            <Dialog footer={footer} draggable={false} style={{ width: "450px" }} header="Edit Update" visible={show} onHide={onHide}>
                {isUpdateLoading ? (
                    <div className="flex align-items-center justify-content-center py-4">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <Input
                            type="text"
                            style={{ width: "100%" }}
                            label="Title"
                            value={formik.values.title}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="title"
                            error={formik.touched.title && formik.errors.title}
                        />
                        <div style={{ marginTop: "1rem" }}>
                            <p style={{ fontWeight: 500 }}>Description </p>
                            <InputTextarea
                                rows={3}
                                value={formik.values.description}
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{ width: "100%" }}
                            />
                            {formik.touched.description && formik.errors.description && <p style={{ color: "red" }}>{formik.errors.description}</p>}
                        </div>

                        <div style={{ marginTop: "1rem" }}>
                            <p style={{ fontWeight: 500 }}>Type </p>
                            <Dropdown
                                style={{ width: "100%" }}
                                className="mr-2"
                                onChange={(e) => formik.setFieldValue("type", e.value)}
                                options={toTypes}
                                optionValue="value"
                                optionLabel="label"
                                value={formik.values.type}
                                placeholder="Type"
                            />
                            {formik.touched.type && formik.errors.type && <p style={{ color: "red" }}>{formik.errors.type}</p>}
                        </div>
                    </>
                )}
            </Dialog>
            <ConfirmationDialog
                isLoading={isLoading}
                msg="Are you sure you want to edit this update? It will notify receivers again."
                show={isEditConfirmationVisible}
                onHide={() => setIsEditConfirmationVisible(false)}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default EditUpdate;
