import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import ReactSelect from "react-select";
import { useSendNotificationMutation } from "../RTK/notifications";
import Input from "../components/Input";
import SpecificOrganization from "../components/notifications/specific-organization";
import { storage } from "../firebase";

const userTypes = [
    {
        label: "Users",
        value: "user",
    },
    {
        label: "HOM",
        value: "organization",
    },
    {
        label: "Nurses",
        value: "nurse",
    },
];

const organizationTypes = [
    {
        label: "Hospitals",
        value: "hospital",
    },
    {
        label: "EMS",
        value: "ems",
    },
    {
        label: "Nursing Homes",
        value: "nursingHome",
    },
];

const allOptions = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Specific",
        value: "specific",
    },
];

const Notifications = () => {
    const [userType, setUserType] = useState("");
    const [organizationType, setOrganizationType] = useState({ value: "hospital", label: "Hospitals" });
    const [all, setAll] = useState({ value: "all", label: "All" });
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [organizations, setOrganizations] = useState([]);
    const [openSpecificModal, setOpenSpecificModal] = useState(false);
    const [imageKey, setImageKey] = useState(Math.random().toString(36));
    const [isUploadingImage, setIsUploadingImage] = useState(false);

    const toastRef = useRef();

    const [sendNotification, { isLoading: isSendingNotification }] = useSendNotificationMutation();

    const handleUserTypeChange = (value) => {
        setUserType(value);
        setAll({ value: "all", label: "All" });
    };

    const handleOrganizationTypeChange = (value) => {
        setOrganizationType(value);
        setAll({ value: "all", label: "All" });
        setOrganizations([]);
    };

    const handleAllOnChange = (e) => {
        if (e.value === "specific") {
            setOpenSpecificModal(true);
        }
        setAll(e);
        setOrganizations([]);
    };

    const handleSpecificOrganizationSelected = (orgs) => {
        setOrganizations(orgs);
    };

    const handleSuccessFullImageUpload = async (uploadTask) => {
        let body = {
            userType,
            organizationType: userType === "user" ? organizationType.value : "",
            specificOrganizations: all.value === "all" ? false : true,
            organizations,
            title,
            body: description,
        };
        if (uploadTask) {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            body.image = url;
            setIsUploadingImage(false);
        }
        const res = await sendNotification(body);
        if (res.data?.success) {
            setOrganizationType({ value: "hospital", label: "Hospitals" });
            setAll({ value: "all", label: "all" });
            setUserType("");
            setOrganizations([]);
            setTitle("");
            setDescription("");
            setImage("");
            setImageKey(Math.random().toString(36));
            toastRef.current.show({ severity: "success", summary: "Successful", detail: "Notification Sent Successfully", life: 3000 });
        } else {
            toastRef.current.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
        }
    };

    const handleImageUploadError = () => {
        toastRef.current.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
    };

    const handleSubmit = () => {
        if (userType === "user") {
            if (!title.trim() || !description.trim() || !userType || !organizationType.value) {
                alert("Please select all the required fields.");
                return;
            }
        } else {
            if (!title.trim() || !description.trim() || !userType) {
                alert("Please select all the required fields.");
                return;
            }
        }

        if (all.value === "specific" && organizations.length === 0) {
            alert("Please select an organization.");
            return;
        }

        if (image) {
            setIsUploadingImage(true);
            const storageRef = ref(storage, `images/${image.name}`);
            const uploadTask = uploadBytesResumable(storageRef, image);
            uploadTask.on(
                "state_changed",
                () => {},
                handleImageUploadError,
                () => handleSuccessFullImageUpload(uploadTask)
            );
        } else {
            handleSuccessFullImageUpload();
        }
    };

    return (
        <>
            <Helmet>
                <title>Send Notification</title>
            </Helmet>
            <Toast ref={toastRef} />
            <div>
                <h1>Send Push Notifications</h1>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)", gap: "1rem", marginTop: "4rem" }}>
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} label="Title *" style={{ width: "100%" }} />

                    <Input key={imageKey} onChange={(e) => setImage(e.target.files[0])} type="file" label="Image" style={{ width: "100%" }} />

                    <div>
                        <p style={{ fontWeight: 500 }}>Description *</p>
                        <InputTextarea value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: "100%" }} />
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }} className="mt-4">
                    <p style={{ marginTop: "1rem", marginRight: "1rem" }}>User Type *: </p>
                    {userTypes.map((item) => (
                        <div className="flex align-items-center mr-4">
                            <RadioButton
                                inputId={item.value}
                                onChange={(e) => handleUserTypeChange(e.value)}
                                name={item.value}
                                value={item.value}
                                checked={userType === item.value}
                            />
                            <label htmlFor={item.value} className="ml-2">
                                {item.label}
                            </label>
                        </div>
                    ))}
                </div>
                {userType === "user" ? (
                    <>
                        <div style={{ display: "flex", alignItems: "center" }} className="mt-4">
                            <p style={{ marginTop: "1rem", marginRight: "1rem" }}>Organization Type: </p>
                            <ReactSelect
                                isSearchable={false}
                                value={organizationType}
                                onChange={(e) => handleOrganizationTypeChange(e)}
                                placeholder="Organization Type"
                                options={organizationTypes}
                            />
                        </div>
                        {organizationType && (
                            <div style={{ display: "flex", alignItems: "center" }} className="mt-4">
                                <p style={{ marginTop: "1rem", marginRight: "1rem" }}>All or Specific: </p>
                                <ReactSelect isSearchable={false} options={allOptions} value={all} onChange={(e) => handleAllOnChange(e)} />
                            </div>
                        )}
                    </>
                ) : userType === "nurse" ? (
                    <div style={{ display: "flex", alignItems: "center" }} className="mt-4">
                        <p style={{ marginTop: "1rem", marginRight: "1rem" }}>All or Specific: </p>
                        <ReactSelect isSearchable={false} options={allOptions} value={all} onChange={(e) => handleAllOnChange(e)} />
                    </div>
                ) : userType === "organization" ? (
                    <div style={{ display: "flex", alignItems: "center" }} className="mt-4">
                        <p style={{ marginTop: "1rem", marginRight: "1rem" }}>All: </p>
                        <ReactSelect isSearchable={false} options={[{ label: "All", value: "all" }]} value={all} onChange={(e) => handleAllOnChange(e)} />
                    </div>
                ) : null}
                <Button
                    disabled={isUploadingImage || isSendingNotification}
                    loading={isUploadingImage || isSendingNotification}
                    onClick={handleSubmit}
                    label="Send"
                    className="mt-4"
                />
            </div>
            <SpecificOrganization
                handleSpecificOrganizationSelected={handleSpecificOrganizationSelected}
                isVisible={openSpecificModal}
                onHide={() => {
                    setOpenSpecificModal(false);
                }}
                type={userType}
                orgType={organizationType.value}
            />
        </>
    );
};

export default Notifications;
