import { Button } from "primereact/button";
import React from "react";
import UsersDialog, { getType } from "./UsersDialog";
import { useState } from "react";
import { useMemo } from "react";

const colors = [
    "linear-gradient(0deg, #F765A3, #F765A3),linear-gradient(0deg, #FFA5CB, #FFA5CB)",
    "linear-gradient(0deg, #63ABFD, #63ABFD),linear-gradient(0deg, #165BAA, #165BAA)",
    "linear-gradient(0deg, #A155B9, #A155B9),linear-gradient(0deg, #E697FF, #E697FF)",
];

export const headerBody = (rowData) => {
    const type = getType(rowData);
    if (type === "organization") {
        const name = rowData?.organization?.name;
        return <p>{name}</p>;
    } else if (type === "hom") {
        const name = rowData?.hom[0]?.organization?.name;
        return <p>{name}</p>;
    } else {
        return <p>{rowData?.fullName}</p>;
    }
};

const SelectionView = ({ question }) => {
    const [isSeeingUsers, setSeeingUsers] = useState(false);
    const [pollees, setPollees] = useState([]);

    const totalCount = useMemo(() => question?.selections?.reduce((total, data) => total + data.count, 0), [question]);

    const dataWithPercentages = useMemo(() => {
        return question?.selections?.map((data) => ({
            option: data.option,
            count: data.count,
            pollees: data?.pollees,
            percentage: totalCount === 0 ? 0 : (data.count / totalCount) * 100,
        }));
    }, [question]);

    const handleShowUsers = (users) => {
        setPollees(users);
        setSeeingUsers(true);
    };

    return (
        <>
            {dataWithPercentages?.map((option, index) => {
                const bgColorIndex = index % colors.length;
                return (
                    <div className="flex align-items-center" style={{ borderBottom: "1px solid lightgray" }} key={index}>
                        <p style={{ width: "15%", margin: 0, padding: "0.8rem 0" }}>{option?.option}</p>
                        <div style={{ width: "70%", borderLeft: "1px solid lightgray", padding: "0.8rem 0" }}>
                            <div style={{ height: "1rem", width: `${option.percentage}%`, background: colors[bgColorIndex] }} />
                            <div className="flex align-items-center mt-2" style={{ gap: "2rem", paddingLeft: "2rem" }}>
                                {option?.pollees
                                    ?.filter((_, i) => i < 3)
                                    ?.map((pollee) => {
                                        return (
                                            <div className="flex align-items-center" style={{ gap: "0.5rem" }}>
                                                <div style={{ borderRadius: "50%", width: "0.5rem", height: "0.5rem", backgroundColor: "rgba(22, 91, 170, 1)" }} />
                                                {headerBody(pollee)}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div style={{ width: "15%" }}>
                            <Button onClick={() => handleShowUsers(option?.pollees)} label="View all users" />
                        </div>
                    </div>
                );
            })}
            <UsersDialog users={pollees} visible={isSeeingUsers} onClose={() => setSeeingUsers(false)} />
        </>
    );
};

export default SelectionView;
