import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import Cookies from "js-cookie";
import { baseUrl } from "../AppConfig";

export const polls = createApi({
    reducerPath: "pollsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            const token = Cookies.get("LOGIN_TOK");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Polls"],
    endpoints: (builder) => ({
        getAllPolls: builder.query({
            query: (params) => ({
                url: "/api/v1/polls/admin",
                params: { ...params },
            }),
            providesTags: ["Polls"],
        }),
        createPoll: builder.mutation({
            query: (body) => ({
                url: "/api/v1/polls/admin",
                body,
                method: "POST",
            }),
            invalidatesTags: ["Polls"],
        }),
        deletePoll: builder.mutation({
            query: (id) => ({
                url: "/api/v1/polls/admin",
                params: { poll: id },
                method: "PATCH",
            }),
            invalidatesTags: ["Polls"],
        }),
        getPollById: builder.query({
            query: (id) => ({
                url: `/api/v1/polls/${id}/admin`,
            }),
            providesTags: ["Polls"],
        }),
        updatePoll: builder.mutation({
            query: (body) => {
                const { id, ...rest } = body;
                return {
                    url: "/api/v1/polls/admin",
                    params: { poll: id },
                    method: "PUT",
                    body: rest,
                };
            },
            invalidatesTags: ["Polls"],
        }),
        getPollAnalytics: builder.query({
            query: (id) => ({
                url: `/api/v1/polls/${id}/admin/analytics`,
            }),
        }),
        getPollQuestionById: builder.query({
            query: ({ pollId, questionId }) => ({
                url: `/api/v1/polls/${pollId}/admin/analytics/${questionId}`,
            }),
        }),
    }),
});

export const {
    useGetAllPollsQuery,
    useCreatePollMutation,
    useDeletePollMutation,
    useGetPollByIdQuery,
    useUpdatePollMutation,
    useGetPollAnalyticsQuery,
    useGetPollQuestionByIdQuery,
} = polls;
