/**
 * Get data from localstorage
 * @param {string} key
 * @param {boolean} toJson
 */
export const getData = (key, toJson = false) => {
  let data = '';
  try {
    data = localStorage.getItem(key);
  } catch (error) {
    return '';
  }
  if (toJson) return JSON.parse(data);
  return data;
};

/**
 * Save data in localstorage
 * @param {string} key
 * @param {string} value
 * @param {boolean} stringify
 */
export const saveData = (key, value, stringify = false) => {
  try {
    if (stringify) localStorage.setItem(key, JSON.stringify(value));
    else localStorage.setItem(key, value);
  } catch (error) {
    return false;
  }
  return true;
};

/**
 * Delete data from localstorage
 * @param {string} key
 * @returns
 */
export const deleteData = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    return false;
  }
  return true;
};

/**
 * Clear localstorage
 * @returns
 */
export const clearStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    return false;
  }
  return true;
};
