import classNames from "classnames";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";
import "primereact/resources/primereact.min.css";
import "prismjs/themes/prism-coy.css";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./App.scss";
import { AppMenu } from "./AppMenu";
import { AppTopbar } from "./AppTopbar";
import "./assets/layout/flags/flags.css";
import "./assets/layout/layout.scss";
import ProgressSpinner from "./components/progress-spinner";
import Ads from "./pages/ads";
import Analytics from "./pages/analytics";
import Categories from "./pages/categories";
import CreatePoll from "./pages/create-poll";
import CustomAppDetails from "./pages/custom-app-details";
import CustomApps from "./pages/custom-apps";
import EditPoll from "./pages/edit-poll";
import Notifications from "./pages/notifications";
import Polls from "./pages/polls";
import RegisteredOrganizations from "./pages/registered-organizations";
import SystemDirectors from "./pages/system-directors";
import Updates from "./pages/updates";
import UserDetails from "./pages/user-details";
import ViewPollAnalytics from "./pages/view-poll-analytics";
import ViewPollQuestion from "./pages/view-poll-question";

const Dashboard = lazy(() => import("./pages/dashboard"));
const Freelancer = lazy(() => import("./pages/hospital"));
const HiringManager = lazy(() => import("./pages/nursing-home"));
const FreelancerDetails = lazy(() => import("./pages/freelancer-detail"));
const HiringManagerDetails = lazy(() => import("./pages/hiring-manager-details"));
const PostBanners = lazy(() => import("./pages/post-banners"));
const UnderDevelopment = lazy(() => import("./pages/under-development"));
const EditHospital = lazy(() => import("./pages/edit-hospital"));
const AddHospital = lazy(() => import("./pages/add-hospital"));
const AddNursingHome = lazy(() => import("./pages/add-nursing-home"));
const EditNursingHome = lazy(() => import("./pages/edit-nursing-home"));
const Setting = lazy(() => import("./pages/settings"));
const User = lazy(() => import("./pages/User"));
const AddUser = lazy(() => import("./pages/add-user"));
const EditUser = lazy(() => import("./pages/edit-user"));
const Ems = lazy(() => import("./pages/ems"));
const EditEms = lazy(() => import("./pages/edit-ems"));
const AddEms = lazy(() => import("./pages/add-ems"));

const layoutMode = "static";
const inputStyle = "outlined";
const ripple = true;
const App = () => {
    const layoutColorMode = useSelector((state) => state.ui.theme);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "",
            items: [
                {
                    label: "Analytics",
                    icon: "pi pi-chart-bar",
                    to: "/dashboard/analytics",
                },
                {
                    label: "Users",
                    icon: "pi pi-users",
                    to: "/dashboard/users",
                },
                {
                    label: "Hospitals",
                    icon: "pi pi-fw pi-building",
                    to: "/dashboard/hospitals",
                },
                {
                    label: "EMS",
                    icon: "pi pi-globe",
                    to: "/dashboard/ems",
                },
                {
                    label: "Nursing Homes",
                    icon: "pi pi-home",
                    to: "/dashboard/nursing-homes",
                },
                {
                    label: "Registered Organizations",
                    icon: "pi pi-id-card",
                    to: "/dashboard/registered-organizations",
                },
                {
                    label: "System Directors",
                    icon: "pi pi-user",
                    to: "/dashboard/system-directors",
                },
                {
                    label: "Polls",
                    icon: "pi pi-chart-bar",
                    to: "/dashboard/polls",
                },
                {
                    label: "Categories",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/dashboard/categories",
                },
                {
                    label: "Advertisement",
                    icon: "pi pi-money-bill",
                    to: "/dashboard/ads",
                },
                {
                    icon: "pi pi-undo",
                    label: "Updates",
                    to: "/dashboard/updates",
                },
                {
                    label: "Notifications",
                    to: "/dashboard/notifications",
                    icon: "pi pi-bell",
                },
                {
                    label: "Custom Apps",
                    to: "/dashboard/custom-apps",
                    icon: "pi pi-mobile",
                },
                {
                    label: "Settings",
                    icon: "pi pi-cog",
                    to: "/dashboard/settings",
                },
            ],
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {/* <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" /> */}

            <AppTopbar
                onToggleMenuClick={onToggleMenuClick}
                layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive}
                onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
            />

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    <Suspense
                        fallback={
                            <div style={{ position: "relative", height: "80vh" }}>
                                <div className="my-5 center">
                                    <ProgressSpinner />
                                </div>
                            </div>
                        }
                    >
                        <Switch>
                            {/* <Route path="/dashboard" exact component={UnderDevelopment} /> */}
                            <Route path="/dashboard/analytics" exact component={Analytics} />
                            <Route path="/dashboard/hospitals" exact component={Freelancer} />
                            <Route path="/dashboard/nursing-homes" exact component={HiringManager} />
                            <Route path="/dashboard/hospitals/add" exact component={AddHospital} />
                            <Route path="/dashboard/hospitals/:id" exact component={EditHospital} />
                            <Route path="/dashboard/nursing-homes/add" exact component={AddNursingHome} />
                            <Route path="/dashboard/nursing-homes/:id" exact component={EditNursingHome} />
                            <Route path="/dashboard/settings" exact component={Setting} />
                            <Route path="/dashboard/users" exact component={User} />
                            <Route path="/dashboard/ems" exact component={Ems} />
                            <Route path="/dashboard/users/add" exact component={AddUser} />
                            {/* <Route path="/dashboard/users/:id" exact component={EditUser} /> */}
                            <Route path="/dashboard/ems/add" exact component={AddEms} />
                            <Route path="/dashboard/ems/:id" exact component={EditEms} />
                            <Route path="/dashboard/categories" exact component={Categories} />
                            <Route path="/dashboard/registered-organizations" exact component={RegisteredOrganizations} />
                            <Route path="/dashboard/system-directors" exact component={SystemDirectors} />
                            <Route path="/dashboard/polls" exact component={Polls} />
                            <Route path="/dashboard/polls/edit/:id" exact component={EditPoll} />
                            <Route path="/dashboard/polls/view/:id" exact component={ViewPollAnalytics} />
                            <Route path="/dashboard/polls/view-question/:pollId/:questionId" exact component={ViewPollQuestion} />
                            <Route path="/dashboard/polls/create" exact component={CreatePoll} />
                            <Route path="/dashboard/ads" exact component={Ads} />
                            <Route path="/dashboard/users" exact component={User} />
                            <Route path="/dashboard/users/:id" exact component={UserDetails} />
                            <Route path="/dashboard/notifications" exact component={Notifications} />
                            <Route path="/dashboard/updates" exact component={Updates} />
                            <Route path="/dashboard/custom-apps" exact component={CustomApps} />
                            <Route path="/dashboard/custom-apps/:id" exact component={CustomAppDetails} />
                        </Switch>
                    </Suspense>
                </div>

                {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
            </div>

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default App;
