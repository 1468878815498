import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginFun } from "../store/auth/actions";
import { Toast } from "primereact/toast";
import { isLoggingIn, loginn } from "../redux/auth-slice";
import { useLoginMutation, useWhoAmIMutation } from "../RTK/auth";
import Cookies from "js-cookie";
import logo from "../assets/logoBlack.png";
import { Helmet } from "react-helmet";

const Login = () => {
    const toast = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, setState] = useState({});
    const isLoading = useSelector((state) => state.auth.isLoggingin);
    const [login] = useLoginMutation();
    const [whoAmI] = useWhoAmIMutation();

    const handleOnChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

    const getValue = (key) => state[key];

    const token = Cookies.get("token");

    const showToast = (obj) => {
        return new Promise((resolve) => {
            toast.current.show(obj);
            setTimeout(() => {
                toast.current.clear();
                resolve();
            }, obj.life);
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        const { email, password } = state;

        dispatch(isLoggingIn(true));
        const res = await login({ email, password });
        if (res.data) {
            dispatch(isLoggingIn(false));
            Cookies.set("LOGIN_TOK", res.data?.token, {
                expires: 1,
            });
            dispatch(loginn({ token: res?.data?.token }));
            const userData = await whoAmI();
            dispatch(loginn({ userMeta: userData?.data }));
            // showToast({ severity: "success", summary: "Successful", detail: "Logged In Successfuly", life: 1000 })
            // .then(() => {
            // })
            toast.current?.show({ severity: "success", summary: "Successful", detail: "Logged In Successfuly", life: 1000 });
            history.push("/dashboard/analytics");
        } else {
            dispatch(isLoggingIn(false));
            toast.current?.show({ severity: "error", summary: "Error", detail: res?.error !== "" ? res?.error?.data?.error : "Invalid Credentials", life: 3000 });
        }
    };

    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Toast ref={toast} />
            <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
                <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4 md:w-6 sm:w-9 center">
                    <div className="text-center mb-5">
                        <img src={logo} alt="hyper" height="50" className="mb-3" />
                        <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
                        {/* <span className="text-600 font-medium line-height-3">Don't have an account?</span>
                    <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a> */}
                    </div>

                    <form onSubmit={(e) => handleLogin(e)}>
                        <label htmlFor="email1" className="block text-900 font-medium mb-2">
                            Email
                        </label>
                        <InputText id="email1" type="text" className="w-full mb-3" name="email" value={getValue("email")} onChange={handleOnChange} />

                        <label htmlFor="password1" className="block text-900 font-medium mb-2">
                            Password
                        </label>
                        <InputText id="password1" type="password" className="w-full mb-3" name="password" value={getValue("password")} onChange={handleOnChange} />

                        <div className="flex align-items-center justify-content-between mb-6">
                            {/* <div className="flex align-items-center">
                            <Checkbox inputId="rememberme1" binary className="mr-2" onChange={(e) => setChecked(e.checked)} checked={checked} />
                            <label htmlFor="rememberme1">Remember me</label>
                    </div> */}
                            <a href="/#" className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
                                Forgot password?
                            </a>
                        </div>

                        <Button type="submit" label={isLoading ? "Please Wait..." : "Sign In"} icon="pi pi-user" className="w-full" />
                    </form>
                </div>
            </div>
        </>
    );
};

export default Login;
