import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image as ImageComponent } from "primereact/image";
import { Toast } from "primereact/toast";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import FileBase64 from "react-file-base64";
import * as Yup from "yup";
import Input from "./Input";
import Select from "./Select";

const organizationTypes = [
    {
        type: "hospital",
        label: "Hospital",
    },
    {
        type: "ems",
        label: "EMS",
    },
    {
        type: "nursingHome",
        label: "Nursing Home",
    },
];

const Ads = ({ onHide, handleSubmit, show, edit }) => {
    const [loading, setLoading] = useState(false);

    const toast = useRef(null);

    const handleImageUpload = (value) => {
        const img = new Image();
        img.onload = (e) => {
            const width = img.width;
            // const height = img.height
            formik.setFieldValue("image", img);
            // formik.setFieldError("image", null)
            // }else{
            // formik.setFieldError("image", "File size must be 100x800")
            // formik.setFieldValue("image", null)
            // }
            // console.log(width, height, "size")
        };
        img.src = URL.createObjectURL(value);

        console.log(value, "value");
    };

    const formik = useFormik({
        initialValues: {
            position: "",
            subject: "",
            email: "info@nationaltransferregistry.com",
            image: null,
        },
        validationSchema: Yup.object().shape({
            position: Yup.string().required("This field is required"),
            subject: Yup.string().trim().required("This field is required"),
            email: Yup.string().trim().required("This field is required"),
            image: Yup.mixed()
                .required("This field is required")
                .test("size", "Image must be 800x100", (value) => {
                    const i = new Image();
                    i.src = value;
                    return i.width === 800 && i.height === 100;
                }),
        }),
        onSubmit: (values, { setSubmitting }) => {
            const { image } = values;
            const imageBase64 = image?.split(",")[1];
            // console.log(values, imageBase64)
            const body = { ...values, image: imageBase64 };
            handleSubmit(body, setSubmitting);
        },
    });
    const footer = () => (
        <div>
            <Button onClick={formik.handleSubmit}> {formik.isSubmitting ? "Please wait.." : "Save"}</Button>
        </div>
    );

    const handeTypeChange = (e) => {
        formik.setFieldValue("type", e.value);
    };

    const handleToggleTrafficLight = (e) => {
        e?.checked ? formik.setFieldValue("trafficLightSystem", true) : formik.setFieldValue("trafficLightSystem", false);
    };

    const positionOptions = [
        {
            label: "Home Screen",
            value: "home-screen",
        },
        {
            label: "Hospitals List",
            value: "hospitals-list",
        },
        {
            label: "Nursing Homes List",
            value: "nursing-homes-list",
        },
        {
            label: "Ems List",
            value: "ems-list",
        },
        {
            label: "Claim Organization Screen",
            value: "claim-organization-screen",
        },
    ];

    // const convertToBase64 = (file) => {
    //     const reader = new FileReader()

    //     reader.readAsDataURL(file)

    //     reader.onload = () => {
    //     //   console.log('called: ', reader)
    //       formik.setFieldValue("image", reader.result)
    //     }
    //   }

    return (
        <>
            <Toast ref={toast} />
            <Dialog footer={footer} draggable={false} style={{ width: "650px" }} header="Add Advertisement" visible={show} onHide={onHide}>
                {/* <Input autoComplete="off" style={{width: "100%", marginBottom: "1rem"}} label="Name" onBlur={formik.handleBlur} onChange={formik.handleChange} name="name" error={formik.errors.name} /> */}
                <Select
                    onChange={(e) => formik.setFieldValue("position", e?.value)}
                    value={formik.values.position}
                    onBlur={formik.handleBlur}
                    name="position"
                    error={formik.touched.position && formik.errors.position}
                    style={{ width: "100%", marginBottom: "1rem" }}
                    label="Position"
                    placeholder="Select a position"
                    options={positionOptions}
                    optionLabel="label"
                    optionValue="value"
                />
                <p style={{ fontWeight: 500 }}>Image (Size should be 800x100) </p>
                {/* <input type="file" onChange={e => convertToBase64(e.target.files[0])} /> */}
                <FileBase64
                    multiple={false}
                    onDone={({ base64 }) => {
                        const type = base64.slice(5, 10);
                        if (type === "image") {
                            const base64str = base64.split(",")[1];
                            const decoded = atob(base64str);
                            const bytes = decoded.length;
                            const kb = bytes / 1000;
                            const mb = kb / 1000;
                            if (mb.toFixed(2) < 1) {
                                formik.setFieldValue("image", base64);
                            } else {
                                alert("File size must be less than 1mb");
                            }
                        } else {
                            alert("Invalid Image Type. Please upload a png/jpeg image");
                        }
                    }}
                />
                {formik.errors.image && <p style={{ color: "red" }}>{formik.errors.image}</p>}
                <div style={{ marginTop: "1rem" }}>{formik.values.image && <ImageComponent width="300px" src={formik.values.image} preview />}</div>
                <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Email Content</p>
                <Input
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && formik.errors.email}
                    style={{ width: "100%", marginBottom: "1rem" }}
                />
                <Input
                    name="subject"
                    label="Subject"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.subject && formik.errors.subject}
                    style={{ width: "100%", marginBottom: ".5rem" }}
                />
            </Dialog>
        </>
    );
};

Ads.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool,
    onSubmit: PropTypes.func,
    edit: PropTypes.bool,
};

export default Ads;
