import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { baseUrl } from "../AppConfig";

export const ems = createApi({
    reducerPath: "emsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            const token = Cookies.get("LOGIN_TOK");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAllEms: builder.mutation({
            query: (args) => {
                const { search, searchKey, sort, page, sortkey } = args;
                return {
                    url: "/api/v1/admin/ems",
                    params: { search, searchKey, sort: sort === "asc" ? 1 : -1, page, sortkey: "createdAt" },
                };
            },
            invalidatesTags: ["ems"],
        }),
        getEms: builder.query({
            query: (params) => {
                return {
                    url: "/api/v1/admin/ems",
                    params: { ...params },
                };
            },
        }),
        addEms: builder.mutation({
            query: (body) => ({
                url: "/api/v1/admin/ems",
                method: "POST",
                body,
            }),
            invalidatesTags: ["ems"],
        }),
        editEms: builder.mutation({
            query: (body) => {
                const { id, ...rest } = body;
                return {
                    url: `/api/v1/admin/ems/${id}`,
                    method: "PATCH",
                    body: rest,
                };
            },
            invalidatesTags: ["ems"],
        }),
        getEmsById: builder.query({
            query: (id) => `/api/v1/admin/ems/${id}`,
            providesTags: ["ems"],
        }),
        deleteEms: builder.mutation({
            query: (id) => ({
                url: `/api/v1/admin/ems/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["ems"],
        }),
    }),
});

export const { useGetAllEmsMutation, useAddEmsMutation, useGetEmsByIdQuery, useEditEmsMutation, useDeleteEmsMutation, useGetEmsQuery } = ems;
