export const ROLES = {
    ADMIN: 1,
    CLIENT: 2,
    CUSTOM: 3,
};

export const AD_POSITIONS = {
    HOME_SCREEN: "home-screen",
    HOSPITALS_LIST: "hospitals-list",
    EMS_LIST: "ems-list",
    NURSING_HOMES_LIST: "nursing-homes-list",
    CLAIM_ORGANIZATION_SCREEN: "claim-organization-screen",
};

export const adPositionsMap = {
    "home-screen": "Home Screen",
    "hospitals-list": "Hospitals List",
    "ems-list": "Ems List",
    "nursing-homes-list": "Nursing Homes List",
    "claim-organization-screen": "Claim Organization Screen",
}

export const AUTH_TOKEN = "LOGIN_TOK";
