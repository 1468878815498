import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { baseUrl } from "../AppConfig";

export const analytics = createApi({
    reducerPath: "analyticsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = Cookies.get("LOGIN_TOK");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAllAnalytics: builder.query({
            query: (params) => ({
                url: "/api/v1/activity-logs",
                params: { ...params },
            }),
        }),
        getPieChart: builder.query({
            query: (params) => ({
                url: "/api/v1/analytics/organizations/views",
                params: { ...params },
            }),
        }),
        getBarChart: builder.query({
            query: (params) => ({
                url: "/api/v1/activity-logs/ads-barchart",
                params: { ...params },
            }),
        }),
        getRegisteredUsers: builder.query({
            query: (params) => ({
                url: "/api/v1/analytics/users/registration",
                params: { ...params },
            }),
        }),
        getActiveUsers: builder.query({
            query: (params) => ({
                url: "/api/v1/activity-logs/ads-linechart",
                params: { ...params },
            }),
        }),
        getAds: builder.query({
            query: (params) => ({
                url: "/api/v1/analytics/ads",
                params: { ...params },
            }),
        }),
        getUsersActivity: builder.query({
            query: (params) => ({
                url: "/api/v1/analytics/users/activity",
                params: { ...params },
            }),
        }),
        getPollAnalytics: builder.query({
            query: (params) => ({
                url: "/api/v1/analytics/polls/responses",
                params: { ...params },
            }),
        }),
    }),
});

export const {
    useGetAllAnalyticsQuery,
    useGetBarChartQuery,
    useGetPieChartQuery,
    useGetRegisteredUsersQuery,
    useGetActiveUsersQuery,
    useGetAdsQuery,
    useGetUsersActivityQuery,
    useGetPollAnalyticsQuery,
} = analytics;
