import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { baseUrl } from "../AppConfig";

export const organization = createApi({
    reducerPath: "organizationApi",
    tagTypes: ["Organization", "Category"],
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = Cookies.get("LOGIN_TOK");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (data) => {
                const { email, ...rest } = data;
                return {
                    url: "/api/v1/org/auth/register",
                    method: "POST",
                    body: { email: email?.toLowerCase(), ...rest },
                };
            },
            invalidatesTags: ["Organization"],
        }),
        changePasswordWithoutOrganization: builder.mutation({
            query: (data) => {
                return {
                    url: "/api/v1/admin/organizations",
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["Organization"],
        }),
        changePassword: builder.mutation({
            query: (data) => {
                return {
                    url: "/api/v1/org/auth/update-password",
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["Organization"],
        }),
        getAllOrganizations: builder.query({
            query: (args) => {
                const { sort } = args;
                return {
                    url: "/api/v1/admin/organizations",
                    method: "GET",
                    params: { ...args, sort: sort ? sort : "desc" },
                };
            },
            providesTags: ["Organization"],
        }),
        removeOrganization: builder.mutation({
            query: (arg) => {
                return {
                    url: "/api/v1/admin/organizations/",
                    params: { organization: arg.id },
                    method: "DELETE",
                };
            },
            invalidatesTags: ["Organization"],
        }),
        getOrganizationsNurses: builder.query({
            query: (params) => {
                return {
                    url: "/api/v1/admin/organizations/nurses",
                    params: { ...params },
                    method: "GET",
                };
            },
        }),
        getOrganizationViews: builder.query({
            query: (params) => {
                return {
                    url: "/api/v1/analytics/organization/views",
                    params: { ...params },
                    method: "GET",
                };
            },
        }),
        getOrganizationAllCategories: builder.query({
            query: (params) => ({
                url: "/api/v1/admin/categories",
                params: { ...params },
            }),
            providesTags: ["Category"],
        }),
        getSelectedOrganizationCategories: builder.query({
            query: (params) => ({
                url: "/api/v1/admin/added/categories",
                params: { ...params },
            }),
            providesTags: ["Category"],
        }),
        addOrganizationCategory: builder.mutation({
            query: (body) => ({
                url: "/api/v1/admin/categories",
                body,
                method: "POST",
            }),
            invalidatesTags: ["Category"],
        }),
        deleteOrganizationCategory: builder.mutation({
            query: (params) => ({
                url: "api/v1/admin/delete/categories",
                params: { ...params },
                method: "DELETE",
            }),
            invalidatesTags: ["Category"],
        }),
    }),
});

export const {
    useRegisterMutation,
    useGetOrganizationViewsQuery,
    useChangePasswordMutation,
    useGetOrganizationsNursesQuery,
    useGetAllOrganizationsQuery,
    useRemoveOrganizationMutation,
    useChangePasswordWithoutOrganizationMutation,
    useGetOrganizationAllCategoriesQuery,
    useGetSelectedOrganizationCategoriesQuery,
    useAddOrganizationCategoryMutation,
    useDeleteOrganizationCategoryMutation,
} = organization;
