import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import React from "react";
import { useGetPollQuestionByIdQuery } from "../RTK/poll";
import Spinner from "../components/Spinner";
import SelectionView from "../components/pollsAnalytics/SelectionView";
import AnswerView from "../components/pollsAnalytics/AnswerView";

const ViewPollQuestion = () => {
    const history = useHistory();

    const { pollId, questionId } = useParams();

    const { data, isLoading } = useGetPollQuestionByIdQuery({ pollId, questionId });

    return (
        <div className="card">
            {isLoading ? (
                <div style={{ display: "grid", placeItems: "center", padding: "1rem 0" }}>
                    <Spinner />
                </div>
            ) : (
                <>
                    <Button onClick={() => history.goBack()} label="Back" />
                    <h3>{data?.question?.title}</h3>
                    {data?.question?.type === "open_ended" ? <AnswerView question={data?.question} /> : <SelectionView question={data?.question} />}
                </>
            )}
        </div>
    );
};

export default ViewPollQuestion;
