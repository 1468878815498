import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { baseUrl } from "../AppConfig";

export const categories = createApi({
    reducerPath: "categoriesApi",
    tagTypes: ["Categories", "Status"],
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = Cookies.get("LOGIN_TOK");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCategoryByOrganization: builder.query({
            query: (args) => {
                const { organizationType, page, isBH, limit } = args;
                return {
                    url: `/api/v1/org/category/get-categories`,
                    params: { page, organizationType, isBH, limit },
                    method: "GET",
                };
            },
            providesTags: ["Categories"],
        }),
        createCategory: builder.mutation({
            query: (data) => {
                const { params, ...rest } = data;
                return {
                    url: "/api/v1/org/category/create",
                    body: rest,
                    method: "POST",
                    params: { ...params },
                };
            },
            invalidatesTags: ["Categories"],
        }),
        getCategoryById: builder.mutation({
            query: (id) => `/api/v1/org/category/get-category/${id}`,
        }),
        editCategory: builder.mutation({
            query: (data) => {
                const { id, params, ...body } = data;
                return {
                    url: `/api/v1/org/category/update/${id}`,
                    method: "PUT",
                    body,
                    params: { ...params },
                };
            },
            invalidatesTags: ["Categories"],
        }),
        deleteCategory: builder.mutation({
            query: (id) => ({
                url: `/api/v1/org/category/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Categories"],
        }),
        getExpiryTime: builder.query({
            query: () => ({
                url: "/api/v1/admin/expired/time",
                method: "GET",
            }),
            providesTags: ["Status"],
        }),
        updateExpiryTime: builder.mutation({
            query: (time) => ({
                url: `/api/v1/admin/expired/time/?time=${time}`,
                method: "POST",
            }),
            invalidatesTags: ["Status"],
        }),
    }),
});

export const {
    useGetCategoryByOrganizationQuery,
    useCreateCategoryMutation,
    useGetCategoryByIdMutation,
    useEditCategoryMutation,
    useDeleteCategoryMutation,
    useGetExpiryTimeQuery,
    useUpdateExpiryTimeMutation,
} = categories;
