import Cookies from 'js-cookie';

// const in30Minutes = 1 / 48;
const in60Minutes = 1 / 24;

export const deleteSessionCookie = (SESSION_NAME) => {
  Cookies.remove(SESSION_NAME);
};

export const setSessionCookie = (SESSION_NAME, SESSION_VALUE, DURATION) => {
  deleteSessionCookie(SESSION_NAME);
  Cookies.set(SESSION_NAME, SESSION_VALUE, { expires: DURATION || in60Minutes });
};

export const getSessionCookie = (SESSION_NAME) => {
  const sessionCookie = Cookies.get(SESSION_NAME);
  return sessionCookie === undefined ? false : sessionCookie;
};
