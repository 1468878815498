import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { baseUrl } from "../AppConfig"

export const notifications = createApi({
    reducerPath: "notificationsApi",
    tagTypes: ["Notifications"],
    baseQuery: fetchBaseQuery({baseUrl: baseUrl, 
    prepareHeaders: (headers, {getState}) => {
        const token = Cookies.get("LOGIN_TOK")
        if(token){
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    }
}),
    endpoints: builder => ({
        sendNotification: builder.mutation({
            query: body => ({
                url: "/api/v1/admin/notifications",
                method: "POST",
                body
            })
        })
    })  
})

export const {useSendNotificationMutation} = notifications