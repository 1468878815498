import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Menu } from 'primereact/menu';
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AddCategory from "../components/add-category";
import ConfirmationDialog from "../components/confirmation-dialog";
import EditCategory from "../components/edit-category";
import { useCreateCategoryMutation, useGetCategoryByOrganizationQuery, useEditCategoryMutation, useDeleteCategoryMutation } from "../RTK/categories";
import Pagination from 'react-paginate';
import PaginationComponent from "../components/pagination";
import { Image } from "primereact/image";
import AdsComponent from "../components/ads"
import { useGetAllAdsQuery, useAddAdMutation, useDeleteAdMutation, useEditAdMutation } from "../RTK/ads";
import { adPositionsMap, AD_POSITIONS } from "../util/enums";
import { baseUrl } from "../AppConfig";
import EditAds from "../components/edit-ads";
import LargeImage from "../components/LargeImage";

const adTypes = [
    {
        label: "Home Screen",
        value: "home-screen"
    },
    {
        label: "Hospitals List",
        value: "hospitals-list"
    },
    {
        label: "Nursing Homes List",
        value: "nursing-homes-list"
    },
    {
        label: "Ems List",
        value: "ems-list"
    },
    {
        label: "Claim Organization Screen",
        value: "claim-organization-screen"
    }
]


const Ads = () => {


   
    
    const data = [
        {
            name: "Ad for something",
            subject: "Subject 1",
            reciever: "email@email.com",
            images: [
                "https://picsum.photos/400/400"
            ]
        }
    ]

    const [filter, setFilter] = useState("")

    const [isAddAdVisible, setAddAdVisible] = useState(false)
    const [isEditAdVisible, setEditAdVisible] = useState(false)
    const [isEditCategoryVisible, setEditCategoryVisible] = useState(false)
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [id, setId] = useState("")
    const [ad, setAd] = useState({})
    const [currentImage, setCurrentImage] = useState({isVisible: false, img: null})

    const toast = useRef()

    const {data: adsData, isFetching, refetch} = useGetAllAdsQuery({page: currentPage, limit: 10, position: filter})

  
    const [createCategory] = useCreateCategoryMutation()
    const [editCategory] = useEditCategoryMutation()
    const [deleteCategory] = useDeleteCategoryMutation()

    const createdAtBody = rowData => {
        const date = new Date(rowData.createdAt).toDateString()

        return <p>{date}</p>
    }



    const updatedAtBody = rowData => {
        const date = new Date(rowData.updatedAt).toDateString()

        return <p>{date}</p>
    }


    const handleChangeFilter = (e) => {
        setFilter(e?.value)
    }


    const onClearFilter = async () => {
        setFilter("")
        refetch()
    };



    const hideAddAdCategory = () => {
        setAddAdVisible(false);
    }

    const hideEditAdCategory = () => {
        setEditAdVisible(false)
    }

    const showAddAdCategory = () => {
        setAddAdVisible(true);
    }

    const showEditAdCategory = (rowData) => {
        setEditAdVisible(true)
        setAd(rowData)
    }

    const showConfirmDialog = (id) => {
        setId(id)
        setConfirmDialogVisible(true)
    }

    const hideConfirmDialog = () => {
        setConfirmDialogVisible(false)
    }


    const showEditCategory = (id) => {
        setEditCategoryVisible(true);
        setId(id)
    }

    const handleAddCategorySubmit = async (values, setSubmitting) => {
        const body = {
            categoryName: values.name,
            orgType: values.type?.type,
            isLightSystem: values.trafficLightSystem
        }
        const res = await createCategory(body)
        if (res?.data) {
            toast.current.show({ severity: "success", summary: "Success", detail: res?.data?.msg, life: 3000 })
            setSubmitting(false)
            setAddAdVisible(false)
        }
    }

    const handleEditCategorySubmit = async (values, setSubmitting) => {
        const body = {
            categoryName: values.name,
            orgType: values.type?.type,
            isLightSystem: values.trafficLightSystem,
            id
        }
        const res = await editCategory(body)
        if (res?.data) {
            toast.current.show({ severity: "success", summary: "Success", detail: "Category Edited Successfully", life: 3000 })
            setSubmitting(false)
            setId("")
            setEditCategoryVisible(false)
        }
    }

    const handleDeleteCategorySubmit = async () => {
        const res = await deleteCategory(id)
        if (res?.data) {
            toast.current.show({ severity: "success", summary: "Success", detail: res?.data?.msg, life: 3000 })
            setId("")
            setConfirmDialogVisible(false)
        }
    }

    const userProfileTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="image-text">{`${rowData.name}`}</span>
            </React.Fragment>
        );
    };


    const menuRef = useRef()

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <i onClick={(e) => menuRef.current.toggle(e)} style={{cursor: "pointer"}} className="pi pi-ellipsis-v" ></i>
                <Menu model={items} popup ref={menuRef} /> */}
                <Button onClick={() => showEditAdCategory(rowData)} className="p-button-rounded p-button-primary p-mr-2" icon="pi pi-user-edit" />
                <Button
                    onClick={() => showConfirmDialog(rowData?._id)}
                    className="p-button-rounded p-button-danger"
                    icon="pi pi-ban"
                    style={{ marginLeft: ".5rem" }}
                    label="Delete"
                />
            </React.Fragment>
        );
    };

    const lightSystemBody = (rowData) => {
        return (
            <p>{JSON.stringify(rowData?.isLightSystem)}</p>
        )
    }

    const imgRef = useRef()

    const imgBody = rowData => {
        return (
            <img 
                ref={imgRef}
                onClick={() => {
                    setCurrentImage({isVisible: true, img: baseUrl + `/api/v1/ads/image/${rowData?._id}`})
                }}
                style={{width: "7rem", height: "7rem", objectFit: "cover"}} src={baseUrl + `/api/v1/ads/image/${rowData?._id}`} 
                crossOrigin="anonymous"
            />
            // <Image src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" zoomSrc="https://www.primefaces.org/wp-content/uploads/2020/05/small.png" alt="Image" width="80" height="60" preview />
            // <Image crossorigin="anonymous" src={baseUrl + `/api/v1/ads/image/${rowData?._id}`} 
            // zoomSrc={baseUrl + `/api/v1/ads/image/${rowData?._id}`}
            //  preview height="60" width="80" />
        )
    }

    const positionBody = (rowData) => {
        return adPositionsMap[rowData?.position]
    }

    const [deleteAd, {isLoading}] = useDeleteAdMutation()
    const [addAd, {isLoading: isAdLoading}]  = useAddAdMutation()
    const [editAd] = useEditAdMutation()

    const handleDeleteAd = async () => {
        const res = await deleteAd(id)
        if(res?.data){
            setId("")
            hideConfirmDialog()
            refetch()
            toast.current.show({ severity: "success", summary: "Successful", detail: "Ad Deleted Successfully", life: 3000 });
        }else{
            toast.current.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
        }
    }

    const handleAddAd = async (values, setSubmitting) => {
        // setSubmitting(true)
        const res = await addAd(values)
        if(res.data){
            toast.current.show({ severity: "success", summary: "Successful", detail: "Ad Added Successfully", life: 3000 });
            hideAddAdCategory()
            refetch()
        }else{
            toast.current.show({ severity: "error", summary: "Error", 
            detail: res?.error?.data?.error === "request entity too large" ? "Image size should be smaller" : "Something went wrong", 
            life: 3000 });
            setSubmitting(false)
        }
        // setSubmitting(false)
    }

    const handleEditAd = async (values, setSubmitting) => {
        const body = {...values, ad: ad?._id}
        const res = await editAd(body)
        if(res.data){
            toast.current.show({ severity: "success", summary: "Successful", detail: "Ad Edited Successfully", life: 3000 });
            setSubmitting(false)
            hideEditAdCategory()
            refetch()
            imgRef.current.src = imgRef.current.src + "?t=" + new Date().getTime()
        }else{
            toast.current.show({ severity: "error", summary: "Error", 
            detail: res?.error?.data?.error === "request entity too large" ? "Image size should be smaller" : "Something went wrong",
            life: 3000 });
            setSubmitting(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>Advertisement</title>
            </Helmet>
            <Toast ref={toast} />
            <div className="card">
                <div className="py-5">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>
                            <div className="font-medium text-3xl text-900">Advertisement</div>
                        </div>
                        <div className="mt-3 lg:mt-0">
                            <Button label="Add" onClick={showAddAdCategory} className="p-button-outlined mr-2" icon="pi pi-plus" />
                        </div>
                    </div>
                    <div className="my-4" >
                        <Button label="Clear" onClick={onClearFilter} className="p-button-outlined mr-2" icon="pi pi-filter" />
                        <Dropdown
                            className="mr-2"
                            options={adTypes}
                            value={filter}
                            optionValue="value"
                            onChange={handleChangeFilter}
                            optionLabel="label"
                            placeholder="Advertisement Types"
                        />
                    </div>
                </div>
                <DataTable
                    lazy
                    loading={isFetching}
                    dataKey="_id"
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    emptyMessage={"No record found"}
                    first={0}
                    value={adsData?.ads}
                    rows={10}
                    totalRecords={data?.length}
                >
                    <Column field="position" body={positionBody} header="Position" />
                    {/* <Column body={organizationTypeMap} field="organizationType" header="Organization Type"  /> */}
                    {/* <Column body={lightSystemBody} field="isLightSystem" header="Light System" /> */}
                    <Column field="subject"  header="Subject" />
                    <Column field="email" header="Email" />
                    <Column field="images" body={imgBody} header="Banner" />
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "8rem" }}></Column>
                </DataTable>
                
                <div className="pagination">
                    <PaginationComponent 
                        pageCount={adsData?.totalPages}
                        totalDocs={adsData?.totalCount}
                        currentPage={currentPage}
                        onPageChange={(e) => setCurrentPage(e?.selected + 1)}
                    />
                </div>
            </div>
            <AdsComponent
                onHide={hideAddAdCategory}
                show={isAddAdVisible}
                handleSubmit={handleAddAd}
            />
            <EditAds
                show={isEditAdVisible}
                onHide={hideEditAdCategory}
                ad={ad}
                handleSubmit={handleEditAd}
            />
            {/* <EditCategory submit={handleEditCategorySubmit} id={id} onHide={hideEditCategory} show={isEditCategoryVisible} /> */}
            <ConfirmationDialog isLoading={isLoading} onSubmit={handleDeleteAd} msg="Are you sure you want to delete this ad?" onHide={hideConfirmDialog} show={isConfirmDialogVisible} />
            <LargeImage setVisible={setCurrentImage} visible={currentImage?.isVisible} image={currentImage?.img} />
        </>
    )
}

export default Ads