import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";
import * as Yup from "yup";
import { useSendUpdateMutation } from "../RTK/updates";
import { toTypes } from "../pages/updates";
import Input from "./Input";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import ConfirmationDialog from "./confirmation-dialog";

const SendUpdate = ({ onHide, submit, show }) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [sendUpdate, { isLoading }] = useSendUpdateMutation();

    const toastRef = useRef();

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            type: "",
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().required("This field is requried"),
            description: Yup.string().trim().required("This field is requried"),
            type: Yup.string().trim().required("This field is requried"),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setShowConfirmationModal(true);
        },
    });

    const handleSubmit = async () => {
        const body = {
            title: formik.values.title,
            body: formik.values.description,
            to: formik.values.type,
        };
        try {
            const res = await sendUpdate(body);
            if (res.data?.success) {
                formik.resetForm();
                toastRef.current?.show({ severity: "success", summary: "Success", detail: "Update Sent Successfully" });
                setShowConfirmationModal(false);
                onHide();
            }
        } catch (error) {
            toastRef.current?.show({ severity: "error", summary: "error", detail: "Something went wrong" });
        }
    };

    const footer = () => (
        <div>
            <Button disabled={isLoading} onClick={formik.handleSubmit}>
                {" "}
                {formik.isSubmitting ? "Please wait.." : "Update"}
            </Button>
        </div>
    );

    return (
        <>
            <Toast ref={toastRef} />
            <Dialog footer={footer} draggable={false} style={{ width: "450px" }} header="Send Update" visible={show} onHide={onHide}>
                <Input
                    type="text"
                    style={{ width: "100%" }}
                    label="Title"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="title"
                    error={formik.errors.title}
                />
                <div style={{ marginTop: "1rem" }}>
                    <p style={{ fontWeight: 500 }}>Description </p>
                    <InputTextarea rows={3} name="description" onChange={formik.handleChange} onBlur={formik.handleBlur} style={{ width: "100%" }} />
                    {formik.errors.description && <p style={{ color: "red" }}>{formik.errors.description}</p>}
                </div>

                <div style={{ marginTop: "1rem" }}>
                    <p style={{ fontWeight: 500 }}>Type </p>
                    <Dropdown
                        style={{ width: "100%" }}
                        className="mr-2"
                        onChange={(e) => formik.setFieldValue("type", e.value)}
                        options={toTypes}
                        optionValue="value"
                        optionLabel="label"
                        value={formik.values.type}
                        placeholder="Type"
                    />
                    {formik.errors.type && <p style={{ color: "red" }}>{formik.errors.type}</p>}
                </div>
            </Dialog>
            <ConfirmationDialog
                isLoading={isLoading}
                onSubmit={handleSubmit}
                msg="Are you sure you want to send this update as a notification?"
                show={showConfirmationModal}
                onHide={() => setShowConfirmationModal(false)}
            />
        </>
    );
};

export default SendUpdate;
