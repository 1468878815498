import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import logo from "./assets/logoBlack.png";
import logoWhite from "./assets/logoWhite.png";
import { setTheme, setThemeFile } from "./redux/ui-slice";
import { setLogout } from "./store/auth/actions";
import { saveData } from "./util/storage";

export const AppTopbar = (props) => {
    const theme = useSelector((state) => state.ui.theme);
    const dispatch = useDispatch();
    const history = useHistory();

    const changeTheme = (theme, scheme) => {
        saveData("theme", scheme);
        dispatch(setTheme(scheme));
        dispatch(setThemeFile(theme));
    };

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={theme === "light" ? logo : logoWhite} alt="logo" />
                <span>The NTR</span>
            </Link>

            <button title="Close/Expand Sidebar" type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                <li>
                    <button
                        title="Toggle Theme"
                        className="p-link layout-topbar-button"
                        onClick={() => (theme === "light" ? changeTheme("bootstrap4-dark-blue", "dark") : changeTheme("bootstrap4-light-blue", "light"))}
                    >
                        <i className={theme === "light" ? "pi pi-moon" : "pi pi-sun"} />
                        <span>Toggle Theme</span>
                    </button>
                </li>
                {/* <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-cog" />
                        <span>Settings</span>
                    </button>
                </li> */}
                <li>
                    <button
                        title="Logout"
                        className="p-link layout-topbar-button"
                        onClick={() => {
                            dispatch(setLogout(() => history.push("/login")));
                        }}
                    >
                        <i className="pi pi-sign-out" />
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};
