import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useMemo, useRef, useState } from "react";
import ReactSelect from "react-select";
import * as Yup from "yup";
import { useGetAllOrganizationsQuery } from "../RTK/organization";
import { useRegisterMutation } from "../RTK/system-directors";
import Input from "./Input";

const SystemDirectorDialog = ({ onHide, isVisible, onSubmit }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [register, { isLoading }] = useRegisterMutation();

    const toast = useRef();

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            organizations: [],
            // defaultOrganization: null
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email().required("This field is required"),
            password: Yup.string().required("This field is required"),
            organizations: Yup.array().min(1).required("This field is required"),
            // defaultOrganization: Yup.object().required("This field is required").nullable()
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log("submit called");
            const { email, password, organizations, defaultOrganization } = values;
            const orgs = organizations.map((item) => {
                const o = { organization: item.id, organizationType: item.type };
                return o;
            });

            const body = {
                email: email?.toLowerCase(),
                password,
                organizations: orgs,
                orgId: orgs[0]?.organization,
                orgType: orgs[0]?.organizationType,
            };

            const res = await register(body);
            if (res.data) {
                toast.current.show({ severity: "success", summary: "Successful", detail: "System Director Added Successfully", life: 3000 });
                resetForm();
                onHide();
            } else {
                toast.current.show({ severity: "error", summary: "Successful", detail: res?.error?.data?.error, life: 3000 });
            }
        },
    });

    const { data } = useGetAllOrganizationsQuery({ limit: 1000, sort: "desc" });

    const organizations = useMemo(() => {
        // if (data && param?.env === "dev") {
        return data?.data?.map((item) => {
            const org = { id: item?.organization?._id, type: item?.organizationType, name: item?.organization?.name };
            return org;
        });
        // } else {
        //     return data?.organizations?.map((item) => {
        //         const org = { id: item?.organization?._id, type: item?.organizationType, name: item?.organization?.name };
        //         return org;
        //     });
        // }
    }, [data]);

    const handleSelectOrganization = (e) => {
        formik.setFieldValue("organizations", e);
    };

    const handleDefaultOrganization = (e) => {
        formik.setFieldValue("defaultOrganization", e.value);
    };

    const footer = () => (
        <div>
            <Button disabled={isLoading} type="button" onClick={formik.handleSubmit}>
                {" "}
                {formik.isSubmitting ? "Please wait.." : "Register"}
            </Button>
        </div>
    );

    const handleSelectDelete = (item) => {
        const id = item?.id;
        const temp = formik.values.organizations.filter((item) => item?.id !== id);
        formik.setFieldValue("organizations", temp);
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                footer={footer}
                contentStyle={{ paddingTop: "5rem", paddingBottom: "5rem", display: "flex", justifyContent: "center", flexDirection: "column" }}
                style={{ width: "650px", display: "flex", justifyContent: "center" }}
                draggable={false}
                onHide={onHide}
                visible={isVisible}
                header="Add System Director"
            >
                <div style={{ display: "flex", alignItems: "center", width: "100%", marginBottom: "1rem" }}>
                    <Input
                        containerStyles={{ width: "50%", marginRight: "1rem" }}
                        label="Email"
                        style={{ width: "100%", borderRadius: "1rem", border: "1px solid #007bff" }}
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && formik.errors.email}
                    />
                    <div style={{ position: "relative", width: "50%" }}>
                        <Input
                            containerStyles={{ width: "100%", marginRight: "1rem" }}
                            label="Password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={formik.values.password}
                            style={{ width: "100%", borderRadius: "1rem", border: "1px solid #007bff" }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && formik.errors.password}
                        />
                        <i
                            className="pi pi-eye"
                            onClick={() => setShowPassword((prev) => !prev)}
                            style={{ position: "absolute", right: "1rem", top: "3.2rem", cursor: "pointer" }}
                        />
                    </div>
                </div>
                {organizations && (
                    <div style={{ marginBottom: "1rem" }}>
                        <p style={{ fontWeight: 500 }}>Operational Managers (Select Multiple)</p>
                        <ReactSelect
                            isMulti={true}
                            options={organizations}
                            styles={{
                                container: (baseStyles) => ({
                                    ...baseStyles,
                                    border: "1px solid #007bff",
                                    borderRadius: "2rem",
                                }),
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderRadius: "2rem",
                                    border: "1px solid #007bff",
                                    padding: ".3rem",
                                }),
                                multiValue: (baseStyles) => ({
                                    ...baseStyles,
                                    background: "#007bff",
                                    color: "white",
                                    borderRadius: "1rem",
                                }),
                                multiValueLabel: (baseStyles) => ({
                                    ...baseStyles,
                                    color: "white",
                                }),
                            }}
                            name="organizations"
                            closeMenuOnSelect={false}
                            controlShouldRenderValue={false}
                            getOptionLabel={(data) => data?.name}
                            getOptionValue={(data) => data?.id}
                            value={formik.values.organizations}
                            onBlur={formik.handleBlur}
                            onChange={handleSelectOrganization}
                        />
                        {formik.touched.organizations && formik.errors.organizations && <p style={{ color: "red" }}>{formik.errors.organizations}</p>}
                    </div>
                )}
                {formik.values.organizations.length > 0 && (
                    <>
                        <div style={{ marginBottom: "1rem", display: "grid", gridTemplateColumns: "repeat(3,1fr)", columnGap: "1rem", rowGap: "1rem" }}>
                            {formik?.values?.organizations?.map((item) => (
                                <div
                                    key={item?.id}
                                    style={{
                                        padding: ".5rem",
                                        borderRadius: "1rem",
                                        background: "#007bff",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <p style={{ color: "white", fontSize: ".8rem", marginBottom: "0", marginRight: ".5rem" }}>{item?.name}</p>
                                    <i
                                        onClick={() => handleSelectDelete(item)}
                                        style={{ fontSize: ".8rem", color: "white", cursor: "pointer" }}
                                        className="pi pi-times"
                                    ></i>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {/* {formik.values.email && !formik.errors.email && formik.values.password && !formik.errors.password && formik.values.organizations.length > 0 ? (
                    <div className="flex mt-4 align-items-center justify-content-end">
                        <a href={`mailto:?to=&body=Email:%20${formik.values.email}%0APassword:%20${formik.values.password}`}>
                            <Button className="p-button-outlined">Share Credentials</Button>
                        </a>
                    </div>
                ) : null} */}
                <p style={{ marginTop: "1rem" }}>
                    <strong>Note:</strong> If Target HOM is not in the list. Please assign an HOM to the organization first.
                </p>
            </Dialog>
        </>
    );
};

export default SystemDirectorDialog;
