import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import AppRouter from "./AppRouter";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/layout/flags/flags.css";
import "./assets//layout/layout.scss";
import "./App.scss";
import { getSessionCookie } from "./util/cookie";
import { AUTH_TOKEN } from "./util/enums";
import { whoami } from "./store/auth/actions";
import { getData } from "./util/storage";
import { AppConfig, baseUrl, development, production } from "./AppConfig";
import PrimeReact from "primereact/api";
import { setActiveMenuLabel, setTheme, setThemeFile } from "./redux/ui-slice";
import { useWhoAmIMutation } from "./RTK/auth";
import { loginn } from "./redux/auth-slice";
import Cookies from "js-cookie";
import { changeEnv } from "./redux/env-slice";

const App = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [whoAmI] = useWhoAmIMutation();
    const isLogin = useSelector((state) => state.auth.isLogin);
    const themeFile = useSelector((state) => state.ui.themeFile);

    useEffect(() => {
        if (!getSessionCookie(AUTH_TOKEN) && pathname !== "/login") {
            history.push("/login");
        } else if (getSessionCookie(AUTH_TOKEN) && pathname === "/login") {
            history.push("/dashboard/hospitals");
        } else if (getSessionCookie(AUTH_TOKEN) && pathname === "/") {
            history.push("/dashboard/hospitals");
        }
    }, [isLogin, pathname, history]);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/dashboard/nursing-homes") {
            dispatch(setActiveMenuLabel("Nursing Homes"));
        } else if (location.pathname === "/dashboard/hospitals") {
            dispatch(setActiveMenuLabel("Hospitals"));
        } else if (location.pathname === "/dashboard/ems") {
            dispatch(setActiveMenuLabel("EMS"));
        } else if (location.pathname === "/dashboard/registered-organizations") {
            dispatch(setActiveMenuLabel("Registered Organizations"));
        } else if (location.pathname === "/dashboard/registered-organizations") {
            dispatch(setActiveMenuLabel("Registered Organizations"));
        } else if (location.pathname === "/dashboard/system-directors") {
            dispatch(setActiveMenuLabel("System Directors"));
        } else if (location.pathname === "/dashboard/categories") {
            dispatch(setActiveMenuLabel("Categories"));
        } else if (location.pathname === "/dashboard/ads") {
            dispatch(setActiveMenuLabel("Advertisement"));
        } else if (location.pathname === "/dashboard/settings") {
            dispatch(setActiveMenuLabel("Settings"));
        } else if (location.pathname === "/dashboard/analytics") {
            dispatch(setActiveMenuLabel("Analytics"));
        }
    }, [location.pathname]);

    useEffect(() => {
        const getUserData = async () => {
            const userData = await whoAmI();
            if (userData?.data) {
                dispatch(loginn({ userMeta: userData?.data }));
            } else if (userData.error?.originalStatus === 401) {
                Cookies.remove(AUTH_TOKEN);
                history.push("/login");
            }
        };
        getUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [ripple, setRipple] = React.useState(true);
    const [inputStyle, setInputStyle] = React.useState("outlined");
    const [layoutMode, setLayoutMode] = React.useState("static");
    const [layoutColorMode, setLayoutColorMode] = React.useState(null);

    React.useEffect(() => {
        const val = getData("theme");
        if (val === "dark") {
            setLayoutColorMode(val);
            dispatch(setTheme(val));
            dispatch(setThemeFile("bootstrap4-dark-blue"));
        } else {
            setLayoutColorMode("light");
            dispatch(setTheme("light"));
            setTheme("bootstrap4-light-blue");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (themeFile) {
            let themeElement = document.getElementById("theme-link");
            const themeHref = __dirname + "assets/themes/" + themeFile + "/theme.css";
            replaceLink(themeElement, themeHref);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeFile]);

    useEffect(() => {
        window.addEventListener("error", (e) => {
            if (e.message === "ResizeObserver loop limit exceeded" || e.message === "Script error.") {
                const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
                const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });
    }, []);

    const replaceLink = (linkElement, href, callback) => {
        if (isIE()) {
            linkElement.setAttribute("href", href);

            if (callback) {
                callback();
            }
        } else {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute("href", href);
            cloneLinkElement.setAttribute("id", id + "-clone");

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove();
                cloneLinkElement.setAttribute("id", id);

                if (callback) {
                    callback();
                }
            });
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };
    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };
    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };
    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    return (
        <>
            <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/dashboard" render={(props) => <AppRouter {...props} />} />
                {/* <Redirect from="/" to="/login" /> */}
            </Switch>
            <AppConfig
                rippleEffect={ripple}
                onRippleEffect={onRipple}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode}
                onLayoutModeChange={onLayoutModeChange}
                layoutColorMode={layoutColorMode}
                onColorModeChange={onColorModeChange}
            />
        </>
    );
};

export default App;
