import React from 'react'
import PropTypes from "prop-types"

const Spinner = ({style}) => {
  return (
    <i className="pi pi-spin pi-spinner" style={style}></i>
  )
}

export default Spinner

Spinner.propTypes = {
    style: PropTypes.object
}