import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { baseUrl } from "../AppConfig"

export const auth = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({baseUrl: baseUrl, 
    prepareHeaders: (headers, {getState}) => {
        const token = Cookies.get("LOGIN_TOK")
        if(token){
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    }
}),
    endpoints: builder => ({
        login: builder.mutation({
            query: user => ({
                url: "/api/v1/auth/admin/login",
                method: "POST",
                body: user,
            })
        }),
        whoAmI: builder.mutation({
            query: token => ({
                url: "/api/v1/auth/admin/whoami"
            })
        })
    })
})

export const {useLoginMutation, useWhoAmIMutation} = auth